/**
 * i18n 的全局的t
 */
import i18n from '@/lang/lang'

class GenerateI18nUtil {
  static i18nUtil: GenerateI18nUtil = new GenerateI18nUtil()
  get T() {
    return i18n.global.t
  }

  get Locale() {
    return i18n.global.locale.value
  }
}

export default GenerateI18nUtil.i18nUtil
export const { i18nUtil } = GenerateI18nUtil
