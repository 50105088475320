import { useIntersectionObserver } from '@vueuse/core'
import { onMounted, ref } from 'vue'
import I18nUtil from '@/utils/I18nUtil'

// 图片懒加载
export default (el: HTMLImageElement, binding: { value: string }) => {
  const { stop } = useIntersectionObserver(el, ([{ isIntersecting }]) => {
    if (isIntersecting) {
      // 进入视口区域  赋值src
      el.src = binding.value
      // 加载图片后停止监听
      stop()
    }
  })
}

//注册页面的密码框的验证
export let validatePass = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error(I18nUtil.T('register.err10')))
  }
  if (
    !/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){8,30}$/.test(
      value
    )
  ) {
    callback(
      new Error(
        I18nUtil.T('register.err11')
      )
    )
  } else {
    callback()
  }
}

export let validateEMail = (rule: any, value: any, callback: any) => {
  const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/
  if (value == '' || value == undefined || value == null) {
    callback()
  } else {
    if (!reg.test(value)) {
      callback(new Error(I18nUtil.T('register.err12')))
    } else {
      callback()
    }
  }
}

//生成11为uuid
export function serial_num_11() {
  let d: any = new Date()
  let year: any = d.getFullYear()
  let month: any = d.getMonth() + 1
  let date: any = d.getDate()
  let day: any = d.getDay()
  year = (year + '').substring(2)
  if (month <= 9) {
    month = '0' + month
  } else if (date <= 9) {
    date = '0' + date
  }
  //获取当日凌晨0:00:00（零时整）
  let startTime: any = parseInt(
    String(new Date(new Date().toLocaleDateString()).getTime())
  )
  //获取当日23:59:59
  let endTime: any = startTime + (24 * 60 * 60 * 1000 - 1)
  //获取当前时间戳
  let currentTime: any = parseInt(String(new Date().getTime()))
  let remainTime: any = parseInt(
    String((parseInt(endTime) - parseInt(currentTime)) / 1000)
  )
  let time
  if (parseInt(currentTime) > parseInt(startTime)) {
    if (remainTime < 10) {
      time = '0000' + remainTime.toString()
    }
    if (remainTime < 100 && remainTime >= 10) {
      time = '000' + remainTime.toString()
    }
    if (remainTime < 1000 && remainTime >= 100) {
      time = '00' + remainTime.toString()
    }
    if (remainTime < 10000 && remainTime >= 1000) {
      time = '0' + remainTime.toString()
    }
    if (remainTime >= 10000) {
      time = remainTime.toString()
    }
    var id = year + month + date + time
    return id
  }
}

//时间戳转换为yyyy-MM-dd格式
export function getDate(date: string) {
  //date是传过来的时间戳，注意需为13位，10位需*1000
  //也可以不传,获取的就是当前时间
  var time = new Date(date)
  var year = time.getFullYear() //年
  var month = ('0' + (time.getMonth() + 1)).slice(-2) //月
  var day = ('0' + time.getDate()).slice(-2) //日
  var mydate = year + '-' + month + '-' + day
  return mydate
}

export function uuid(length: number) {
  if (length > 0) {
    var data = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]
    var nums = ''
    for (var i = 0; i < length; i++) {
      var r = parseInt(String(Math.random() * 35))
      nums += data[r]
    }
    return nums
  } else {
    return false
  }
}

//获取cookie
export function getCookie(name: string | null) {
  // 获取cookies
  let strCookie = document.cookie
  let arrCookie = strCookie.split('; ')
  for (var i = 0; i < arrCookie.length; i++) {
    let arr = arrCookie[i].split('=')
    if (name == arr[0]) {
      return arr[1]
    }
  }
  return
}


export function delCookie(name: string) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toUTCString() + ";path=/";
}

