import { reactive } from 'vue'
import { defineStore } from 'pinia'

// export interface OrfFinderFace {
//   in: string
//   ml: 30 | 75 | 150 | 300 | 600
//   g: 1
//   s: 0 | 1 | 2
//   outfmt: 0
// }
// 初始值
// const initForm: OrfFinderFace = {
//   in: '',
//   ml: 75,
//   g: 1,
//   s: 2,
//   outfmt: 0,
// }

const initForm = {
  sequenceValue: '',
  basicGroupValue: 30, //碱基数量
  readingFrameValue: '1', //阅读框的位置
  codonValue: 'transl_table=1',
  color: 'color',
  type: 'linear',
}
// 使用这个来持久化数据，不想使用query参数来持久了
export const useResetMap = defineStore(
  'reset-map',
  () => {
    let form = reactive({ ...initForm })

    // 需要特定情况下重置为原来的状态，例如刷新，进入到form页面的时候
    function $reset() {
      Object.assign(form, initForm)
    }
    return { form, $reset }
  },
  { persist: { enabled: true, strategies: [{ storage: sessionStorage }] } }
)
