export default {
    upload: {
        // 主页
        Home: {
            allItem: '全部项目',
            notsubmitted: '未提交',
            underreview: '审查中',
            isPublic: '已通过',
            notpass: '未通过',
            Newuploadproject: '新建上传项目',
            uploadType: '上传项目类型',
            filter: '筛选',
            projectName: '项目名称',
            status: '状态',
            uploadDate: '上传时间',
            operation: '操作',
            info: '审核需要一段时间，请您耐心等待',
            isDelete: '是否删除？',
            yes: '是',
            no: '否',
            UploadTitle: '上传',
            SelectTypeOption: {
                All: '全部',
                NatureMetabolomics: '天然产物-代谢组学',
                NatureBioassay: '天然产物-生物测定',
                CompoundNewCom: '化合物-新建化合物',
                CompoundComReaction: '化合物-化学反应',
                ProteinBaseInfo: '蛋白质-蛋白质基本信息',
                ProteinDisease: '蛋白质-人类疾病信息',
                ProteinArticles: '蛋白质-氨基酸序列有关文章',
                GeneBioProject: '基因-生物项目',
                GeneBioSample: '基因-生物样本',
                CompoundSuppCom: '化合物-补充更正化合物'
            }
        },
        // 上传
        Upload: {
            common: {
                save: '保存',
                delete: '删除',
                cancel: '取消',
                submit: '提交',
                add: '添加',
                nextStep: '下一步',
                saveRemind: '您的项目将保存在数据库中，可以随时进行编辑，百至满意后提交项目，审核人员再对其进行审核。',
                submitSuccessfully: '提交成功',
                submitFailure: '提交失败',
                saveSuccessfully: '保存成功',
                saveFailure: '保存失败',
                cannotEmpty: '输入不能为空',
                SubmitTitle: '提交',
                SubmitText: '您确定要提交该上传项目吗？',
                ConfirmText: '确定',
                CancelText: '取消',
                CancelUpload: '取消上传',
                CancelSave: '取消保存',
                SaveTitle: '保存',
                SaveText: '您的项目将保存在数据库中，可以随时进行编辑，直至满意后提交项目，审核人员再对其进行审核。',
                DataFiles: '数据文件',
                FilesErr: '文件格式有误！',
                DragText: '拖拽文件到此处或',
                UploadFile: '上传文件',
                RequiredMsg: '请输入必填项！',
                FilesMsg: '请上传文件！',
                NextStep: '下一步',
                SubmitSucc: '提交成功',
                SubmitErr: '提交失败',
                EditSucc: '编辑成功',
                EditErr: '编辑失败',
            },
            compound: {
                reaction: {
                    reactant: '反应物',
                    substance: '物质',
                    enterSearchCompounds: '请输入或搜索化合物',
                    concentrationUnits: '浓度（单位）',
                    enterReactantConcentration: '请输入反应物质的浓度（单位）',
                    agentia: '试剂',
                    enterOrSearchReagent: '请输入或搜索试剂物质',
                    enterAgentiaConcentration: '请输入试剂的浓度（单位）',
                    solvent: '溶剂',
                    enterOrSearchSolvent: '请输入或搜索溶剂物质',
                    enterSolventConcentration: '请输入溶剂的浓度（单位）',
                    catalyst: '催化剂',
                    enterOrSearchCatalyst: '请输入或搜索催化剂物质',
                    enterCatalystConcentration: '请输入催化剂的浓度（单位）',
                    product: '生成物',
                    enterProductConcentration: '请输入生成物的浓度（单位）',
                    reactionCondition: '反应条件',
                    conditionType: '条件类型',
                    enterReactionConditions: '请输入反应条件，如: 温度、搅拌等',
                    value: '值',
                    enterValueForReactionCondition: '请输入该反应条件的数值',
                    reactionProcess: '反应过程',
                    enterReactionProcess: '请输入反应过程',
                    references: '参考文献',
                    patent: '专利',
                    articleTitle: '文章标题',
                    enterTheArticleTitle: '请输入文章标题',
                    articleAuthor: '文章作者',
                    enterTheArticleAuthor: '请输入文章作者',
                    periodical: '期刊',
                    dataFile: '数据文件',
                    uploadFiles: '上传文件',
                    supportFormat: '支持格式',
                    enterReferenceDOI: '请输入参考文献DOI',
                    enterPatentNumber: '若数据来源于专利，请输入专利号',
                    journalTitle: '期刊名称',
                    enterJournalName: '请输入期刊名称',
                    abstract: '摘要',
                    enterAbstract: '请输入摘要',
                    delete: '删除',
                    cancel: '取消',
                    submit: '提交',
                    nextStep: '下一步',
                },
            },
            gene: {
                bioProject: {
                    basicProjectInformation: '项目基本信息',
                    projectTitle: '项目标题',
                    enterProjectTitle: '请输入项目标题',
                    involvedField: '涉及领域',
                    selectInterestArea: '请选择涉及领域',
                    projectDescription: '项目说明',
                    enterProjectDescription: '请输入项目说明',
                    capitalSource: '资金来源',
                    organization: '机构',
                    selectMechanism: '选择机构',
                    itemClassification: '项目类别',
                    enterProjectCategory: '请输入项目类别',
                    porojectAapprovalNumber: '项目批准号',
                    enterProjectApprovalNumber: '请输入项目批准号',
                    projectName: '项目名称',
                    enterProjectName: '请输入项目名称',
                    dataType: '数据类型',
                    itemDataType: '项目数据类型',
                    samplingRange: '样本范围',
                    enterSampleRange: '请输入样本范围',
                    sampleRangeSelectionPrompts: '样本范围选择提示',
                    monoisolateTip: '单个动物、培养细胞系、近交群体（或可能是从集合样本中产生单个基因型群体），注意此项适用范围较窄，不为优选项，请谨慎选择；',
                    multiisolateTip: '同一物种的多基因型的个体样本集；',
                    multiSpeciesTip: '多物种样本集；',
                    environmentTip: '环境样本集，多用于宏基因组研究；',
                    syntheticTip: '在实验室里制造/合成的样本集；',
                    singleCell: '单个细胞测序样本集；',
                    otherTip: '其他样本集/无法归入以上类别的特殊样本集；',
                    publicationInformation: '出版信息（选填）',
                    articleTitle: '文章标题',
                    enterTheArticleTitle: '请输入文章标题',
                    journal: '杂志名称',
                    enterMagazineName: '请输入杂志名称',
                    author: '作者',
                    enterAuthor: '请输入作者',
                    publicationYear: '发表年份',
                    enterPublicationYear: '请输入发表年份',
                    enterPubMed: '请输入PubMed',
                    addContentCannotEmpty: '添加内容不能为空',
                },
                bioSample: {
                    basicInformation: '基本信息',
                    biologicalProjectNumber: '生物项目编号',
                    selectBiologicalItemNumber: '请选择生物项目编号',
                    orYouCanCreate: '或者您可以创建',
                    newBiologicalProject: '新的生物项目',
                    sampleTitle: '样本标题',
                    enterSampleTitle: '请输入样本标题',
                    sampleSpecification: '样本说明',
                    enterSampleDescription: '请输入样本说明',
                    sampleType: '样本类型',
                    pathogenTip: '用于与公告卫生相关的病原体样本',
                    microbeTip: '用于能具体写出物种名称的微生物样本，但不包括致病菌或病毒样本。   宏基因组数据建议选择的Metagenome/Environmental Sample（GSC MIMS unsupported） 或 Metagenome/Environmental Sample （GSC MIMS compliant）',
                    animalTip: '用于模式生物或动物的多细胞样本或细胞系样本，如大鼠、小鼠、果蝇、线虫、鱼类、两栖类或其他哺乳动物数据。',
                    humanTip: '人类遗传资源相关组学原始数据提交到GSA for Human数据库。',
                    plantTip: '用于植物样本或植物细胞系样本。',
                    virusTip: '用于所有与疾病无关的病毒样本，病原体应归类为',
                    metagenomeOrEnvironmentalTip: '目前用于不适于Metagenome/EnvironmentalSample（GSC MIMS compliant）的宏基因组生物样本数据。',
                    metaTip: '用于宏基因组生物样本数据，下设三个小类分别接收来自人类胃肠道、土壤和水相关宏基因数据，即human-gut，soil，water。',
                    backButton: '返回样本类型选择',
                    sampleAttribute: '样本类型',
                    animal: {
                        sampleName: '样本名称',
                        provideClearAndUniqueSampleName: '请提供一个意义明确且唯一的样本名称，该名称能体现您的实验室名称等信息',
                        speciesName: '物种名称',
                        searchAndSelectSampleSpeciesName: '请搜索并选择样本物种名称',
                        breed: '品种',
                        enterDomesticatedAnimalOrPlant: '请输入驯化后动物或植物的品种名称',
                        species: '种属',
                        enterSampleSpecies: '请输入样本的种属或分型信息',
                        age: '年龄',
                        enterSeedlingStageOrPeriod: '请输入样本取样时的苗期/时期',
                        developmentalStage: '发育阶段',
                        enterSampleGrowthStage: '请输入样本生长发育阶段',
                        selectAtLeastOne: '年龄和发育阶段至少选填一个',
                        laboratoryMaterialProvider: '实验材料提供者',
                        enterLaboratoryPIinformation: '请输入实验材料提供实验室PI信息，或储存机构信息',
                        sex: '性别',
                        selectGender: '请选择样本的性别',
                        histopathologicalType: '组织类型',
                        searchOrSelectSampleTissueType: '请搜索并选择取样组织类型，如果样本取自整个个体，可填写wholebody/whole plant',
                        timeOfBirth: '出生时间',
                        enterDateOfBirth: '请输入出生日期',
                        birthPlace: '出生地点',
                        enterPlaceOfBirth: '请输入出生地点',
                        breedingHistory: '育种史',
                        enterBreedingHistory: '请输入育种史',
                        breedingMethod: '育种方法',
                        enterBreedingMethod: '请输入育种方法',
                        cellLine: '细胞系',
                        enterCellLineName: '请输入细胞系名称',
                        cellSubtype: '细胞亚型',
                        enterCellSubtype: '请输入细胞亚型',
                        cellType: '细胞类型',
                        searchCellType: '请搜索并选择样本的细胞类型',
                        sampler: '取样者',
                        enterPersonOrInstitutionName: '请输入取样的人员或机构名称',
                        sampleDepository: '样本保藏机构',
                        enterSourceInstituteName: '请输入来源研究所的名称和独特的唯一识别码标识',
                        deathTime: '死亡时间',
                        enterDateOfDeath: '请输入死亡日期',
                        diseaseName: '疾病名称',
                        enterDiagnosedDiseasesList: '请输入诊断的疾病清单，可以包括多种诊断',
                        diseaseStage: '疾病阶段',
                        enterDiseaseStage: '请输入取样时疾病阶段',
                        genotype: '基因型',
                        enterObservableHostGenotype: '请输入可观察到的宿主基因型',
                        samplingSpot: '取样地点',
                        enterSamplingLocation: '请输入取样地点，格式为: 国家/海洋: 地区。例如: "China: Hunan"',
                        FeedingOrCultureMethods: '饲养/培养方法',
                        enterFeedingOrCulture: '请输入样本饲养或培养方法',
                        healthCondition: '健康状况',
                        enterHealthStatus: '请输入取样时样本的健康状况',
                        isolatedSource: '分离源',
                        enterPhysicalEenvironmental: '请输入描述该生物样本的物理、环境/地理分布信息。',
                        longitudeAndLatitude: '经纬度',
                        enterLatitudeAndLongitude: '请输入取样经纬度信息，例如: 38.98N 77.11W',
                        phenotype: '表型',
                        enterPhenotype: '请输入样本表型信息',
                        sampleType: '样本类型',
                        enterSampleType: '请输入样本类型，如细胞培养、混合培养，组织样本，整个生物，单细胞，或基因组装配',
                        sampleIdentifier: '样本标识符',
                        enterSampleCredentialLabel: '请输入样本凭证标签',
                        sampleStorageCondition: '样本储存条件',
                        enterStorageConditions: '请输入DNA提取前样本的储存条件',
                        registerNumber: '登记册编号',
                        enterRegisterNumber: '请输入样本的登记册编号',
                        handlingMethod: '处理方法',
                        enterSampleProcessingMethod: '请输入样本处理方式',
                    },
                    human: {
                        sampleCharacteristicInformation: '样本特征信息',
                        enterIdentification: '请输入获得该样本的特定个体的识别或描述信息',
                        nation: '民族',
                        enterNationality: '请输入民族',
                        karyotype: '核型',
                        enterChromosomesNumber: '请输入样本细胞核在有丝分裂时其染色体的数量和外观特征，例如: 正常女性46，XX;正常男性46，XY',
                        population: '种群',
                        enterAllOrganismsSum: '请输入生活在一个特定的地理区域，同一群体或物种的所有生物体的总和',
                        race: '人种',
                        moreRefersToRace: '多是指人种，通常界定的其实就是三种,即白人、黄种人和黑人',
                        externalDatabase: '外部数据库',
                        enterExternalDatabaseName: '请输入外部数据库名称',
                        externalDatabaseNumber: '外部数据库编号',
                        enterSampleNumber: '请输入外部数据库中该样本的编号',
                        externalDatabaseURL: '外部数据库URL',
                        enterSampleURL: '请输入外部数据库中该样本的URL',
                    },
                    humanGut: {
                        samplingTime: '取样时间',
                        selectSamplingTime: '请选择取样时间',
                        environmentalBiome: '环境生物群系',
                        addDescriptiveTerms: '添加样本生存大环境的描述术语，如添加多个术语可以用逗号分隔，例如: 对于human-gut，可填写digestive tract environment [ENVO: 01001033 ] 等术语',
                        environmentCharacteristic: '环境特征',
                        addSmallEnvironmental: '添加对样本有因果景响的小环境特征术语，如添加多个术语可以用逗号分隔',
                        environmentalMedium: '环境介质',
                        addEnvironmentalTerms: '添加样本中包含的环境介质（物质）术语，如添加多个术语可以用逗号分隔',
                        host: '宿主',
                        searchHost: '请搜索并选择宿主',
                        biologicalMaterialsReferenceSource: '生物材料参考出处',
                        enterBiomaterialSource: '请输入生物材料参考文章出处或基因组报告出处 (PMID、Doi或URL)',
                        bacteriaAndOxygenRelationship: '菌与氧的关系',
                        selectBacteriaAndOxygenRelationship: '请选择微生物生存与氧气的关系',
                        sampleCollectionDeviceOrMethod: '样本收集装置或方法',
                        enterSampleCollectionMethodAndDevice: '请输入采集样本的方法和装置',
                        sampleTreatment: '样本处理',
                        enterSeparation: '请输入分离期间或分离后对样本的处理',
                        sampleVolume: '样本量',
                        enterSamplesCollectedNumberOrSize: '请输入收集的样本的数量或大小 (如: 体积、质量或面积)',
                        sourceMaterialIdentifier: '源材料标识符',
                        enterSampleUniqueIdentifier: '请输入用于提取核酸和测序的样本材料的唯一标识符，该标识符可以用来指代收集的原始生物材料，以及任何衍生的子样本',
                        chemicalApplication: '化学用品施用',
                        chemicalsGivenList: '给予宿主或采样地点的化学物质清单(如: 抗生素，氮肥，空气过滤器)和时间，可以包含多种化合物',
                        gastrointestinalDisease: '胃肠道疾病',
                        enterGastrointestinalDiseaseHistory: '请输入胃肠道疾病史，可以包含多个疾病',
                        hostAge: '宿主年龄',
                        enterHostAge: '请输入宿主的年龄',
                        hostInstitutionalIndex: '宿主体制指数(BMI)',
                        enterHostBMIl: '请输入宿主BMIl，计算公式=体重/身高的平方',
                        hostProduct: '宿主产物',
                        enterHostProduct: '请输入样本来自的宿主产物，如:stool、 mucus等',
                        hostBodyTemperature: '宿主体温',
                        enterHostTemperature: '请输入取样时，宿主的体温',
                        hostDietaryType: '宿主饮食类型',
                        enterHostDietaryType: '请输入宿主的饮食类型或食性。如动物中常见的Carnivorous (肉食性)、omnivore (植食性)、herbivore (杂食性) 等',
                        hostDisease: '宿主疾病',
                        searchHostDiseaseName: '请搜索并选择宿主疾病名称',
                        hostFamilyRelationship: '宿主家庭关系',
                        enterHostFamilyRelationship: "请输入宿主的家庭关系",
                        hostGenotype: '宿主基因型',
                        enterHostGenotype: '请输入宿主基因型',
                        hostHeight: '宿主身高',
                        enterHostHeight: '请输入宿主身高',
                        hostLastMeal: '宿主上次膳食情况',
                        enterHostLastMeal: '请输入宿主上次进餐食物和时间，可以包含多个值',
                        hostOccupation: '宿主职业',
                        enterHostClass: '请输入宿主职业',
                        hostPhenotype: '宿主表现型',
                        enterHostPhenotype: '请输入宿主表现型',
                        hostPulse: '宿主脉搏',
                        enterRestingPulse: '请输入宿主静息时脉搏，以每分钟脉搏教计算',
                        hostSex: '宿主性别',
                        selectHostGender: '请选择宿主的性别',
                        hostIdentificationNumber: '宿主识别号',
                        enterUniqueHostIdentificationNumber: '请输入宿主唯一的识别号',
                        hostSamplingTissue: '宿主采样组织',
                        searchHostSampleTissueType: '请搜索并选择宿主取样组织类型，如果样本取自整个个体，可填写whole body/whole plant',
                        totalHostMass: '宿主总质量',
                        enterTotalMass: '请输入宿主的总质量，单位取决于宿主',
                        drugCode: '药物代码',
                        multipleDrugCodes: '可以包含多个药物代码',
                        hepaticDisease: '肝脏疾病',
                        enterLiverDiseaseHistory: '请输入肝脏疾病史,可以包括多个疾病',
                        ifOtherCases: '是否有其他病例',
                        whetherCompleteMedical: '是否收集了完整的病例',
                        otherParameters: '其他参数',
                        enterAdditionalMeasurements: '请输入其他测量值或参数',
                        totalOrganism: '生物体总数',
                        enterOrganismsTotalNumber: '请输入每克或单位体积内生物体的总数,应包括生物名称及对应数量，可以包含多个生物体数量',
                        oxygenationState: '氧合状态',
                        selectOxygenationStatus: '请选择样本的氧合状态',
                        disturbanceType: '扰动类型',
                        enterDisturbanceType: '请输入扰动类型，如: 使用化学药物、物理干扰等，以及扰动发生时间，可以包含多个扰动类型',
                        sampleSalinity: '样本盐度',
                        enterSampleSalinity: '请输入样本盐度',
                        sampleStorageTime: '样本存储时间',
                        enterSampleStorageTime: '请输入样本存储时间',
                        sampleStorageLocation: '样本存储位置',
                        enterSampleStorageLocation: '请输入样本存储位置',
                        sampleStorageTemperature: '样本存储温度',
                        enterSampleStorageTemperature: '请输入样本存储温度',
                        specialDiet: '特殊饮食',
                        selectSpecialDiet: '请选择特殊饮食说明，可以包含多种特殊饮食',
                        temperature: '温度',
                        enterLivingOrCulture: '请输入取样时样本的生存或培养环境温度',
                        isolatedSource: '分离源',
                        describePhysical: '描述该生物样本的物理、环境和/或地理分布信息',
                        userDefinedAttribute: '自定义属性',
                        addNecessaryCustomProperties: '请增加必要的自定义属性',
                    },
                    soil: {
                        depth: '深度',
                        depthIsDefined: '深度定义为表层以下的垂直距离。例如，对于沉积物或土壤样本来说，深度分别是从沉积物或土壤表层开始测量的。对于地表下的样本来说，深度可以表述为间距',
                        elevation: '海拔',
                        samplingPointElevation: '从平均海平面的垂直距离测量采样点的海拔高度',
                        agrochemicalAddition: '农用化学品添加',
                        applyingFertilizers: '施化肥、打农药等，以及这些操作的次数和时间',
                        aluminumSaturation: '铝饱和度',
                        enterAluminumSaturation: '请输入铝饱和度（尤其是热带土壤）',
                        determiningAluminumSaturationMethod: '铝饱和度测定的方法',
                        enterReferenceOrMethod: '请输入确定铝饱和度的参照或方法',
                        altitude: '高度',
                        sampleHeight: '样本的高度是指地表与空气中取样地点之间的垂直距离',
                        annualQuarterlyPrecipitation: '年季降水量',
                        enterAverageAnnualSeasonalPrecipitation: '请输入平均年季降水量(mm)',
                        seasonalTemperature: '年季温度',
                        enterAverageAnnualAeasonalTemperature: '请输入平均年季节温度(℃)',
                        cropRotation: '作物轮作',
                        whetherCropsRotated: '作物是否轮作，如果是，请说明轮作安排',
                        currentLandUse: '当前土地用途',
                        selectLandCondition: '请选择当前取样地的土地状况',
                        currentVegetation: '当前植被',
                        accordingStandardVegetation: '根据取样地的标准植被分类,或者农作物',
                        currentVegetationMethods: '当前植被方法',
                        enterVegetationClassifyingMethod: '请输入用于植被分类的参考或方法',
                        drainageCategory: '排水类别',
                        drainageAccordingClassification: '根据标准排水系统 (如: USDA system)得到的排水分类',
                        extremeEvents: '极端事件',
                        enterUnusualPhysical: '请输入可能影响微生物种群的异常物理事件',
                        extremeSalinity: '极端盐度',
                        enterMeasuredSalinity: '请输入测量的盐度',
                        FAOClassification: 'FAO分类',
                        enterSoilFAOClassification: '请输入土壤FAO分类',
                        fire: '火灾',
                        enterHistoryPhysical: '请输入火灾的历史和/或实物证据',
                        flood: '洪水',
                        enterHistoryPhysicalFlood: '请输入洪水的历史和/或实物证据',
                        heavyMetal: '重金属',
                        enterHeavyMetal: '请输入重金属及其浓度;实验对象使用的药物以及使用频率;可以包含多种重金属及其浓度',
                        heavyMetalsDeterminationMethod: '重金属测定方法',
                        aReferenceOrMethod: '用于确定重金属的参考或方法',
                        soilLayer: '土壤层',
                        theTopLayer: '土壤中的特顶层，可平行于土壤表层进行测量，并具有不同于上下各层的物理特征',
                        assayMethod: '测定方法',
                        aReferenceOrMethodForDetermining: '用于测定土壤层的参考或方法',
                        sampleAttachedAnalysisLink: '样本附加分析链接',
                        enterAReferenceLink: '请输入样本附加分析结果的参考链接',
                        soilClassificationInformationLinks: '土壤分类信息链接',
                        enterARferenceLinkDigitalSoil: '请输入数字士壤地图或其他土壤分类信息的参考链接',
                        localSoilClassification: '当地土壤分类',
                        enterASoilClassification: '请输入基于当地土壤分类系统的土壤分类',
                        microbialBiomass: '微生物生物量',
                        enterAPortionOrganicMatter: '请输入土壤中的部分有机物，构成了小于5-10µm的微生物，微生物生物里需要采用修正因子来转换为最终单位，即: 每千克壤中C(或N)的重量(mg)',
                        determinationMethods: '微生物生物量测定方法',
                        determiningMicrobialBiomass: '用于确定微生物生物量的参考或方法',
                        PHValue: 'PH值',
                        enterPHValue: '请输入PH值',
                        PHValueDeterminationMethod: 'PH值测定方法',
                        enterPHMeasurementMethod: '请输入PH值测定方法',
                        mixedDNAExtraction: '混合DNA提取',
                        howManymultipleDNAExtracts: '是否为多个DNA提取物的混合? 有多少个?',
                        previousLandUse: '之前土地用途',
                        determinePreviousLandUse: '用于确定之前土地用途和使用日期的参考或方法',
                        determiningLandUsePreviousMethods: '之前土地用途的测定方法',
                        enterDisturbanceType: '请输入扰动类型，如: 使用化学药物、物理干扰等，以及扰动发生时间，可以包含多个扰动类型',
                        profilePosition: '剖面位置',
                        selectProfileLocation: '请选择取样山坡的剖面位置，相对于周边区域的样本区域位置',
                        determiningSalinityMethod: '测定盐度的方法',
                        determineSalinity: '用于测定盐度的参考或方法',
                        screen: '筛选',
                        enterCollectionDesign: '请输入混合样本的收集设计和/或筛选尺寸，以及筛选的样本的数量',
                        slope: '坡向',
                        slopeDirection: '斜坡的朝向。当向下看斜坡时，用指南针记录观测者所面对的方向(或角度)，如: NW或315"',
                        agrotype: '土壤类型',
                        enterSoilSeriesName: '请输入土壤系列名称或其他下级分类',
                        slopeGradient: '坡度',
                        enterAngle: '请输入地面与水平线之间的角度 (百分比)',
                        soilTypeDeterminationMethod: '土壤类型测定方法',
                        enterSoilTypeMethod: '请输入用于测定土壤类型的参考或方法',
                        holdingConditions: '贮藏条件',
                        enterSamplePriorStorage: '请输入DNA提取前样本的储存条件',
                        soilTexture: '土壤质地',
                        differentParticleSizesrelativeProportion: '使用某种标准系统测算的土壤中矿物颗粒的不同颗粒大小的相对比例',
                        soilTextureDeterminationMethods: '土壤质地测定方法',
                        enterReferenceOrMethod1: '请输入用于测定土壤质地的参考或方法',
                        farmingMethod: '耕种方法',
                        selectFarmingMethod: '请选择耕种方法',
                        totalNitrogenContentDeterminationMethod: '总氮含量测定方法',
                        determiningTotalNitrogenContent: '请输入测定总氮含量的参考或方法',
                        totalNitrogenContent: '总氮含量',
                        enterSampleTotalNitrogenContent: '请输入样品的总氮含量',
                        organicCarbonContentDeterminationMethod: '总有机碳含量测定方法',
                        enterOrganicCarbonContentDeterminingTotal: '请输入测定总有机碳含量的参考或方法',
                        organicCarbonTotalContent: '总有机碳含量',
                        enterTotalOrganicCarbonContent: '请输入土攘中总有机碳含量，单位: g C/kg土壤',
                        soilWaterContent: '土壤含水量',
                        enterSoilWaterContent: '请输入土壤水含量(g/g或cm³/cm³)',
                        determiningSoilWaterContentMethods: '土壤含水量确定方法',
                        enterSoilMoistureDeterminationMethod: '请输入土壤含水量测定方法',
                    },
                    microbe: {
                        authenticationMode: '鉴别方式',
                        enterTaxonomicFieldNamerInformation: '请输入分类学领域的命名人信息',
                        laboratoryHost: '实验室宿主',
                        enterHostName: '请输入在实验室中试验时使用的宿主名称，例如，大肠杆菌DH5a，HeLa细胞',
                        copulativity: '交配性',
                        enterEukaryoticMicroOrganismMating: '请输入真核微生物的交配型',
                        generationHistory: '传代史',
                        enterPassageMethod: '请输入传代次数以及传代方法',
                        serotype: '血清型',
                        serotypeIsASerologicalClassificationMethod: '血清型是菌种分类的一种血清学分类方法，根据菌体抗原（H抗原）的特异性和生理化特性向下再分为各个亚种。如cy098518流感病毒的“H1N1”亚型',
                        serovariant: '血清变型',
                        subspeciesTheFollowingCategories: '亚种以下分类，通常用于抗原性多样的细菌、真茵或病毒中，可作为在细菌分关学上的物种标识符，例如Leptospira',
                    },
                    pathogenCl: {
                        hostDetails: '宿主详细信息',
                        enterHostAdditionalInformation: '请输入宿主的其他补充信息',
                        hostDiseaseOutcome: '宿主疾病结果',
                        enterHostDiseaseResults: '请输入宿主疾病结果',
                        hostDiseaseStaging: '宿主疾病分期',
                        enterSamplingPeriodDiseaseStage: '请输入采样期疾病分期',
                        hostHealthStatus: '宿主健康状况',
                        enterHostHealth: '请输入宿主健康情况',
                        lesionType: '病变型',
                        enterDiseaseTypeClassificationInformation: '请输入致病型分类信息',
                        subset: '亚群',
                        subspeciesFollowingClassification: '亚种以下分类,常用于病毒，表示从单个分离物中获取的亚组',
                        subtype: '亚型',
                        forVirusClassification: '用于病毒分类，如: HIV type1',
                    },
                    plant: {
                        cultivar: '栽培品种',
                        enterSampleCultivarName: '请输入样本栽培品种名称',
                        culturalMethod: '培养方法',
                        enterSampleCultureMethod: '请输入样本的培养方法',
                        heightOrlength: '高度/长度',
                        enterSamplePlantHeightOrWidth: '请输入样本的株高或宽度',
                    },
                    water: {
                        basicity: '碱度',
                        alkalinityRefersNeutralizeAbility: '碱度指将酸中和到碳国盐或碳酸氢盐的能力',
                        alkylDiether: '烷基二醚',
                        enterAlkylDietherConcentration: '请输入烷基二醚的浓度',
                        aminopeptidaseActivity: '氨肽酶活性',
                        enterAminopeptidaseActivityValue: '请输入氨肽酶活性值',
                        ammoniumConcentration: '铵浓度',
                        enterAmmoniumConcentration: '请输入铵浓度',
                        atmosphereData: '大气数据',
                        enterAtmosphericDataMeasurements: '请输入大气数据测量值，可包含多个数据',
                        bacterialProduction: '细菌产量',
                        enterBacteriaProduction: '请输入用同位素吸收法测定的水柱中细菌的产量',
                        bacterialRespirationValue: '细菌呼吸值',
                        enterMeasurement: '请输入水柱中细菌呼吸测量值',
                        bacterialCarbonProduction: '细菌碳产量',
                        enterBacterialCarbonProductionMeasurement: '请输入细菌碳产量测量值',
                        biomass: '生物量',
                        enterBiomass: '请输入生物量，记录应包括所测量的生物的名称，如：微生物，生物量。可以包含多个测量值',
                        literHolaneAlcoholConcentration: '二升霍烷-醇浓度',
                        enterLiterHolaneAlcoholConcentratio: '请输入二升霍烷-醇浓度',
                        bromideConcentration: '溴化物浓度',
                        enterBromideConcentration: '请输入溴化物浓度',
                        calciumConcentration: '钙浓度',
                        enterCalciumConcentration: '请输入钙浓度',
                        carbonNitrogenRatio: '碳氮比',
                        enterCarbonAndNitrogenQuantityRatio: '请输入碳、氮的数量或浓度比',
                        chlorideConcentration: '氯化物浓度',
                        enterChlorideConcentration: '请输入氯化物浓度',
                        enterChlorophyllConcentration: '请输入叶绿素浓度',
                        specificConductance: '电导率',
                        enterWaterConductivity: '请输入水的电导率',
                        density: '密度',
                        enterSampleDensity: '请输入样品的密度',
                        dietherLipidConcentration: '二醚脂质浓度',
                        enterDietherLipidConcentration: '请输入二醚脂质浓度，可包含多种二醚脂质',
                        dissolvedCarbonDioxideConcentration: '溶解二氧化碳浓度',
                        enterDissolvedCarbonDioxideConcentration: '请输入溶解二氧化碳浓度',
                        dissolvedHydrogenConcentration: '溶解氢浓度',
                        enterDissolvedHydrogenConcentration: '请输入溶解氢浓度',
                        dissolvedInorganicCarbonConcentration: '溶解无机碳浓度',
                        enterDissolvedInorganicCarbonConcentration: '请输入溶解无机碳浓度',
                        dissolvedInorganicNitrogenConcentration: '溶解无机氮浓度',
                        enterDissolvedInorganicNitrogenConcentration: '请输入溶解无机氮浓度',
                        dissolvedInorganicPhosphorusConcentration: '溶解无机磷浓度',
                        enterDissolvedInorganicPhosphorusConcentration: '请输入溶解无机磷浓度',
                        dissolvedOrganicCarbonConcentration: '溶解有机碳浓度',
                        enterDissolvedOrganicCarbonConcentration: '请输入溶解有机碳浓度',
                        dissolvedOrganicNitrogenConcentration: '溶解有机氮浓度',
                        enterDissolvedOrganicNitrogenConcentration: '请输入溶解有机氮浓度',
                        dissolvedOxygenConcentration: '溶解氧浓度',
                        enterDissolvedOxygenConcentration: '请输入溶解氧浓度',
                        downPAR: '向下PAR',
                        enterVisibleBandRadiation: '请输入水柱中可见波段辐射和辐照度测量值',
                        fluorescence: '荧光性',
                        enterOriginalConverted: '请输入水的原始或转化的荧光性',
                        glucosidaseActivity: '葡糖苷酶活性',
                        enteGlucoheadEnzymeActivityValue: '请输入葡糖首酶活性值',
                        lightIntensity: '光强度',
                        enterLightIntensity: '请输入光强度',
                        magnesiumConcentration: '镁浓度',
                        enterMagnesiumConcentration: '请输入镁浓度',
                        meanFrictionVelocity: '平均摩擦速度',
                        enterAverageFrictionSpeed: '请输入平均摩擦速度',
                        averagePeakFrictionVelocity: '平均峰值摩擦速度',
                        enterAveragePeakFrictionVelocity: '请输入平均峰值摩擦速度',
                        normalAlkaneConcentration: '正烷烃浓度',
                        enterNalkaneConcentration: '请输入正烷烃浓度，可以包括多个正烷烃及其浓度值',
                        nitrateConcentration: '硝酸盐浓度',
                        enterNitrateConcentration: '请输入硝酸盐浓度',
                        nitriteConcentration: '亚硝酸盐浓度',
                        enterNitriteConcentration: '请输入亚硝酸盐浓度',
                        nitrogenConcentration: '氮浓度',
                        enterNitrogenConcentration: '请输入氮浓度',
                        organicCarbonConcentration: '有机碳浓度',
                        enterOrganicCarbonConcentration: '请输入有机碳浓度',
                        organicMatterConcentration: '有机物浓度',
                        enterOrganicConcentration: '请输入有机物浓度',
                        organicNitrogenConcentration: '有机氮浓度',
                        enterOrganicNitrogenConcentration: '请输入有机氮浓度',
                        particulateOrganicCarbonConcentration: '颗粒有机碳浓度',
                        enterParticulateOrganicCarbonConcentration: '请输入颗粒有机碳浓度',
                        particleOrganicNitrogenConcentration: '颗粒有机氮浓度',
                        enterParticleOrganicNitrogenConcentration: '请输入颗粒有机氮浓度',
                        petroleumHydrocarbonConcentration: '石油烃浓度',
                        enterPetroleumHydrocarbonConcentration: '请输入石油烃浓度',
                        magnesiumChlorophyllinAcidConcentration: '脱镁叶绿酸浓度',
                        enterMagnesiumChlorophyllinAcidConcentration: '请输入脱镁叶绿酸浓度，可包含多个脱镁叶绿酸及其浓度',
                        phosphateConcentration: '磷酸盐浓度',
                        enterPhosphateConcentration: '请输入磷酸盐浓度',
                        phospholipidFattyAcidConcentration: '磷脂脂肪酸浓度',
                        enterPhospholipidFattyAcidConcentration: '请输入磷脂脂肪酸浓度，可包含多个磷脂脂肪酸浓度及其浓度',
                        photonFlux: '光子通量',
                        enterPhotonFluxValue: '请输入光子通量值',
                        potassiumConcentration: '钾浓度',
                        enterPotassiumConcentration: '请输入钾浓度',
                        pressure: '压力',
                        enterPressure: '请输入在大气中样本受到的压力',
                        primaryProduct: '初级产物',
                        enterPrimaryProductMeasurement: '请输入初级产物测量值',
                        redoxPotential: '氧化还原电位',
                        indicatingOxidationOrReduction: '氧化还原电位，相对于气电池测量，表明氧化或者还原电位',
                        nitrateConcentration1: '硅酸盐浓度',
                        enterNitrateConcentration1: '请输入硅酸盐浓度',
                        sodiumConcentration: '钠浓度',
                        enterSodiumConcentration: '请输入钠浓度',
                        solubleActivePhosphorusConcentration: '可溶性活性磷浓度',
                        enterSolubleActivePhosphorusConcentration: '请输入可溶性活性磷浓度',
                        sulfateConcentration: '硫酸盐浓度',
                        enterSulfateConcentration: '请输入硫酸盐浓度',
                        sulfideConcentration: '硫化物浓度',
                        enterSulfideConcentration: '请输入硫化物浓度',
                        suspendedParticulateConcentration: '悬浮颗粒物浓度',
                        enterSuspendedParticulateConcentration: '请输入悬浮颗粒物浓度',
                        tidalStage: '潮汐阶段',
                        selectTidalStage: '请选择潮汐阶段',
                        waterColumnTotalDepth: '水柱总深度',
                        enterWaterColumnTotalDepth: '请输入水柱总深度测量值',
                        totalDissolvedAmmoniaConcentration: '溶解氨总浓度',
                        enterTotalDissolvedAmmoniaConcentration: '请输入溶解氨总浓度，计算公式为: NH4浓度+NONO2浓度+溶解有机氨浓度',
                        totalInorganicNitrogenContent: '无机氮总含量',
                        enterTotalInorganicNitrogenContent: '请输入无机氮总含量',
                        totalParticleCarbonContent: '颗粒碳总含量',
                        enterTotalParticleCarbonContent: '请输入颗粒碳总含量',
                        totalPhosphorusConcentration: '总磷浓度',
                        enterTotalPhosphorusConcentration: '请输入总磷浓度，计算公式为: 总磷浓度=溶解磷总浓度+颗粒磷浓度',
                        waterDischarge: '水流量',
                        enterWaterDischarge: '请输入水流的大小和方向',
                        DNAExtractionSampleSize: '用于DNA提取的样本量',
                        enterDNAExtractionSampleSize: '请输入用于DNA提取的样本的体积(mL)或重量(g)',
                    },

                },
            },
            Compound: {
                Compound: {
                    Title: '上传具体数据',
                    common: {
                        Add: '添加',
                        Submit: '提交',
                        Cancel: '取消',
                        Delete: '删除',
                        Edit: '修改',
                        EditSubmit: '编辑提交'
                    },
                    UploadType: {
                        Title: '上传类型',
                        NewCom: '上传新化合物',
                        SuppExCom: '补充已有化合物的数据',
                        CorrectExCom: '更正已有化合物的信息',
                    },
                    NewCom: {
                        Form: {
                            common: {
                                Add: '添加',
                                Submit: '提交',
                                Cancel: '取消',
                                Delete: '删除',
                                Edit: '修改'
                            },
                            NameAndIdentifier: {
                                Title: '1. 名称和标识符',
                                ComputationDescriptor: '1.1 计算描述符',
                                CharacterType: '字符类型：',
                                Value: '值：',
                                Name: '1.2 名称',
                                MolecularFormula: '1.3 分子式',
                                OtherIdentifiers: '1.4 其他标识符',
                                SynonymsOfMedicalSubjectWords: '1.5 医学主题词近义词',
                                Tips: {
                                    CTTip: '请选择字符类型',
                                    CVTip: '请输入标识符的值',
                                    NTip: '请输入化合物名称',
                                    MFTip: '请输入分子式',
                                    OITTip: '选择字符类型',
                                    OIVTip: '单行输入',
                                    SOMSWTip: '请输入医学主题词近义词',
                                }
                            },
                            ChemicalClassification: {
                                Title: '2. 化学分类',
                                ClassificationTree: '2.1 分类树',
                                TreeNode: '树节点：',
                                Value: '值：',
                                Tips: {
                                    TNTip: '请输入树节点',
                                    TNVTip: '请输入Mesh记录的标识符',
                                }
                            },
                            MedicinesAndDrugInformation: {
                                Title: '3. 药物和药物信息',
                                IndicationsForDrugUse: '3.1 药物适应症',
                                ClassificationOfDrugs: '3.2 药物分类',
                                TherapeuticUses: '3.3 治疗用途',
                                Tips: {
                                    IFDUTip: '请输入药物适应症',
                                    CODTip: '请输入药物分类',
                                    TUTip: '请输入治疗用途',
                                },
                            },
                            PharmacologyAndBiochemistry: {
                                Title: '4. 药理学和生物化学',
                                Pharmacodynamics: '4.1 药效学',
                                AbsorptionDigestionAndExcretion: '4.2 吸收、消化和排泄',
                                DescriptionOfAbsorption: '吸收描述：',
                                DescriptionOfDigestion: '消化描述：',
                                DescriptionOfExcretion: '排泄描述：',
                                MetabolismMetabolites: '4.3 代谢/代谢产物',
                                MechanismOfAction: '4.4 作用机制',
                                Tips: {
                                    PTip: '请输入药效学信息',
                                    DOATip: '请输入吸收描述',
                                    DODTip: '请输入消化描述',
                                    DOETip: '请输入排泄描述',
                                    MMTip: '请输入代谢/代谢产物信息',
                                    MOATip: '请输入作用机制信息',
                                }
                            },
                            Toxicity: {
                                Title: '5. 毒性',
                                ToxicitySummary: '5.1 毒性总结',
                                RouteOfExposure: '5.2 接触途径',
                                Symptoms: '5.3 症状',
                                DetoxificationAndEmergencyTreatment: '5.4 解毒和紧急治疗',
                                Tips: {
                                    TSTip: '请输入毒性总结',
                                    ROETip: '请输入接触途径',
                                    STip: '请输入症状',
                                    DAETTip: '请输入解毒和紧急治疗信息',
                                }
                            },
                            OtherCompoundRelatedInfo: {
                                Title: '6. 其他化合物相关信息',
                                RelatedItemName: '相关项名称：',
                                RelatedItems: '相关项内容：',
                                RITitle: '标题：',
                                RIContent: '内容：',
                                Tips: {
                                    RITTip: '请输入相关信息的标题',
                                    RICTip: '请输入具体内容'
                                }
                            },
                            References: {
                                Title: '7. 参考文献',
                                ArticleTitle: '文章标题：',
                                ArticleAuthor: '文章作者：',
                                Abstract: '摘要：',
                                Periodicals: '期刊：',
                                PeriodicalsName: '期刊名称：',
                                Tips: {
                                    ATTip: '请输入相关信息的标题',
                                    AATip: '请输入参考文献的作者，作者之间以逗号隔开',
                                    PNTip: '请输入期刊的名称',
                                    AbTip: '请输入具体内容'
                                }
                            },
                            DataFile: {
                                Title: '8. 数据文件',
                                Tip: '支持格式: JSON、XML、CSV、DOCX、XLSX、PDF、CAJ'
                            },
                            Options: {
                                ComputationDescriptorOption: {
                                    IUPACName: 'IUPAC名称',
                                    InChI: 'InChI',
                                    InChIKey: 'InChIKey',
                                    SMILES: 'SMILES'
                                },
                                OtherIdentifiersOption: {
                                    CAS: 'CAS',
                                    ECCode: '欧洲共同体(EC)编号',
                                    UNCode: '联合国(UN)编号',
                                    UNICode: '唯一标识码(UNI)',
                                    Wiki: '维基百科',
                                    WikiData: '维基百科数据',
                                    NCICode: 'NCI同义词库代码',
                                    RXCode: 'RX唯一标识符'
                                },
                            }
                        },
                    },
                    SuppAndCorrectExCom: {
                        ReferencesDIO: '参考文献DOI',
                        SupplementaryContent: '补充内容',
                        SupplementaryItem: '补充项',
                        SupplementaryItemName: '补充项名称：',
                        SupplementaryItemContent: '补充项内容：',
                        ContentOfCorrection: '更正内容：',
                        TermOfCorrection: '更正项',
                        TermOfCorrectionName: '更正项名称：',
                        TermOfCorrectionContent: '更正项内容：',
                        Tips: {
                            RDIOTip: '请输入参考文献DOI',
                            YYCIDTip: '请输入YYC ID',
                            SITTip: '请输入补充项',
                            SICTip: '请输入补充内容',
                            DelTip: '是否删除项',
                            TOCTip: '请输入更正项',
                            COCTip: '请输入更正内容',
                        },
                        DataFile: {
                            Title: '数据文件',
                            Tip: '支持格式: JSON、XML、CSV、DOCX、XLSX、PDF、CAJ',

                        },
                    }
                },
            },
            Protein: {
                HeadTitle: '上传具体的数据',
                Functions: '方式:',
                UploadForm: '填写数据',
                UploadFile: '上传文件',
                Tips: '说明：新的蛋白质数据会被赋予一个新的项目标识符',
                Tips2: '说明：您可以直接上传包含数据信息的文件，支持格式xlsx、xls',
                DownloadFile: '下载模板',
                NextStep: '下一步',
                Save: '保存',
                Submit: '提交',
                BaseInfo: {
                    ProteinName: '蛋白质名称',
                    SpeciesSource: '物种来源',
                    SequenceLength: '序列长度',
                    MolMassOfSequence: '序列分子质量(Da)',
                    SerialInformation: '序列信息:',
                    IncludedDataBase: '是否收录在外部数据库',
                    DataBaseNum: '外部数据库项目编号',
                    ProteinFunc: '蛋白质功能介绍:',
                    HomologyData: '同源异构体数据:',
                    ProteinInteraction: '蛋白互作实验数据:',
                    TargetDrugBioassay: '靶点-药物生物活性测定实验室数据:',
                    PosttranslationalModification: '翻译后修饰数据:',
                    InvolvementInDisease: '参与疾病数据:',
                    Protein3DStructural: '蛋白质3D结构数据:',
                    SubcellularLocalization: '亚细胞定位数据:',
                    Domain: '结构域数据:',
                    Other: '其他信息:',
                    Tips: {
                        PNTip: '请输入蛋白质名称',
                        SSTip: '请输入物种来源',
                        SLTip: '请输入序列长度',
                        MMOSTip: '请输入序列分子质量(Da)',
                        SITip: '请输入序列信息',
                        IDBTip: 'Null',
                        DBNTip: '请输入或搜索生成物',
                        PFTip: '请输入蛋白质功能介绍',
                        HDTip: '请输入同源异构体数据',
                        PITip: '请输入蛋白质互作实验数据',
                        TDBTip: '请输入靶点-药物生物活性测定实验数据',
                        PMTip: '请输入翻译后修饰数据',
                        IIDTip: '请输入参与疾病数据',
                        P3STip: '支持上传格式：pdb,cif',
                        SubLTip: '请输入亚细胞定位数据',
                        DTip: '请输入结构域数据',
                        OTip: '其他信息',
                        FilesErr: '文件格式有误！',
                        IDBTip2: '请选择'
                    },
                },
                HumanDisease: {
                    Tip: '说明：新的人类疾病数据会被赋予一个新的项目标识符',
                    DiseaseName: '人类疾病名称:',
                    Abbreviations: '缩写:',
                    AliasName: '别名:',
                    Definition: '定义:',
                    RelatedProtein: '相关蛋白质:',
                    Tips: {
                        DNTip: '请输入人类疾病名称',
                        ATip: '请输入缩写',
                        ANTip: '请输入该人类疾病别名',
                        DTip: '请输入该人类疾病的定义',
                        RPTip: '请搜索该人类疾病的相关蛋白质(检索方式：关键字、yyhomePID)',
                    }
                },
                Articel: {
                    Tip: '说明：新的氨基酸序列有关文章数据就会被赋予一个新的项目标识符',
                    ArticleTitle: '文章标题：',
                    Author: '作者：',
                    Journal: '杂志：',
                    Abstract: '摘要：',
                    Introduce: '介绍：',
                    Result: '结果：',
                    Discussion: '讨论：',
                    Thanks: '致谢：',
                    Quote: '引用：',
                    Conclusion: '结论：',
                    ConflictOfInterest: '利益冲突：',
                    UploadForm: '上传表格：',
                    UploadImg: '上传图片：',
                    SuppMaterials: '补充材料：',
                    FullArticle: '全文文章：',
                    ReferenceDataInfo: '该篇文章涉及哪些数据信息:',
                    Tips: {
                        ATTip: '请输入文章标题',
                        ATip: '请输入文章作者，作者之间以逗号隔开',
                        JTip: '请输入该文章杂志',
                        AbTip: '请输入该文章的摘要',
                        ITip: '请输入该文章的介绍',
                        RTip: '请输入该文章的结果',
                        DTip: '请输入该文章的讨论',
                        TTip: '请输入该文章的致谢',
                        QTip: '请输入该文章的引用',
                        CTip: '请输入该文章的结论',
                        CITip: '请输入该文章的利益冲突',
                        UFTip: '支持上传格式：docx、xlsx、xls、csv、pdf',
                        UITip: '支持上传格式：tif、jpg、pdf、eps',
                        SMTip: '支持上传格式：docx、xlsx、xls、csv、pdf、tif、jpg、eps',
                        FATip: '支持上传格式：docx、csv、pdf',
                        RDIOptions: {
                            ProteinName: '蛋白质名称',
                            AminoAcidSeq: '氨基酸序列',
                            Function: '功能',
                            Homeomer: '同源异构体',
                            ProteinInteraction: '蛋白互作',
                            TargetDrugBioassay: '靶点-药物生物活性测定',
                            PostTranslationalModification: '翻译后修饰',
                            Disease: '疾病',
                            Protein3D: '蛋白质3D结构数据',
                            SubcellularLocalization: '亚细胞定位',
                            Domain: '结构域'
                        }
                    }
                }
            },
            Nature: {
                metabonomics: {
                    uploadFile: '上传文件',
                    Downloadtemplate: '下载模板',
                    projectdescription: '项目描述',
                    projecttitle: '项目标题',
                    projectbrief: '项目摘要',
                    linkman: '联系人',
                    addusers: '添加用户',
                    keyword: '关键字',
                    addkeywords: '添加关键字',
                    isorno: '是否有发表的公共出版物',
                    y: "有，已公开",
                    y2: "有，准备中",
                    n: '无',
                    inputDOI: '输入DOI或PMD：',
                    title: '标题：',
                    summarize: '总结：',
                    sample: '样品/分析细节',
                    name: '联系人的姓名',
                    email: '联系人的电子邮箱',
                    phone1: '联系人的电话号码',
                    phone2: '联系人的电话号码',
                    address: '联系人的地址',
                    user: '用户角色',
                    nameholder: '请输入姓名',
                    emailholder: '请填写正确的邮箱',
                    userholder: '请输入联系人的用户角色',
                    Analysislist: '分析列表：',
                    Newanalysis: '新增分析',
                    edit: '编辑',
                    delete: '删除',
                    projecttitleP: '请输入项目标题',
                    projectbriefP: '请输入项目摘要',
                    linkmanP: '请添加联系人',
                    keywordP: '请添加关键字',
                    poiP: '请输入DOI或PMD',
                    titleP: '请输入标题',
                    summarizeP: '请输入总结',
                    sampleP: '请添加至少一个实验信息',
                    fileP: '当前上传文件只支持.xlsx',
                    lookdetail: '查看详情'
                },
                bioassay: {
                    baseInfo: '基本信息',
                    bioName: '生物测定名称：',
                    bioNameP: '请输入生物测定名称',
                    targetName: '靶点名称：',
                    targetNameP: '请输入靶点名称',
                    targetType: '靶点类型：',
                    targetTypeP: '请输入靶点类型',
                    species: '实验物种：',
                    speciesP: '请输入实验物种',
                    test: '测试化合物计数',
                    act: '活跃数：',
                    actP: '请输入活跃数',
                    notAct: '不活跃数：',
                    notActP: '请输入不活跃数',
                    dataSource: '数据来源',
                    JournalName: '期刊名称：',
                    JournalNameP: '请输入期刊名称',
                    doiP: '请输入DOI',
                    chembl: 'ChEMBL靶点名：',
                    chemblP: '请输入ChEMBL靶点名',
                    relationshipType: '关系类型：',
                    relationshipTypeP: '请输入关系类型',
                    year: '年份：',
                    yearP: '请输入年份',
                    abstract: '摘要：',
                    abstractP: '请输入摘要',
                    result: '实验结果',
                    resultP: '请添加至少一个实验信息',
                    ExpDataList: '实验数据列表：',
                    addExpData: '新增实验数据',
                    data: '数据',
                    tag: '标签（如lndnG150）',
                    tagP: '请输入标签（如lndnG150）',
                    value: '值',
                    valueP: "请输入值",
                    enterValueForReactionCondition: '请输入该反应条件的数值',
                    reactionProcess: '反应过程',
                    enterReactionProcess: '请输入反应过程',
                    references: '参考文献',
                    patent: '专利',
                    articleTitle: '文章标题',
                    enterTheArticleTitle: '请输入文章标题',
                    articleAuthor: '文章作者',
                    enterTheArticleAuthor: '请输入文章作者',
                    periodical: '期刊',
                    dataFile: '数据文件',
                    uploadFiles: '上传文件',
                    supportFormat: '支持格式',
                    enterReferenceDOI: '请输入参考文献DOI',
                    enterPatentNumber: '若数据来源于专利，请输入专利号',
                    journalTitle: '期刊名称',
                    enterJournalName: '请输入期刊名称',
                    // abstract: '摘要',
                    enterAbstract: '请输入摘要',
                    delete: '删除',
                    cancel: '取消',
                    submit: '提交',
                    nextStep: '下一步',
                },
            },
        },


        // 详情页
        Details: {
            Header: {
                HeadTitle: '上传-项目详情',
                Identifier: '标识符',
                Type: '上传项目类别',
                Status: '状态',
                UploadDate: '上传时间',
                Controls: '操作',
                baseinfo: '项目基本信息'
            },
            common: {
                Title: '项目基本信息',
                EditBtn: '编辑',
                DelBtn: '删除',
                Message: {
                    DelSuccess: '删除成功！',
                    DelDefult: '删除失败！',
                    DelErr: '服务器开小差了，请稍后再试！',
                    DelText: '是否删除？',
                    Comfirm: '是',
                    Cancel: '否',
                },
            }
        },
        // 编辑页
        Edit: {
            Header: {
                HeadTitle: '上传-编辑项目',
                CardTitle: '编辑数据'
            }
        },
        Step: {
            newProject: '新建项目',
            upload_newProject: '上传-新建项目',
            enterUserInfo: '输入提交者信息',
            select: '选择上传数据类型',
            uploadData: '上传具体数据',
            pands: '预览&提交',
            note1: '以下步骤将指导您上传项目。提交后，您可以通过yyHome网址查看和编辑您的项目',
            cancel: '取消',
            create: '新建',
            next: '下一步'
        },
        submitInfo: {
            submitInfo: '提交者信息',
            name: '姓名',
            namePlaceholder: '请输入姓名',
            email: '邮箱',
            emailPlaceholder: '请输入邮箱',
            SecondaryEmail: '备用邮箱',
            SecondaryEmailPlaceholder: '请输入备用邮箱',
            organization: '单位/企业/学术机构/非盈利组织名称',
            organizationPlaceholder: '请输入单位/企业/学术机构/非盈利组织名称',
            organizationURL: '单位/企业/学术机构/非盈利组织网址',
            organizationURLPlaceholder: '请输入单位/企业/学术机构/非盈利组织网址',
            department: '部门',
            departmentPlaceholder: '请输入部门',
            tel: '手机',
            telPlaceholder: '请输入手机号',
            fax: '传真',
            faxPlaceholder: '请输入传真',
            state: '国家',
            china: '中华人民共和国',
            Provinces: '省/自治区/直辖市/特别行政区',
            ProvincesPlaceholder: '请输入省/自治区/直辖市/特别行政区',
            postcode: '邮编',
            postcodePlaceholder: '请输入邮编'
        },
        // 上传-预览
        Preview: {
            Title: '预览&提交',
            SubmiterInfo: '提交者信息',
            DataTypeInfo: '数据类型信息',
            DataInfo: '具体数据信息',
            UploadDataBase: '上传数据库',
            UploadBaseInfo: '上传具体数据',
            Submiter: {
                Name: '姓名',
                Phone: '手机',
                Email: '邮箱',
                Address: '地址',
                Unit: '单位',
                Postcode: '邮编',
                Department: '部门',
            }
        },
        Select: {
            selectDatabase: '选择数据库',
            selectThing: '选择上传物质',
            DataBase: {
                Nature: '天然产物库',
                Protein: '蛋白质库',
                Compound: '化合物库',
                Gene: '基因库'
            },
            BaseMaterial: {
                Metabolomics: '代谢组学',
                Bioassay: '生物测定',
                ProteinBaseInfo: '蛋白质基本信息',
                HumanDisease: '人类疾病',
                AminoAcidSequenceRelatedArticles: '氨基酸序列有关文章',
                Compound: '化合物',
                ChemicalReactions: '化学反应',
                BiologicalProject: '生物项目',
                BiologicalSample: '生物样本'
            }
        },
    },
}