import { enAdmet, zhAdmet } from './admet-prediction'
import { enInverseSynthesis, zhInverseSynthesis } from './inverse-synthesis'
import { enTargetPrediction, zhTargetPrediction } from './target-prediction'
import { enStructDraw, zhStructDraw } from './struct-draw'
import { enSimilarCompound, zhSimilarCompound } from './similar-compound'
import { zhPeriodicTable, enPeriodicTable } from './periodic-table'

export default {
  enAdmet,
  zhAdmet,
  enInverseSynthesis,
  zhInverseSynthesis,
  enTargetPrediction,
  zhTargetPrediction,
  enStructDraw,
  zhStructDraw,
  enSimilarCompound,
  zhSimilarCompound,
  zhPeriodicTable,
  enPeriodicTable,
}
