import RouteNames from '@/consts/RouteNames'
import { RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

import GYProtein from './GY/protein'

export const ProteinRoutes: RouteRecordRaw[] = [
  {
    path: '/protein',
    name: RouteNames.Protein.Protein,
    component: () => import('@/views/Protein/layout/ProteinLayout.vue'),
    children: [
      {
        path: '',
        name: RouteNames.Protein.HomePage,
        component: () => import('@/views/Protein/Home/ProteinHome.vue'),
      },
      // {
      //   path: 'search/:keyword',
      //   name: RouteNames.Protein.SearchPage,
      //   component: () => import('@/views/Protein/Search/SearchComponent.vue'),
      // },
      {
        path: 'search/:keyword',
        name: RouteNames.Protein.SearchPage2,
        component: () => import('@/views/Protein/new_serach/SearchHome.vue'),
      },
      {
        path: 'BatchSearch',
        name: RouteNames.Protein.BatchSearchPage,
        component: () => import('@/views/Protein/BatchSearch/ProteinBatchSearch.vue'),
      },
      {
        path: 'protein/:id',
        name: RouteNames.Protein.ProteinDetailsPage,
        component: () => import('@/views/Protein/Details/ProteinDetails/ProteinDetails.vue'),
      },
      {
        path: 'humanDiseases/:id',
        name: RouteNames.Protein.HumanDiseasesDetailsPage,
        component: () => import('@/views/Protein/Details/HumanDiseasesDetails/HumanDiseasesDetails.vue'),
      },
      {
        path: 'target/:id',
        name: RouteNames.Protein.TargetDetailsPage,
        meta: {
          marginBottom: true, //设置距离底部
          breadcrumb: [
            { id: '/home', text: 'nav.home' },
            { id: '/protein', text: 'breadcrumb.Protein' },
            { id: '', text: 'protein.target' },
            { id: '', text: 'common.detail' },
          ],
        },
        beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
          to.meta.breadcrumb![2].id = to.query.fromPath as string
          next()
        },
        component: () => import('@/views/Protein/Details/ProteinTargetDetails2/index.vue'),
      },
      {
        path: 'aboutus', //关于我们页面
        name: RouteNames.Protein.AboutUsPage,
        component: () => import('@/views/Protein/AboutUs/AboutUs.vue'),
      },
      {
        path: 'dataStatistic',
        name: RouteNames.Protein.DataStatisticPage,
        component: () => import('@/views/Protein/DataStatistic/ProteinDataStatistic.vue'),
      },
      {
        path: 'clusterAnalysis',
        name: RouteNames.Protein.ClusterAnalysis,
        component: () =>
          import('@/views/Protein/ClusterAnalysis2/index.vue'),
      },
      {
        path: 'AlphaFold/:id',
        name: RouteNames.Protein.AlphaFold,
        component: () => import('@/views/Protein/Details/AlphaFold/AlphaFoldDetails.vue'),
      },
      // 虚拟筛选
      {
        path: 'virtualFiltering',
        name: RouteNames.Protein.VirtualFiltering,
        component: () => import('@/views/Protein/virtual-filtering/index.vue'),
      },
      ...GYProtein,
    ],
  },
]
