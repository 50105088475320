import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  StructureDraw: '结构绘制',
  Search: '搜索',
  Reset: '重置',
  MolecularFormula: '结构简式',
  MolecularWeight: '相对分子质量',
}
const zhParagraphInfo = {
  tip01: '请绘制区域或输入Smiles式',
  tip02: '请输入正确的Smiles',
  tip03: '绘制图形与Smiles不能同时存在',
  tip04: 'IUPAC 名称',
  tip05: '暂无搜索结果，请重新绘制或输入',
  tip06: '说明：您可以直接在绘制面板中进行结构绘制，也可以点击绘制面板顶部的',
  tip07: '上传化合物结构文件，此外，您还可以在底部输入框中输入SMILES式来上传化合物。',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)
enParagraphInfo.tip01 = 'Please draw areas or enter Smiles'
enParagraphInfo.tip02 = 'Please enter the correct Smile'
enParagraphInfo.tip03 = 'Drawing and Smiles cannot exist at the same time'
enParagraphInfo.tip04 = 'IUPAC Name'
enParagraphInfo.tip05 = 'No search results, please redraw or enter'
enParagraphInfo.tip06 = 'You can directly draw the structure in the drawing panel, you can also click on the top of the drawing panel'
enParagraphInfo.tip07 = 'Upload the compound structure file, in addition, you can also type SMILES in the bottom input box to upload the compound.'

export const zhStructDraw = { ...zhBaseInfo, ...zhParagraphInfo }
export const enStructDraw = { ...enBaseInfo, ...enParagraphInfo }
