export default {
  nature: {
    overview: '总览',
    HeadTitle: '天然产物',
    HeadText: '素华春漠漠，丹实夏煌煌',
    similarCompound: '相似化合物',
    compound: '化合物',
    compoundSuggest: '化合物',
    food: '食用植物',
    plant: '药用植物',
    bioassay: '生物测定',
    bioassaySuggest: '生物测定',
    cell: '细胞系',
    cellSuggest: '细胞系',
    taxonomy: '分类学',
    foodSuggest: '食用植物',
    plantSuggest: '药用植物',
    compound_chemical_reaction: '化学反应',
    chemicalReaction: '化学反应',
    compound_taxonomy: '分类学',
    OrganismsTaxonomy: '生物分类学',
    Browser: '数据概览',
    LearnMore: '了解更多更新',
    AboutText:
      '天然产物库是世界上最大的可免费访问的化学信息集合。按名称、分子式、结构和其他标识符搜索化学品。查找化学和物理特性、生物活性、安全性和毒性信息、专利、文献引用等。是世界上最大的可免费访问的化学信息集合。按名称、分子式、结构和其他标识符搜索化学品。查找化学和物理特性、生物活性、安全性和毒性信息、专利、文献引用等。',
    LearnMoreBtn: '了解更多',
    SearchBox: {
      Label1: '化合物',
      Label2: '食用植物',
      Label3: '药用植物',
      Label4: '生物测定',
      Label5: '细胞系',
    },
    search: {
      compound: {
        MolecularFormula: '结构简式',
        MolecularWeight: '相对分子质量',
        IUPACName: 'IUPAC名称',
        CreateDate: '创建日期',
      },
      food: {
        LatinName: '拉丁名',
        EnglishName: '英文名',
        Description: '简述',
        cytologicalClassification: '细胞分类',
        soutce: '来源',
        ChineseName: '中文名'
      },
      plant: {
        LatinName: '拉丁名',
        EnglishName: '英文名',
        Description: '简述',
        ChineseName: '中文名'
      },
      bioassay: {
        ProjectCategory: '生物测定类型',
        TestedCompounds: '测试化合物数量',
        TestedSubstances: '测试物质数量',
        Source: '数据来源',
        DepositDate: '上传日期',
        Description: '简述',
      },
      taxonomy: {
        name: '名称',
        k: '界',
        p: '门',
        c: '纲',
        o: '目',
        f: '科',
        s: '种',
        g: '属',
        Classificationlevel: '分类等级',
        besubordinateto: '从属于',
        QuickLinks: '快速链接',
        DisplayDetails: '显示详情',
        SpeciesClassificationTree: '物种分类树',
        EukaryoticOrganisms: '真核生物',
        Bacteria: '细菌',
        Archaea: '古菌',
        Virus: '病毒',
        Other: '其他',
        unclassified: '未分类',
        title1: '您搜索的物种关键词：',
        title2: '名称：',
        Nucleicacid: '核酸',
        protein: '蛋白质',
        compound: '化合物',
        bioassay: '生物测定',
        ClassificationTree: '分类树',
        Details: '详情',
        all: '全部',
        UseClassification: '用途分类',
        TaxonomyOfOrganisms: '生物分类学',
        homeText1: '鹰击长空，鱼翔浅底，万类霜天竞自由。',
        Included: '共收录了',
        piecesof: '条',
        CategorizedData: '分类数据',
        relateProtein: '相关蛋白质',
        relateGene: '相关基因',
        relateBioassay: '相关生物测定',
        relateProteinId: '蛋白质Id',
        relateGeneId: '基因Id',
        relateBioassayId: '生物测定Id',
        relateProteinName: '蛋白质名称',
        relateGeneName: '基因名称',
        relateBioassayName: '生物测定名称',
        otherInfo: '其他相关信息',
        relatePlant: '相关植物',
        relatePlantId: '植物Id',
        relatePlantName: '植物名称'
      },
      reaction: {
        ChemicalReactionId: '化学反应YYCReaction ID',
        Reactants: '反应物',
        Product: '生成物',
        ConditionOfReaction: '反应条件',
        temperature: '温度',
        stirring: '搅拌',
      },
    },
    details: {
      cellLines: {
        Summary: '细胞系摘要',
        NameCode: '名称和标识符',
        OtherName: '其他命名',
        OtherCode: '其他标识符',
        CellSource: '细胞来源',
        CompoundBioassay: '化合物相关生物测定',
        AssociatedDiseases: '相关疾病',
        Reference: '相关文献',
        Classify: '分类',
        InfoSource: '信息来源',
        IdentifierType: '标识符类型',
        DataSource: '数据来源',
        Identifier: '标识符',
        CellSourceType: '细胞来源类型',
        BioName: '生物测定名称',
        BioValue: '测定结果',
        BioId: '生物测定ID',
        LiteratureId: '文献ID',
        date: '出版日期',
        name: '名称',
        JournalTitle: '期刊名',
        CellSorting: '细胞分类',
        SourceOrganization: '来源组织',
        CellGender: '细胞性别',
        UpdateTime: '数据修改时间',
        Basic: '基础信息',
        ChemicalsAndBioactivities: '化合物相关测定',
        Diseases: '相关疾病',
        Literature: '相关文献',
        Classification: '分类',
        References: '信息来源',
        Knowledge: '知识图谱'
      },
      compound: {
        Summary: '化合物摘要',
        YYNPsId: '化合物id',
        Name: '名称',
        Structure: '结构',
        ChemicalSafety: '化学安全符',
        MolecularFormula: '分子式',
        Synonyms: '别名',
        MolecularWeight: '分子量',
        Structure2D: '2D结构',
        Structure3D: '3D结构',
        NameAndIdentifiers: '名称与编码',
        ComputedDescription: '计算机表征',
        OtherIdentifiers: '其他编码',
        PhysicalAndChemicalCharacteristics: '理化特征',
        ChemicalPropertyName: '计算特征',
        PysicalDescription: '实验特征',
        PharmacologyAndIochemistry: '药理学和生物化学',
        BiochemicalReaction: '生化反应',
        AbsorptionDistributionAndExcretion: '吸收分布和排泄',
        BiologicalHalfLife: '生物半衰期',
        Pharmacology: '药理学',
        HumanMetabolite: '人体代谢',
        Metabolism: '代谢',
        BiologicalActivity: '生物活性',
        EcologicalInformation: '生态信息',
        ArtificialPollutionSources: '人工污染源',
        EnvironmentalBiodegradation: '环境生物降解',
        Mobility: '流动性',
        Soil: '土壤',
        EnvironmentalWaterConcentrations: '环境水浓缩',
        EnvironmentalAbioticDegradation: '环境非生物降解',
        EnvironmentalFate: '环境归趋',
        EnvironmentalBioconcentration: '环境生物浓缩',
        ExposureSummary: '概述',
        CompoundSimilarity: '相似化合物',
        DataSources: '来源数据库',
        DataId: 'id',
        DataType: 'id类型',
        ViewType: '展示类型',
        BiologicalSource: '生物来源',
        Standard: '标准',
        Reaction: '反应',
        CellularLocations: '亚细胞定位',
        TissueLocations: '组织定位',
        Similarity: '相似度',
        StructuralSimplification: '结构简式',
        Reference: '引用',
        Basic: '基础信息',
        Knowledge: '知识图谱'
      },
      bioassay: {
        Intro: '生物测定摘要',
        DataSource: '数据来源',
        ExperimentResult: '实验结果',
        TargetInformation: '靶点信息',
        OriginalCitation: '原创引文',
        Detail: '详情信息',
        BioassayAnnotation: '生物测定注释（待完善）',
        InfoSource: '信息来源',
        YYAID: 'YYBAssay ID',
        TargetType: '靶点类型',
        Source: '来源',
        ExperimentalSpecies: '实验物种',
        TestCompoundCount: '测试化合物计数',
        DataUpdateDate: '数据更新日期',
        All: '全部',
        Active: '活跃',
        InActive: '不活跃',
        JournalTitle: '期刊名称',
        YearDate: '年份',
        DOI: 'DOI',
        ChEMBLTargetName: '靶点名',
        RelationshipType: '关系类型',
        Summary: '摘要',
        Structure: '结构',
        YYHomeNPSID: 'YYHomeNPSID',
        YYHomeCID: 'YYHomeCID',
        Activity: '活性',
        TargetName: '靶点名称',
        DetectionForm: '检测形式',
        DetectionType: '检测类型',
        DetectionInfo: '检测信息',
        DetectionOfCellType: '检测细胞类型',
        DetectionOrganism: '检测生物',
        DeterminationOfTissue: '测定组织',
        Licence: '许可证',
        BasicInfo: '基本信息',
        OrganismTestName: '生物测定名',
        ProjectClassification: '项目分类',
        OtherRelevantBiologicalAssays: '其他相关生物测定',
        aid: '相关生物测定id',
        aidname: '相关生物测定名称',
        testedsidcnt: '被测试物种数量',
        activesidcnt: '活跃物种数量',
        aidtype: '相关生物测定类型',
        Basic: '基础信息',
        References: '信息来源',
        Knowledge: '知识图谱'
      },
      food: {
        Summary: '食用植物摘要',
        FoodbId: 'fooddb数据库id',
        EnglishName: '英文名',
        ChineseName: '中文名',
        OtherName: '其他名称',
        LatinName: '拉丁名',
        Picture: '图片地址',
        Description: '描述',
        Classification: '类别',
        PrimaryClassification: '第一分类',
        SecondaryClassification: '第二分类',
        Taxonomy: '分类学',
        Kingdom: '界',
        Phylum: '门',
        Class: '纲',
        Order: '目',
        Family: '科',
        Seed: '种',
        Genus: '属',
        Application: '应用',
        PreparationMethod: '制备方式',
        Dose: '剂量',
        Effect: '功效',
        Indications: '适用症',
        Contraindication: '禁忌症',
        OtherUses: '其他用途',
        Refaddress: '引用地址',
        Compounds: '化合物组成',
        DataSources: '数据来源',
        DataType: '数据类型',
        DataId: 'id',
        ViewType: '展示类型',
        Macronutrients: '营养成分',
        References: '引用',
        Reference: '引用地址',
        struct: '结构',
        maxConcentration: '最大含量',
        minConcentration: '最小含量',
        avgConcentration: '平均含量',
        reference: '来源',
        macronutrient: '营养成分',
        food: '食用植物',
      },
      plant: {
        Summary: '药用植物摘要',
        MorphologicalCharacteristics: '形态特征',
        LifeHabits: '分布区域',
        MapDistribution: '地图分布',
        FoodbId: 'plantdb数据库id',
        EnglishName: '英文名',
        ChineseName: '中文名',
        LatinName: '拉丁名',
        OtherName: '其他名称',
        Picture: '图片地址',
        Description: '描述',
        Classification: '类别',
        PrimaryClassification: '第一分类',
        SecondaryClassification: '第二分类',
        Taxonomy: '分类学',
        Kingdom: '界',
        Phylum: '门',
        Class: '纲',
        Order: '目',
        Family: '科',
        Seed: '种',
        Genus: '属',
        Application: '中医应用',
        PreparationMethod: '制备方式',
        Dose: '剂量',
        Effect: '功效',
        Indications: '适用症',
        Contraindication: '禁忌症',
        OtherUses: '其他用途',
        Refaddress: '引用地址',
        Compounds: '化合物组成',
        DataSources: '数据来源',
        DataType: '数据类型',
        DataId: 'id',
        ViewType: '展示类型',
        Macronutrients: '营养成分',
        References: '引用',
        Reference: '引用地址',
        IntroductionOfMedicinalMaterials: '药材介绍',
        Basigen: '基原',
        FlavorAndMeridianTropism: '性味归经',
        PrimaryFunction: '功能主治',
        DosageAndUsage: '用量用法',
        IndicationAndContraindication: '宜忌',
        SideSelection: '选方',
        Pharmacology: '药理',
        picture: '图片',
        plant: '药用植物',
      },
    },
    StructureDraw: {
      Title: '结构绘制',
      inputTitle: '请输入Smiles...',
    },
    DataStatistic: {
      Title1: '物种分类图示',
      Title2: '化合物分类图示',
      Text1:
        '下图为生物分类学分类层次的表示。单击一个部分展开它，然后单击下面的浏览按钮开始更详细地研究分类法级别。',
      Text2:
        '下图为YYHOME化合物库中化合物分类层次的表示，单击一个部分展开它，然后单击中心按钮返回上一级别。',
      BtnText: '物种全览',
    },
    NatureBrowser: {
      Compound: '化合物',
      Plant: '药用植物',
      Food: '食用植物',
      Bioassay: '生物测定',
      Cell: '细胞系',
      MoleFormula: '分子式:',
      MoleWeight: '分子量:',
      LatinName: '拉丁名:',
      EnglishName: '英文名:',
      TestedCompounds: '测试化合物数',
      TestedSubstances: '测试物质数',
      CellCategory: '细胞分类',
      SourceOrganism: '来源',
    },
  },
}
