import lang from '../protein'
export default {
  protein: {
    human_diseases: 'Human diseases',
    go: 'Go',
    protein: 'Protein',
    references: 'References',
    virtualScreening: 'Virtual screening',
    molecularDockingTool: 'Web-based molecular docking tool',
    target: 'Target-Compound Bioassay',
    total: 'Total',
    HeadTitle: 'Start your protein exploration journey',
    HeadText: 'All data, Professional analysis, Deep mining',
    LearnMore: 'Learn more updates',
    AboutText:
      'yyHome Protein databases are international, innovative, interdisciplinary, and champions of open data in the field of life sciences. We are part of the yyHome Strategic Data Platform, a 30-person R & D team exclusively funded, operated and maintained by Ningbo Yongheng Yaoyao Intelligent Technology Co., Ltd. We are located in No.1277, Zhongguan West Road, Ningbo City, Zhejiang Province, China.',
    LearnMoreBtn: 'Learn more',
    HumanDiseasesSuggest: 'Human Diseases',
    proteinSuggest: 'Protein',
    alpha: 'AlphaFold Protein Structure Prediction',
    proteinAlphaFoldSuggest: 'AlphaFold Protein Structure Prediction',
    AlphaFold: 'AlphaFold Protein Structure Prediction',
    SearchBox: {
      Label1: 'All',
      Label2: 'Human Disease',
      Label3: 'Protein',
    },
    //主页数据统计
    Statisitc: {
      Protein: 'Protein',
      Target: 'Target',
      Protein3D: 'Protein 3D structure',
      ProteinInteraction: 'Protein Interaction',
      HumanDisease: 'Human Disease',
      HomologyIsomers: 'Homology Isomers',
      DrugTargetInteraction: 'Drug Target Interaction',
    },
    //主页功能按钮
    functionNav: {
      Batch: 'Batch Search',
      DataStatistic: 'Data Statistic',
      Blast: 'Basic tool for local sequence alignment search（BLAST）',
      Msa: 'Multiple sequence alignment（MSA）',
      phylogenetictree: 'phylogenetic tree',
      AnalysisPhyPro: 'Analysis of physicochemical property',
    },
    search: {
      ProteinBaseInfo: 'Basic Protein Information',
      HumanDiseaseData: 'Human Disease Data',
      TargetBaseInfo: 'Basic Target Information',
      protein: {
        sequence: 'Sequence',
        createtime: 'Create time',
        keywords: 'Keywords',
        Type: 'Type',
      },
      go: {
        sourceDatabase: 'Database Source',
        sourceDatabaseID: 'Database Source ID',
        term: 'Terminology',
        index: 'Index',
        aspect: 'Aspect',
      },
      target: {
        ActivitiesCount: 'Biological Activity',
        targetOrganism: 'Species Name',
        CompoundsCount: 'Compounds Count',
        prefName: 'Target Name',
        baoLabel: 'Type',
      },
      AlphaFold: {
        Organism: 'Organism of origin',
      },
    },
    details: {
      protein: {
        summary: 'Protein Summary',
        name: 'Protein name',
        englishname: 'English name',
        alternativenames: 'Protein aliases',
        id: 'Protein sequence number',
        pid: 'Protein Pid',
        uniprotid: 'Protein Uniprot id',
        sequencesandfeature: 'Sequence and molecular weight',
        sequences: 'One-dimensional amino acid sequence information',
        sequencesfeature: 'Features',
        locationandfeature: 'Location',
        subcellularlocation: 'Protein subcellular localization',
        subcellularfeature: 'Features',
        organisms: 'Classification',
        scientificName: 'Species name',
        organism: 'Taxonomic lineage',
        functions: 'Functions',
        processingandfeature: 'post-translational modification',
        processing: 'post-translational modification',
        processingfeature: 'Features',
        diseaseandvariants: 'Diseases & Pathogens',
        disease: 'Involvement in disease',
        natural_variants: 'Mutation',
        diseasefeature: 'Feature',
        interactionandSubunit: 'Interaction',
        interaction: 'Binary interactions',
        interactionSubunit: 'Subunit',
        family: 'Family & Domains',
        familyDomainInformation: 'Domain of structure',
        familyDomainInformationSimilarity: 'Sequence similarity',
        familyDomainFeature: 'Features',
        expression: 'expression',
        tissueSpecificity: 'Specificity of tissue',
        InductionOfFeeling: 'Induction of feeling',
        StageOfDevelopment: 'Stage of development',
        type: 'Type',
        loginNum1: 'ENTRY 1',
        loginNum2: 'ENTRY 2',
        testNum: 'Number Of Experiments',
        intact: 'Intact',
        sequence: 'One-dimensional amino acid sequence information',
        species: 'Type',
        molweight: 'Molecular weight',
        recommendedname: 'Protein Full Name',
        references: 'References',
        functionfeature: 'Features',
        function: 'Function',
        structure: 'Protein 3D Structure',
        structureFeature: 'Features',
        structure3D: '3D Structure',
        notice: 'Notice',
        describe: 'Description',
        Features: {
          Type: 'Type',
          Location: 'Position(S)',
          Description: 'Description',
        },
        Structure3D: {
          DataBase: 'Source',
          Identifier: 'Identifier',
          Function: 'Method',
          Resolution: 'Resolution',
          CHAIN: 'Chain',
          Positions: 'Positions',
        },
        AminoAcidSequenceInformation: {
          Select: {
            highlight: 'Highlight',
            PolarAminoAcids: 'Polar',
            HydrophobicAminoAcids: 'Hydrophobic',
          },
          Copy: 'Copy sequence',
          CopySuccess: 'Copied to clipboard!',
          CopyError: 'Copy failed!',
          LengthOfSequence: 'Length',
          LastUpdatedTime: 'Last updated',
          MolarMass: 'Mass(Da)',
          CheckOut: 'Check',
          Distinction: 'Difference from the canonical sequence: ',
          BtnTextShow: 'Show sequence',
          BtnTextClose: 'Hide sequence',
        },
        References: {
          ReferencesEngName: 'English name of literature',
          AuthorName: 'Author name',
          QuoteFrom: 'Quoted from',
          AcademicJournal: 'Academic journal',
        },
        protein_loginNumber: 'Protein accession number',
        yyhomeProteinId: 'yyhome Protein database login number',
        uniprotId: 'Uniprot database login number',
        BiologicalName: 'Biological Name',
        baseInfo: 'BaseInfo',
        knowledge: 'Mapping Knowledge Domain',
        more: 'Retrieve more Knowledge Graph information',
      },
      humanDiseases: {
        define: 'Definition',
        abbreviation: 'Abbreviation',
        alias: 'Alias',
        keyword: 'Keyword',
        crossReference: 'Cross reference',
        disclaimers: 'Disclaimer',
        correlationYYhomeProtein: 'Associated YYhome protein',
        Protein: 'Protein',
        EnglishName: 'English name',
        ScientificName: 'Species name',
        Length: 'Length',
        Statement:
          'Any medical or genetic information present in this article is for research, educational and informational purposes only, and it is not used in any way as a substitute for professional medical construction, diagnosis, treatment or care. Our staff is made up of biologists, biochemists, chemists, computer experts, algorithm experts who are not trained to give medical advice.',
        HeadTitle: 'Diseases - ',
        baseInfo: 'BaseInfo',
      },
      proteinTarget: {
        NameAndClassification: 'Target Summary',
        BiologicalActivityVisualizationChart: 'Biological Activity Visualization Chart',
        VisualizationChartOfLigandEfficiency: 'Visualization Chart Of Ligand Efficiency',
        DetailedExperimentalDataSheet: 'Detailed Experimental Data Sheet',
        Type: 'Type: ',
        PreferredName: 'Preferred name: ',
        Synonyms: 'Synonyms: ',
        BiologicalName: 'Species name: ',
        GroupsOfSpecies: 'Groups of species: ',
        ProteinTargetsClassification: 'Classification of protein targets: ',
        TotalProject: 'Total Project: ',
        StandardValue: 'Standard Value ',
        Title: 'The ligand efficiency diagram plotted the binding efficiency index ( BEI ) and surface efficiency index ( SEI ), where : ',
        NoData: 'No data available',
        StructureDiagram: 'Structure Diagram',
        StructuralFormula: 'Structural Formula',
        Activity: 'Activity',
        StandardType: 'Standard Type',
        StandardRelation: 'Standard Relation',
        StandardUnit: 'Standard Unit',
        target: 'Target - compound bioassay',
      },
      AlphaFold: {
        AlphaFold: 'AlphaFold protein structure prediction',
        baseInfo: 'BaseInfo',
        references: 'References',
        cif: 'mmCIF File',
        Predicted: 'Predicted alignment error',
        protein: 'Protein',
        gene: 'Gene',
        organism: 'Source organism',
        ProteinPredicted: 'Protein structure prediction',
        error: 'Tutorial on predicting alignment errors',
        struct3D: '3D structure',
        struct3Dtool: '3D structure viewer',
      },
    },
    //聚类分析
    analysis: {
      DataUpload: 'Data Upload',
      AnalysisDetails: 'Analysis Details',
      Uploadanewtree: 'Upload a new tree',
      note1: 'Note: You can paste the tree directly into the tree text input box, or upload a file using the tree file selector.',
      treeName: 'Tree names (optional):',
      SingleLineInput: 'Single Line Input',
      TreeText: 'Tree Text:',
      MultiLineInput: 'Multi-Line Input',
      treeFile: 'Tree File:',
      note2: 'You can drag and drop files directly here, supported formats: newick, txt',
      Reset: 'Reset',
      Submit: 'Submit',
      Toolbar: 'Toolbar',
      Mode: 'Mode:',
      Round: 'Round',
      Rectangle: 'Rectangle',
      BranchLength: 'Branch Length:',
      Use: 'Use',
      Ignore: 'Ignore',
      downloadExmaple: 'Download Sample',
      returnUpload: 'Return Upload',
      unrooted: 'unrooted',
    },
    msa: {
      SubmitTask: 'Submit Task',
      MyMSATask: 'My MSA Task',
      SelectionSequence: 'Selection Sequence',
      FindProteinSequenceByYYHomePID: ' Find a protein sequence by YYHomePID (e.g: 1426) to align with the Clustal Omega program.',
      pieces: 'pieces',
      PasteAListOfIds: 'You can also paste a list of ids, separated by commas.(e.g: 1426,1427,1428)',
      PleaseEnterYYHomePID: 'Please enter YYHomePID or id list...',
      OrEnterMultiple: 'or Enter multiple protein or nucleotide sequences, separated by a FASTA header.',
      SelectFile: 'Select File',
      ProteinOrNucleotide: 'Protein or nucleotide sequences in FASTA format...',
      NameYourMSAJob: 'Name your MSA job',
      Unnamed: 'unnamed',
      OutputSequenceOrder: 'Output sequence order',
      PleaseSelectOutputSort: 'Please select output sort',
      Iterations: 'Iterations',
      PleaseSelectIterations: 'Please select Iterations',
      Submit: 'Submit',
      Reset: 'Reset',
      FindYourMSATask: 'Find your MSA task',
      YourMSAResultsAreKept: 'Your MSA results are kept for up to 1 week',
      PleaseEnterNameToQueryTheJob: 'Please input a name to query the job...',
      Search: 'Search',
      Task: 'Task',
      Name: 'Name',
      SortBySimilarity: 'Sort by similarity',
      SortByInputOrder: 'Sort by input order',
      Overview: 'Overview',
      Trees: 'Trees',
      PercentIdentityMatrix: 'Percent ldentity Matrix',
      TextOutput: 'Text Output',
      InputParameters: 'Input Parameters',
      APIRequest: 'API Request',
      Download: 'Download',
      SubmitTo: 'Submit to',
      Status: 'Status',
      ExpirationTime: 'Expiration time',
      Handle: 'Handle',
      ThisOperationIsNotRecoverable: 'This operation is not recoverable. Are you sure?',
      Confirm: 'Confirm',
      Cancel: 'Cancel',
      WithoutDay: 'Without day',
      SuccessfullyDelete: 'Successfully delete',
      FailToDelete: 'Fail to delete',
      FailedStatus: 'Failed status and cannot be viewed',
      RunningStatus: 'Running status and cannot be viewed',
      PleaseSelectAtLeastSequences: 'Please select at least 3 sequences',
      NoMoreThanPieces: 'No more than 300 pieces',
      SuccessfullySubmit: 'Successfully Submit',
      FailToSubmit: 'Fail to Submit',
      TheJobWasSubmittedWithTheFollowing: 'The job was submitted with the following original input values, job ID',
      MSAJobName: 'MSA Job Name',
      CommitSequence: 'Commit sequence',
      UsingCurl: 'Using curl, you could run a new job on the command line with the same input as this job by running',
      ResultingAlignmentInClustalFormat: 'Resulting alignment in Clustal format',
      OriginalCommitSequence: 'Original commit sequence',
      PhylogeneticTreeInNewickNotation: 'Phylogenetic tree in Newick notation',
      EnterTheSameSequence: 'Do not enter the same sequence',
      IncorrectFormat: 'Incorrect format, please check',
    },
    //数据统计
    DataStatistic: {
      LineTitle: 'Protein sub-database number line chart',
      LineHeadText: 'Protein sub-database included data volume line chart',
      LineUnit: 'Collection quantity/piece',
      LineText: 'Amount of data',
      VersionTitle: 'Protein sub-database history version statistics',
      VersionName: 'Version name',
      Version: 'Version',
      VersionOnlineDate: 'Online Date',
      PieTitle: 'Protein sub-database included species classification fan diagram',
      PieHeadText: 'Protein sub-database species classification fan diagram',
      LargeDataTitle: 'Statistics of amino acid sequence length included in protein sub-database',
      LargeDataHeadText: 'Amino acid length distribution of protein sub-database',
      LargeDataUnit: 'Sequence length/AA',
      BarTitle: 'Statistics of amino acid composition included in protein sub-database',
      BarHeadText: 'Protein sub-database amino acid composition proportion histogram',
      BarUnit: 'Proportion/%',
      BarText: 'Kinds of amino acid',
      TableTitle: 'Amino acid species table',
      ChineseName: 'Chinese name',
      EngName: 'English name',
      Abbreviation: 'Three-letter abbreviation',
      Symbol: 'Symbol',
    },
    // 虚拟筛选
    VF: { ...lang.enVF },
    // 理化性质
    PP: { ...lang.enPhysicochemicalProperty },
  },
}
