import { RouteRecordRaw } from 'vue-router'

export default [
  {
    path: '/batchSearch',
    component: () => import('@/views/Home/batch-search/index.vue'),
    meta: { marginBottom: true },
    redirect: '/batchSearch/BS-Form',
    name: 'BS-Home',
    children: [
      {
        path: 'BS-Form',
        name: 'BS-Form',
        component: () => import('@/views/Home/batch-search/view/bs-form/index.vue'),
      },
      {
        path: 'BS-Result',
        name: 'BS-Result',
        meta: { showWaitHeader: true },
        component: () => import('@/views/Home/batch-search/view/bs-result/index.vue'),
      },
    ],
  },
] as RouteRecordRaw[]
