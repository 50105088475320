import lang from '@/lang/gene'
export default {
  gene: {
    biosample: 'Biological Sample',
    bioproject: 'Biological Projects',
    nucleotide: 'Nucleotide',
    nucleotideSequence: 'Nucleotide Sequence',
    clinvar: 'Phenotype And VOrfFinderariation',
    gene: 'Gene',
    nlp: 'NLP',
    title: 'Genetic technology changes the world',
    title2: 'Natural selection, survival of the fittest',
    text1:
      'It has established a COVID-19 database, biological project database, biological sample database, gene database, mutation database, phenotype database, mutation and phenotype association database, etc., which can upload/download data, sequence comparison analysis, primer design, exon search, identify gene open reading frame, etc., and integrate scientific research, technology, market, and consulting advantages. It covers multiple omics and multiple technology platforms, enables breakthrough changes in gene technology, improves the entire industrial chain of genomics application, and builds a global leader in gene data science service industry',
    Blast: 'Basic tool for local sequence alignment search（BLAST）',
    Blast1: 'Basic tool for local sequence alignment search',
    SearchBox: {
      Label1: 'All',
      Label2: 'Gene',
      Label3: 'Biological Project',
      Label4: 'Biological Sample',
      Label5: 'Nucleotide',
      Label6: 'Phenotype and Variation',
      Label7: 'NLP',
    },
    details: {
      bioSample: {
        sampleCode: 'Sample Number',
        BiologyName: 'Species Name',
        SampleType: 'Model of sample',
        descriptionInfo: 'Description Information',
        sampleType: 'Sample Types',
        sampleProperties: 'Sample Properties',
        releaseDate: 'Date Of Promulgation',
        BioProject: 'Biological Project',
        Submittingunit: 'Submit Unit',
        BaseInfo: 'Basic Information',
      },
      bioProject: {
        projectCode: 'Project Number',
        AreasInvolved: 'Correlative Field',
        dataType: 'Data Type',
        sampleRange: 'Sample Range',
        scientificName: 'Species name',
        descriptionInfo: 'Description Information',
        publicationInfo: 'Publishing Information',
        TrackLabelPrefix: 'Trajectory Label Prefix',
        ArticleTitle: 'Article Title',
        MagazineName: 'Magazine Name',
        AuthorName: 'Author Name',
        Yearofpublication: 'Year of publication',
      },
      gene: {
        Summary: {
          Species: 'Species',
          GeneAliases: 'Gene Aliases',
          GeneType: 'Gene Type',
          expression: 'Expression',
          CodingForProteins: 'Coding For Proteins',
        },
        source: 'Source',
        geneSummary: 'Gene Summary',
        externalId: 'External ID',
        description: 'Description',
        classify: 'Classify',
        summarize: 'Summarize',
        BasicInformation: 'BasicInformation',
        GenomicRegions: 'Genomic regions, transcripts, and products',
        GenomicBackground: 'Genomic Background',
        relevantContent: 'Relevant Content',
        phenotype: 'Phenotype',
        geneExpression: 'Gene Expression',
        InteractsWithHIV: 'Interacts with HIV-1',
        PubChemApproach: 'PubChem Approach',
        interaction: 'Interaction',
        expression: 'Expression',
        NCBIRefSeq: 'NCBI RefSeq',
        correlationSequences: 'Correlation Sequences',
        reference: 'Reference',
        Profile: 'Gene Summary',
        GeneName: 'Gene Name',
        BiologyName: 'Biolvgical Name',
        Bloodlines: 'Pedigree',
        CodeProteinInfo: 'Encoding Protein Information',
        PreferredName: 'Preferred Name',
        CommonName: 'Predominant Name',
        LoginNumber: 'Accessing Number',
        MainSource: 'Primary Source',
        GeneType: 'Genotype',
        RelatedContent: 'View Related Content',
        SequenceStatus: 'Reference Sequence State',
        ExpressPart: 'Gene Expression Site',
        CorrelationSequence: 'Associated Sequences',
        Protein: 'Protein',
        locatingInformation: 'Locating Information',
        GeneChineseName: 'Gene ChineseName',
        GeneEnglishName: 'Gene EnglishName',
        NumberOfExons: 'Number of Exons',
        GeneAlsoKnownAsName: 'Gene Alias',
        chineseName: 'Gene ChineseName',
        projectTitle: 'Project Title',
        projectDescription: 'Project Description',
        projectID: 'Project ID',
        publication: 'Publication',
        englishName: 'Gene ChineseName',
        alsoKnownAsName: 'Gene Alias',
        analysisDate: 'Analysis Date',
        viewMore: 'View More',
        approachTitle: 'Approach Title',
        Title: 'Title',
        ProteinCode: 'Protein-coding Genes',
        product: 'Product',
        interactant: 'Interactant',
        otherGene: 'Other Gene',
      },
      nucleotide: {
        NucleotideName: 'Nucleotide Sequence Summary',
        BasicInformation: 'BasicInformation',
        Position: 'Location',
        Definition: 'Definition',
        Accession: 'Accession Number',
        Version: 'Version Number',
        DatabaseLink: 'Database Link',
        Keyword: 'key Word',
        Source: 'Source',
        ScientificName: 'Biolvgical Name',
        Reference: 'Reference',
        Annotation: 'Comment Information',
        Characteristic: 'Feature',
        Blast: 'Source Sequence',
        length: 'Sequence Length',
        MolecularCategory: 'Molecular Category',
        GeneTopologyType: 'Gene Topology Type',
        articletitles: 'Article Titles',
        author: 'Author',
        HightStruct: 'High-level structure of nucleotide sequence',
        periodical: 'Periodical',
        remark: 'Remark',
      },
      PhenotypeMutation: {
        Abstract: 'Abstract',
        baseInformation: 'Basic Information',
        detail: 'Variant Details',
        condition: 'Condition',
        relatedGene: 'Related Information',
        variationAndLocation: 'Variation and location information',
        Paraphrase: 'Paraphrase',
        AuditStatus: 'Audit Status',
        SubmitInformation: 'Submit Information',
        FirstSubmissionToPhenotypeAndVariantTime: 'First submission time',
        TimeOfLastSubmission: 'Time of last submission',
        TimeOfLASTAssessment: 'Time of last assessment',
        AccessionNumber: 'Accession Number',
        NumberOfVariants: 'Number Of Variants',
        SpecificDescription: 'Specific Description',
        alleleId: 'Allele ID',
        variantType: 'Variant Type',
        variantLength: 'Variant Length',
        location: 'Cytogenetic Location',
        genomicLocation: 'Mapping The GENOME',
        HGVSPerformance: 'HGVS',
        NucleotideSequence: 'Nucleotide Sequence',
        protein: 'Protein',
        CorollaryOfMolecules: 'Corollary of molecules',
        proteinChange: 'Changes In Protein',
        otherNames: 'Variant Names',
        canonicalSpdi: 'SPDI Criteria',
        functionalConsequence: 'Functional OUTCOME',
        frequency: 'GMAF Frequency',
        alleleFrequency: 'Allele Frequency',
        link: 'Links To Relevant Databases',
        ConditionsOfInterpretation: 'Conditions of interpretation',
        NumberOfSubmissions: 'Number of submissions',
        StatusOfReview: 'Status of review',
        VariationOrConditionRecording: 'Variation/Condition Recording',
        Gene: 'Gene',
        ClinGenGeneDoseSensitivityRanking: 'ClinGen Gene Dose Sensitivity Ranking',
        HaploidFunctionalScores: 'Haploid Functional Scores',
        ThreeTimesSensitivity: 'Three Times Sensitivity',
        VariantViewer: 'Variant Viewer',
        VariantsOfInterest: 'Variants Of Interest',
        InternalGenes: 'Internal Genes',
        AllGenes: 'All Genes',
      },
    },
    resetMap: {
      click: '点击',
      reset: '重置',
    },
    search: {
      gene: {
        organism: 'Species Name',
        alsoKnownAsName: 'Gene Alias',
        englishName: 'English Name',
        chineseName: 'Chinese Name',
        OfficialSymbol: 'Official Symbol',
        SequenceMatching: 'BLAST Sequence Matching',
      },
      biosample: {
        organism: 'Species Name',
        sampleType: 'Sample Type',
        submissionData: 'Date Of Promulgation',
        SampleNumber: 'Sample Number',
        belongPorjuct: 'Affiliated Project',
      },
      nucleotide: {
        organism: 'Species Name',
        AccessionId: 'Accession Number',
        length: 'Sequence Length',
        yyMolType: 'Molecular Category',
        GraphicVisualization: 'Graphic Visualization',
      },
      clinvar: {
        proteinChange: 'Protein Changes',
        accession: 'Accession Number',
        chromosomeNumber: 'Chromosome Number',
        relatedGene: 'Related Gene',
        interpretation: 'Clinical Significance',
        genomicLocation: 'Specific Positioning',
        reviewStatus: 'Audit Status',
      },
      bioproject: {
        name: 'Name Of Biological Project',
        organismName: 'Species Name',
        dataType: 'Data Type',
        revelance: 'Correlative Field',
        sampleRange: 'Sample Range',
      },
      nlp: {
        quotation: 'Quotation',
        belongSpecies: 'Species',
      },
    },
    DataStatistic: {
      DataOverview: 'Data Overview',
      genedosage: 'Number of genes',
      Lines: {
        gene: 'The number of individual genes',
        nucleotide: 'Number of sequences',
        clinvar: 'Number of variants',
        bioProject: 'Number of projects',
        bioSample: 'Number of samples',
        nlp: 'NLP',
      },
      biologicalsample: 'Biological Sample',
      Biologicalprojects: 'Biological Projects',
      Phenotypeandvariation: 'Phenotype And Variation',
      nucleotide: 'Nucleotide',
      title1: 'Line map of the number of gene subbanks',
      title2: 'Gene sublibrary contains data volume line map',
      time: 'Time',
      title3: 'The number of collections / articles',
      title4: 'Number of gene library line chart',
      content1: 'The following figure shows the line chart of gene data changes in the YYHOME database for each year, and different views can be switched.',
      title5: 'Number line chart of nucleotide sequence library',
      content2:
        'The following diagram shows the change line diagram of nucleotide sequence data in the YYHOME database of each year and month, which can be switched between different views.',
      title6: 'Phenotype and variation associated library number line chart',
      content3: 'The following figure shows the change line chart of phenotype and variation association data in YYHOME database of each year, which can switch different views.',
      title7: 'Number line chart of biological project library',
      content4: 'The following figure shows the change line chart of biological project data in YYHOME database in each year, which can switch different views.',
      title8: 'The number of biological sample library line chart',
      content5: 'The following figure shows the change line chart of biological sample data in YYHOME database in each year, which can switch different views.',
      tabTitle1: 'Gene Number',
      tabTitle2: 'Nucleotide Number',
      tabTitle3: 'Phenotype and Variation Number',
      tabTitle4: ' Biological Project Number',
      tabTitle5: 'Biological Sample Number',
      tabTitle6: 'NLP  Number',
      data: 'Data',
      tableTitle1: 'Gene sub-library data version number statistics',
      verName: 'Version Name',
      verNumber: 'Version',
      onlinetime: 'Online Time',
    },
    // 限制性酶切位点分析工具
    ResetMap: lang.enResetMap,
    NLPDetail: lang.enNLPDetail,
    // orf finder
    OF: lang.enOrfFinder,
  },
}
