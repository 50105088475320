import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  SimilarCompound: '相似化合物搜索',
  Structure: '结构',
  name: '名称',
  MolecularFormula: '结构简式',
  Similarity: '相似度',
  Compound: '化合物',
  Nature: '天然产物库',
  All: '全部',
}
const zhParagraphInfo = {}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)

export const zhSimilarCompound = { ...zhBaseInfo, ...zhParagraphInfo }
export const enSimilarCompound = { ...enBaseInfo, ...enParagraphInfo }
