// 上传页面的路由配置
import { RouteRecordRaw } from 'vue-router'
import RouteNames from '@/consts/RouteNames'
import { getCookie } from '@/directives/imgLazy'
import { ElMessage } from 'element-plus'

function noLogin(to: any, from: any) {
  if (getCookie('Authorization')) {
    return true
  }
  else {
    ElMessage({
      message: '请先登录！',
      type: 'warning',
    })
    return { name: RouteNames.Home.HomePage }
  }
}
const uploadItem: RouteRecordRaw[] = [
  // 上传首页
  {
    path: '/upload',
    name: RouteNames.Upload.Upload,
    component: () => import('@/views/upload/layout/UploadLayout.vue'),
    beforeEnter: [noLogin],
    children: [
      {
        path: '',
        name: RouteNames.Upload.UploadHome,
        component: () => import('@/views/upload/upload-home/index.vue'),
      },
      // 上传项
      {
        path: 'item',
        name: RouteNames.Upload.UploadItem,
        component: () => import('@/views/upload/upload-item/index.vue'),
      },
      {
        path: 'detail/:id',
        name: RouteNames.Upload.UploadDetail,
        meta: {
          marginBottom: true, //设置距离底部
          breadcrumb: [
            { id: '/home', text: 'nav.home' },
            { id: '/upload', text: 'breadcrumb.Upload' },
            { id: '', text: 'common.ProjectDetail' },
          ],
        },
        component: () => import('@/views/upload/upload-details/HomeDetails.vue')
      },
      {
        path: 'edit/:id',
        name: RouteNames.Upload.UploadEdit,
        meta: {
          marginBottom: true,
          breadcrumb: [
            { id: '/home', text: 'nav.home' },
            { id: '/upload', text: 'breadcrumb.Upload' },
            { id: '', text: 'common.EditProject' }
          ]
        },
        component: () => import('@/views/upload/upload-edit/HomeEdit.vue')
      }
    ]
  },
]

export { uploadItem }
