import RouteNames from '@/consts/RouteNames'
import { RouteRecordRaw } from 'vue-router'

import GYGene from './GY/gene'
export const GeneRoutes: RouteRecordRaw[] = [
  {
    path: '/gene',
    name: RouteNames.Gene.Gene,
    component: () => import('@/views/Gene/layout/GeneLayout.vue'),
    children: [
      {
        path: '',
        name: RouteNames.Gene.HomePage,
        component: () => import('@/views/Gene/Home/GeneHome.vue'),
      },
      {
        path: 'search/:keyword',
        name: RouteNames.Gene.SearchPage,
        component: () => import('@/views/Gene/Search/GeneSearch_new.vue'),
      },
      {
        path: 'BatchSearch',
        name: RouteNames.Gene.BatchSearchPage,
        component: () => import('@/views/Gene/BatchSearch/GeneBatchSearch.vue'),
      },
      {
        path: 'aboutus', //关于我们页面
        name: RouteNames.Gene.AboutUsPage,
        component: () => import('@/views/Gene/AboutUs/AboutUs.vue'),
      },
      {
        path: 'gene/:id',
        name: RouteNames.Gene.GeneDetailsPage,
        component: () => import('@/views/Gene/Details/GeneDetails/GeneDetails.vue'),
      },
      {
        path: 'bioProject/:id',
        name: RouteNames.Gene.BioProjectDetailsPage,
        component: () => import('@/views/Gene/Details/BioProjectDetails/BioProjectDetails.vue'),
      },
      {
        path: 'bioSample/:id',
        name: RouteNames.Gene.BioSampleDetailsPage,
        component: () => import('@/views/Gene/Details/BioSampleDetails/BioSampleDetails.vue'),
      },
      {
        path: 'nucleotide/:id',
        name: RouteNames.Gene.NucleotideDetailsPage,
        component: () => import('@/views/Gene/Details/NucleotideDetails/NucleotideDetails.vue'),
      },
      {
        path: 'nucleobase',
        name: RouteNames.Gene.nucleobase,
        component: () => import('@/views/Gene/nucleobase/nucleobase.vue'),
      },
      {
        path: 'phenotypeMutation/:id',
        name: RouteNames.Gene.PhenotypeMutationDetailsPage,
        component: () => import('@/views/Gene/Details/PhenotypeMutationDetails/PhenotypeMutationDetails.vue'),
      },
      {
        path: 'dataStatistic',
        name: RouteNames.Gene.DataStatisticPage,
        component: () => import('@/views/Gene/DataStatistic/GeneDataStatistic.vue'),
      },
      {
        path: 'primerUploadDetail',
        name: RouteNames.Gene.PrimerUploadDetail,
        meta: {
          marginBottom: true, //设置距离底部
          // breadcrumb: [
          //   { id: '/home', text: 'nav.home' },
          //   { id: '/gene', text: 'breadcrumb.Gene' },
          //   { id: '/gene/primerUploadDetail', text: 'primer.primerdesign' },
          // ],
        },

        component: () =>
          import('@/views/Home/primer_design_upload_detail/index.vue'),
      },
      {
        path: 'primerHistoryMission',
        name: RouteNames.Gene.PrimerHistoryMission,
        component: () =>
          import('@/views/Home/primer_design_upload_detail/components/history/index.vue'),
      },
      {
        path: 'primerOutput',
        name: RouteNames.Gene.PrimerOutput,
        component: () =>
          import('@/views/Home/primer_design_upload_detail/components/output/index.vue'),
      },
      {
        path: 'waitingInterface',
        name: RouteNames.Gene.WaitingInterface,
        component: () =>
          import('@/views/Home/primer_design_upload_detail/components/waiting-interface/index.vue'),
      },
      {
        path: "primerFail",
        name: RouteNames.Gene.PrimerFail,
        component: () => import('@/views/Home/primer_design_upload_detail/components/fail/index.vue'),
      },
      // {
      //   path: 'orfFinder',
      //   name: RouteNames.Gene.ORFFinder,
      //   meta: {
      //     marginBottom: true, //设置距离底部
      //     // breadcrumb: [
      //     //   { id: '/home', text: 'nav.home' },
      //     //   { id: '/gene', text: 'breadcrumb.Gene' },
      //     //   { id: '/gene/orfFinder', text: 'gene.OrfFinder.HeadTitle' },
      //     // ],
      //   },
      //   component: () => import('@/views/Home/ORFFinder/index.vue'),
      // },
      {
        path: 'JBrowse',
        name: RouteNames.Gene.JBrowse,
        component: () => import('@/views/Home/JBrowse/JBrowse.vue'),
      },
      {
        path: 'JBrowseSearch/:id',
        name: RouteNames.Gene.JBrowseSearch,
        component: () => import('@/views/Home/JBrowse/JBrowseSearch/JBrowseSearch.vue'),
      },
      {
        path: 'JBrowseDownload',
        name: RouteNames.Gene.JBrowseDownload,
        component: () => import('@/views/Home/JBrowse/JBrowseDownload/index.vue'),
      },
      ...GYGene,
    ],
  },
]
