export default {
    upload: {
        // 主页
        Home: {
            allItem: 'All Items',
            notsubmitted: 'Not Submitted',
            underreview: 'Under Review',
            isPublic: 'Already Passed',
            notpass: 'Not Pass',
            Newuploadproject: 'New Upload Project',
            uploadType: 'Upload Type',
            filter: 'Filter',
            projectName: 'Project Name',
            status: 'Status',
            uploadDate: 'Upload Date',
            operation: 'Operation',
            info: 'The review will take some time, please be patient',
            isDelete: 'Do you want to delete?',
            yes: 'yes',
            no: 'no',
            UploadTitle: 'Upload',
            SelectTypeOption: {
                All: 'All',
                NatureMetabolomics: 'Natural Product-Metabolomics',
                NatureBioassay: 'Natural Product-Bioassays',
                CompoundNewCom: 'Compound-New Compounds',
                CompoundComReaction: 'Compound-Chemical Reaction',
                ProteinBaseInfo: 'Protein-Basic Information About Proteins',
                ProteinDisease: 'Protein-Human Disease Information',
                ProteinArticles: 'Protein-Amino Acid Sequence Related Articles',
                GeneBioProject: 'Gene-Biology Project',
                GeneBioSample: 'Gene-Biology Sample',
                CompoundSuppCom: 'Compound-Complementary Correction Compound'
            }
        },
        // 上传
        Upload: {
            common: {
                save: 'Save',
                delete: 'delete',
                cancel: 'Cancel',
                submit: 'Submit',
                add: 'Add',
                nextStep: 'Next step',
                saveRemind: 'Your project will be saved in the database, can be edited at any time, and once you are satisfied with the project, the reviewer will review it again.',
                submitSuccessfully: 'submit successfully',
                submitFailure: 'submit failure',
                saveSuccessfully: 'save successfully',
                saveFailure: 'save failure',
                cannotEmpty: 'The input cannot be empty',
                SubmitTitle: 'Submit',
                SubmitText: 'Are you sure you want to submit this upload project?',
                ConfirmText: 'Confirm',
                CancelText: 'Cancel',
                CancelUpload: 'Cancel Upload',
                CancelSave: 'Cancel Save',
                SaveTitle: 'Save',
                SaveText: 'Your project will be saved in the database and can be edited at any time until you are satisfied and submit the project for review by reviewers.',
                DataFiles: 'Data Files',
                FilesErr: 'Incorrect file format!',
                DragText: 'Drop file here or ',
                UploadFile: 'Upload file',
                RequiredMsg: 'Please enter the required fields!',
                FilesMsg: 'Please upload the file!',
                NextStep: 'Next Step',
                SubmitSucc: 'Submit successfully',
                SubmitErr: 'Commit failure',
                EditSucc: 'Edit successfully',
                EditErr: 'Edit failure',
            },
            compound: {
                reaction: {
                    reactant: 'reactant',
                    substance: 'substance',
                    cannotEmpty: 'The input cannot be empty',
                    enterSearchCompounds: 'Please enter or search for compounds',
                    concentrationUnits: 'concentration(units)',
                    enterReactantConcentration: 'Please enter the concentration(units) of the reactive substance',
                    agentia: 'agentia',
                    enterOrSearchReagent: 'Please enter or search for reagent substance',
                    enterAgentiaConcentration: 'Please enter the concentration(units) of the agentia',
                    solvent: 'solvent',
                    enterOrSearchSolvent: 'Please enter or search for solvent substance',
                    enterSolventConcentration: 'Please enter the concentration(units) of the solvent',
                    catalyst: 'catalyst',
                    enterOrSearchCatalyst: 'Please enter or search for catalyst substance',
                    enterCatalystConcentration: 'Please enter the concentration(units) of the catalyst',
                    product: 'product',
                    enterProductConcentration: 'Please enter the concentration(units) of the product',
                    reactionCondition: 'reaction condition',
                    conditionType: ' condition type',
                    enterReactionConditions: 'Please input reaction conditions, such as temperature, stirring, etc',
                    value: 'value',
                    enterValueForReactionCondition: 'Please enter a value for the reaction condition',
                    reactionProcess: 'reaction process',
                    enterReactionProcess: 'Please enter the reaction process',
                    references: 'references',
                    patent: 'patent',
                    articleTitle: 'article title',
                    enterTheArticleTitle: 'Please enter the title of the article',
                    articleAuthor: 'article author',
                    enterTheArticleAuthor: 'Please enter the author of the article',
                    periodical: 'periodical',
                    dataFile: 'Data file',
                    uploadFiles: 'Upload files',
                    supportFormat: 'Support format',
                    enterReferenceDOI: 'Please enter the reference DOI',
                    enterPatentNumber: 'If the data is from a patent, enter the patent number',
                    journalTitle: 'journal title',
                    enterJournalName: 'Please enter the journal name',
                    abstract: 'abstract',
                    enterAbstract: 'Please enter abstract',
                    delete: 'delete',
                    cancel: 'Cancel',
                    submit: 'Submit',
                    nextStep: 'Next step',
                },
            },
            gene: {
                bioProject: {
                    basicProjectInformation: 'Basic project information',
                    projectTitle: 'project title',
                    enterProjectTitle: 'Please enter the project title',
                    involvedField: 'involved field',
                    selectInterestArea: 'Please select an area of interest',
                    projectDescription: 'project description',
                    enterProjectDescription: 'Please enter a project description',
                    capitalSource: 'capital source',
                    organization: 'organization',
                    selectMechanism: 'Please select a mechanism',
                    itemClassification: 'item classification',
                    enterProjectCategory: 'Please enter the project category',
                    porojectAapprovalNumber: 'project approval number',
                    enterProjectApprovalNumber: 'Please enter the project approval number',
                    projectName: 'project name',
                    enterProjectName: 'Please enter a project name',
                    dataType: 'data type',
                    itemDataType: 'item data type',
                    samplingRange: 'sampling range',
                    enterSampleRange: 'Please enter a sample range',
                    sampleRangeSelectionPrompts: 'sample range selection prompts',
                    monoisolateTip: 'A single animal, cultured cell line, inbred population (or possibly a single genotypic population generated from a collection of samples), note that this is a narrow range of application and is not a preferred option ;',
                    multiisolateTip: 'A sample set of multiple genotypes of individuals of the same species ;',
                    multiSpeciesTip: 'Multi-species sample set ;',
                    environmentTip: 'Environmental sample set, mostly used for metagenomic research ;',
                    syntheticTip: 'A collection of samples manufactured/synthesized in the laboratory ;',
                    singleCell: 'Single cell sequencing sample set ;',
                    otherTip: 'Other sample sets/Special sample sets that do not fit into the above categories ;',
                    publicationInformation: 'publication Information(optional)',
                    articleTitle: 'article title',
                    enterTheArticleTitle: 'Please enter the title of the article',
                    journal: 'journal',
                    enterMagazineName: 'Please enter the magazine name',
                    author: 'author',
                    enterAuthor: 'Please enter author',
                    publicationYear: 'year of publication',
                    enterPublicationYear: 'Place enter the year of publication',
                    enterPubMed: 'Please enter the PubMed',
                    addContentCannotEmpty: 'Add content cannot be empty',
                },
                bioSample: {
                    basicInformation: 'basic information',
                    biologicalProjectNumber: 'biological project number',
                    selectBiologicalItemNumber: 'Please select a biological item number',
                    orYouCanCreate: 'or you can create ',
                    newBiologicalProject: ' new biological project',
                    sampleTitle: 'sample title',
                    enterSampleTitle: 'Please enter a sample title',
                    sampleSpecification: 'sample specifications',
                    enterSampleDescription: 'Please enter a sample description',
                    sampleType: 'sample type',
                    pathogenTip: 'For pathogen samples associated with health announcements',
                    microbeTip: 'For samples of microorganisms that can specifically name a species, but not pathogenic bacteria or viruses. Metagenome/Environmental Sample (GSC MIMS unsupported) or Metagenome/Environmental Sample (GSC MIMS compliant) recommended for Metagenome data selection.',
                    animalTip: 'Multicellular samples or cell line samples for model organisms or animals, such as rats, mice, fruit flies, nematodes, fish, amphibians, or other mammalian data.',
                    humanTip: 'Human genetic resource related omics raw data are submitted to the GSA for Human database.',
                    plantTip: 'For plant samples or plant cell line samples.',
                    virusTip: 'For all virus samples not associated with the disease, the pathogen should be classified as',
                    metagenomeOrEnvironmentalTip: 'Currently used is not suitable for Metagenome/EnvironmentalSample (GSC MIMS compliant) metagenomic biological sample data.',
                    metaTip: 'For metagenomic biological sample data, there are three subclasses that receive macro-factor data from human gastrointestinal tract, soil and water respectively, namely, human-gut, soil and water.',
                    backButton: 'Returns sample type selection',
                    sampleAttribute: 'Sample attribute',
                    animal: {
                        sampleName: 'sample name',
                        provideClearAndUniqueSampleName: 'Please provide a clear and unique sample name that reflects information such as the name of your laboratory',
                        speciesName: 'species name',
                        searchAndSelectSampleSpeciesName: 'Please search and select a sample species name',
                        breed: 'breed',
                        enterDomesticatedAnimalOrPlant: 'Please enter the species name of the domesticated animal or plant',
                        species: 'species',
                        enterSampleSpecies: 'Please enter the species or type information of the sample',
                        age: 'age',
                        enterSeedlingStageOrPeriod: 'Please enter the seedling stage/period at which the sample was sampled',
                        developmentalStage: 'developmental stage',
                        enterSampleGrowthStage: 'Please enter sample growth stage',
                        selectAtLeastOne: 'Age and developmental stage Select at least one',
                        laboratoryMaterialProvider: 'laboratory material provider',
                        enterLaboratoryPIinformation: 'Please input laboratory PI information or storage facility information',
                        sex: 'sex',
                        selectGender: 'Please select the gender of the sample',
                        histopathologicalType: 'histopathological type',
                        searchOrSelectSampleTissueType: 'Please search for and select the sample tissue type, or wholebody/whole plant if the sample is taken from an entire individual',
                        timeOfBirth: 'time of birth',
                        enterDateOfBirth: 'Please enter date of birth',
                        birthPlace: 'birth place',
                        enterPlaceOfBirth: 'Please enter place of birth',
                        breedingHistory: 'breeding history',
                        enterBreedingHistory: 'Please enter breeding history',
                        breedingMethod: 'breeding method',
                        enterBreedingMethod: 'Please enter the breeding method',
                        cellLine: 'cell line',
                        enterCellLineName: 'Please enter cell line name',
                        cellSubtype: 'cell subtype',
                        enterCellSubtype: 'Please enter cell subtype',
                        cellType: 'cell type',
                        searchCellType: 'Please search and select the cell type of the sample',
                        sampler: 'sampler',
                        enterPersonOrInstitutionName: 'Please enter the name of the person or institution sampled',
                        sampleDepository: 'sample depository',
                        enterSourceInstituteName: 'Please enter the name of the source institute and a unique identifier',
                        deathTime: 'death time',
                        enterDateOfDeath: 'Please enter the date of death',
                        diseaseName: 'disease name',
                        enterDiagnosedDiseasesList: 'Please enter a list of diagnosed diseases, which can include multiple diagnoses',
                        diseaseStage: 'disease stage',
                        enterDiseaseStage: 'Please enter the disease stage at the time of sampling',
                        genotype: 'genotype',
                        enterObservableHostGenotype: 'Please enter an observable host genotype',
                        samplingSpot: 'sampling spot',
                        enterSamplingLocation: 'Please enter the sampling location in the format: Country/Ocean: Region.For example:"China: Hunan"',
                        FeedingOrCultureMethods: 'feeding/culture methods',
                        enterFeedingOrCulture: 'Please enter the feeding or culture method of the sample',
                        healthCondition: 'health condition',
                        enterHealthStatus: 'Please enter the health status of the sample at the time of sampling',
                        isolatedSource: 'isolated source',
                        enterPhysicalEenvironmental: 'Please enter the physical, environmental/geographic distribution information that describes the biological sample.',
                        longitudeAndLatitude: 'longitude and latitude',
                        enterLatitudeAndLongitude: 'Please enter the sampling latitude and longitude information, for example: 38.98N 77.11W',
                        phenotype: 'phenotype',
                        enterPhenotype: 'Please enter the sample phenotype information',
                        sampleType: 'sample type',
                        enterSampleType: 'Please enter a sample type, such as cell culture, mixed culture, tissue sample, whole organism, single cell, or genome assembly',
                        sampleIdentifier: 'sample identifier',
                        enterSampleCredentialLabel: 'Please enter the sample credential label',
                        sampleStorageCondition: 'sample storage condition',
                        enterStorageConditions: 'Please enter the storage conditions of the sample prior to DNA extraction',
                        registerNumber: 'register number',
                        enterRegisterNumber: 'Please enter the register number of the sample',
                        handlingMethod: 'handling method',
                        enterSampleProcessingMethod: 'Please enter sample processing method',
                    },
                    human: {
                        sampleCharacteristicInformation: 'sample characteristic information',
                        enterIdentification: 'Please enter an identification or description of the specific individual from whom the sample was obtained',
                        nation: 'nation',
                        enterNationality: 'Please enter nationality',
                        karyotype: 'karyotype',
                        enterChromosomesNumber: 'Please enter the number and appearance of chromosomes of the sample nucleus at mitosis, e.g., normal female 46, XX; Normal male 46, XY',
                        population: 'population',
                        enterAllOrganismsSum: 'Please enter the sum of all organisms living in a particular geographic area, the same group or species',
                        race: 'race',
                        moreRefersToRace: 'More refers to race, usually defined in fact is three, that is, white, yellow and black',
                        externalDatabase: 'external database',
                        enterExternalDatabaseName: 'Please enter an external database name',
                        externalDatabaseNumber: 'external database number',
                        enterSampleNumber: 'Place enter the number of the sample in the external database',
                        externalDatabaseURL: 'external database URL',
                        enterSampleURL: 'Place enter the URL of the sample in the external database',
                    },
                    humanGut: {
                        samplingTime: 'sampling time',
                        selectSamplingTime: 'Please select the sampling time',
                        environmentalBiome: 'environmental biome',
                        addDescriptiveTerms: 'Add descriptive terms for the living environment of the sample, such as adding multiple terms that can be separated by commas. For example, for human gut, fill in terms such as Digestive Trace Environment [ENVO: 01001033]',
                        environmentCharacteristic: 'environment characteristic',
                        addSmallEnvironmental: 'Add small environmental feature terms that have causal effects on the sample, such as adding multiple terms can be separated by commas',
                        environmentalMedium: 'environmental medium',
                        addEnvironmentalTerms: 'Add the terms of the environmental medium (substance) contained in the sample, such as adding multiple terms can be separated by commas',
                        host: 'host',
                        searchHost: 'Please search and select a host',
                        biologicalMaterialsReferenceSource: 'biological materials reference source',
                        enterBiomaterialSource: 'Please enter the source of the biomaterial reference article or the source of the genome report (PMID, Doi or URL)',
                        bacteriaAndOxygenRelationship: 'The relationship between bacteria and oxygen',
                        selectBacteriaAndOxygenRelationship: 'Please select the relationship between microbial survival and oxygen',
                        sampleCollectionDeviceOrMethod: 'sample collection device or method',
                        enterSampleCollectionMethodAndDevice: 'Please enter the sample collection method and device',
                        sampleTreatment: 'sample treatment',
                        enterSeparation: 'Please enter what was done to the sample during or after separation',
                        sampleVolume: 'sample volume',
                        enterSamplesCollectedNumberOrSize: 'Please enter the number or size of the samples collected (e.g. volume, mass or area)',
                        sourceMaterialIdentifier: 'source material identifier',
                        enterSampleUniqueIdentifier: 'Please enter a unique identifier for the sample material used for nucleic acid extraction and sequencing, which can be used to refer to the original biological material collected, as well as any derived subsamples',
                        chemicalApplication: 'chemical application',
                        chemicalsGivenList: 'The list of chemicals given to the host or sampling site (e.g., antibiotics, nitrogen fertilizers, air filters) and time, can contain a variety of compounds',
                        gastrointestinalDisease: 'gastrointestinal disease',
                        enterGastrointestinalDiseaseHistory: 'Please enter a history of gastrointestinal disease, which can include multiple diseases',
                        hostAge: 'host age',
                        enterHostAge: 'Please enter the age of the host',
                        hostInstitutionalIndex: 'host institutional index (BMI)',
                        enterHostBMIl: 'Please enter the host BMIl and calculate the formula = weight/height squared',
                        hostProduct: 'host product',
                        enterHostProduct: 'Please enter the host product from which the sample comes, such as stool, mucus, etc',
                        hostBodyTemperature: 'host body temperature',
                        enterHostTemperature: "Please enter the host's body temperature at the time of sampling",
                        hostDietaryType: 'host dietary type',
                        enterHostDietaryType: "Please enter the host's dietary type or diet. For example, Carnivorous, omnivore and so on are common in animals",
                        hostDisease: 'host disease',
                        searchHostDiseaseName: 'Please search and select a host disease name',
                        hostFamilyRelationship: 'host family relationship',
                        enterHostFamilyRelationship: "Please enter the host's family relationship",
                        hostGenotype: 'host genotype',
                        enterHostGenotype: 'Please enter the host genotype',
                        hostHeight: 'host height',
                        enterHostHeight: 'Please enter the host height',
                        hostLastMeal: "host's last meal",
                        enterHostLastMeal: "Please enter the host's last meal and time, which can contain multiple values",
                        hostOccupation: 'host occupation',
                        enterHostClass: 'Please enter the host class',
                        hostPhenotype: 'host phenotype',
                        enterHostPhenotype: 'Please enter the host phenotype',
                        hostPulse: 'host pulse',
                        enterRestingPulse: 'Please enter the resting pulse of the host and calculate the pulse per minute',
                        hostSex: 'host sex',
                        selectHostGender: 'Please select the gender of the host',
                        hostIdentificationNumber: 'host identification number',
                        enterUniqueHostIdentificationNumber: 'Please enter a unique host identification number',
                        hostSamplingTissue: 'host sampling tissue',
                        searchHostSampleTissueType: 'Search for and select the host sample tissue type, or whole body/whole plant if the sample was taken from an entire individual',
                        totalHostMass: 'total host mass',
                        enterTotalMass: 'Please enter the total mass of the host, the unit depends on the host',
                        drugCode: 'drug code',
                        multipleDrugCodes: 'multiple drug codes can be included',
                        hepaticDisease: 'hepatic disease',
                        enterLiverDiseaseHistory: 'Please enter a history of liver disease, which can include multiple diseases',
                        ifOtherCases: 'if there are other cases',
                        whetherCompleteMedical: 'Whether complete medical records have been collected',
                        otherParameters: 'other parameters',
                        enterAdditionalMeasurements: 'Please enter additional measurements or parameters',
                        totalOrganism: 'total organism',
                        enterOrganismsTotalNumber: 'Please enter the total number of organisms per gram or unit volume, including the name of the organism and the corresponding number. Multiple organisms can be included',
                        oxygenationState: 'oxygenation state',
                        selectOxygenationStatus: 'Please select the oxygenation status of the sample',
                        disturbanceType: 'disturbance type',
                        enterDisturbanceType: 'Please enter the disturbance type, such as chemical, physical disturbance, etc., and the time when the disturbance occurred. Multiple disturbance types can be included',
                        sampleSalinity: 'sample salinity',
                        enterSampleSalinity: 'Please enter sample salinity',
                        sampleStorageTime: 'sample storage time',
                        enterSampleStorageTime: 'Please enter sample storage time',
                        sampleStorageLocation: 'sample storage location',
                        enterSampleStorageLocation: 'Please enter the sample storage location',
                        sampleStorageTemperature: 'sample storage temperature',
                        enterSampleStorageTemperature: 'Please enter sample storage temperature',
                        specialDiet: 'special diet',
                        selectSpecialDiet: 'Please select the Special diet instructions, which can include a variety of special diets',
                        temperature: 'temperature',
                        enterLivingOrCulture: 'Please enter the living or culture ambient temperature of the sample at the time of sampling',
                        isolatedSource: 'isolated source',
                        describePhysical: 'Describe the physical, environmental, and/or geographic distribution of the biological sample',
                        userDefinedAttribute: 'user-defined attribute',
                        addNecessaryCustomProperties: 'Please add the necessary custom properties',
                    },
                    soil: {
                        depth: 'depth',
                        depthIsDefined: 'Depth is defined as the vertical distance below the surface. For example, for sediment or soil samples, depth is measured from the sediment or soil surface, respectively. For subsurface samples, depth can be expressed as spacing',
                        elevation: 'elevation',
                        samplingPointElevation: 'The elevation of the sampling point is measured from the vertical distance of the mean sea level',
                        agrochemicalAddition: 'agrochemical addition',
                        applyingFertilizers: 'Applying fertilizers, pesticides, etc., as well as the frequency and duration of these operations',
                        aluminumSaturation: 'aluminum saturation',
                        enterAluminumSaturation: 'Please enter aluminum saturation (especially for tropical soil)',
                        determiningAluminumSaturationMethod: 'method for determining aluminum saturation',
                        enterReferenceOrMethod: 'Please enter a reference or method for determining aluminum saturation',
                        altitude: 'altitude',
                        sampleHeight: 'The height of the sample is the vertical distance between the surface and the sampling site in the air',
                        annualQuarterlyPrecipitation: 'annual quarterly precipitation',
                        enterAverageAnnualSeasonalPrecipitation: 'Please enter the average annual seasonal precipitation(mm)',
                        seasonalTemperature: 'seasonal temperature',
                        enterAverageAnnualAeasonalTemperature: 'Please enter the average annual seasonal temperature',
                        cropRotation: 'crop rotation',
                        whetherCropsRotated: 'Whether crops are rotated, and if so, please indicate the rotation arrangement',
                        currentLandUse: 'current land use',
                        selectLandCondition: 'Please select the land condition of the current sampling site',
                        currentVegetation: 'current vegetation',
                        accordingStandardVegetation: 'According to the standard vegetation of the sampled area, or crops',
                        currentVegetationMethods: 'current vegetation methods',
                        enterVegetationClassifyingMethod: 'Please enter a reference or method for classifying vegetation',
                        drainageCategory: 'drainage category',
                        drainageAccordingClassification: 'Classification of drainage according to standard drainage systems (e.g. USDA system)',
                        extremeEvents: 'extreme events',
                        enterUnusualPhysical: 'Please enter unusual physical events that may affect microbial populations',
                        extremeSalinity: 'extreme salinity',
                        enterMeasuredSalinity: 'Please enter the measured salinity',
                        FAOClassification: 'FAO classification',
                        enterSoilFAOClassification: 'Please enter the soil FAO classification',
                        fire: 'fire',
                        enterHistoryPhysical: 'Please enter the history and/or physical evidence of the fire',
                        flood: 'flood',
                        enterHistoryPhysicalFlood: 'Please enter the history and/or physical evidence of the flood',
                        heavyMetal: 'heavy metal',
                        enterHeavyMetal: 'Please enter heavy metal and its concentration; The drugs used by the subjects and how often; Can contain a variety of heavy metals and their concentrations',
                        heavyMetalsDeterminationMethod: 'Method for determination of heavy metals',
                        aReferenceOrMethod: 'A reference or method used to determine heavy metals',
                        soilLayer: 'soil layer',
                        theTopLayer: 'The top layer of the soil can be measured parallel to the surface layer of the soil and has physical characteristics different from those of the upper and lower layers',
                        assayMethod: 'assay method',
                        aReferenceOrMethodForDetermining: 'A reference or method for determining soil layers',
                        sampleAttachedAnalysisLink: 'sample attached analysis link',
                        enterAReferenceLink: "Please enter a reference link to the sample's additional analysis results",
                        soilClassificationInformationLinks: 'soil classification information links',
                        enterARferenceLinkDigitalSoil: 'Please enter a reference link to a digital soil map or other soil classification information',
                        localSoilClassification: 'local soil classification',
                        enterASoilClassification: 'Please enter a soil classification based on your local soil classification system',
                        microbialBiomass: 'microbial biomass',
                        enterAPortionOrganicMatter: 'Please enter a portion of the organic matter in the soil that makes up microorganisms smaller than 5-10µm, which requires a correction factor to be converted into the final unit, i.e., the weight of C(or N) per kilogram of soil (mg).',
                        determinationMethods: 'methods for determination of microbial biomass',
                        determiningMicrobialBiomass: 'A reference or method for determining microbial biomass',
                        PHValue: 'PH value',
                        enterPHValue: 'Please enter PH value',
                        PHValueDeterminationMethod: 'method for determination of PH value',
                        enterPHMeasurementMethod: 'Please enter the PH measurement method',
                        mixedDNAExtraction: 'mixed DNA extraction',
                        howManymultipleDNAExtracts: 'Is it a mixture of multiple DNA extracts? How many are there?',
                        previousLandUse: 'previous land use',
                        determinePreviousLandUse: 'A reference or method used to determine the previous land use and date of use',
                        determiningLandUsePreviousMethods: 'previous methods of determining land use',
                        enterDisturbanceType: 'Please enter the disturbance type, such as chemical, physical disturbance, etc., and the time when the disturbance occurred. Multiple disturbance types can be included',
                        profilePosition: 'profile position',
                        selectProfileLocation: 'Please select the profile location of the sample hill, relative to the sample area location of the surrounding area',
                        determiningSalinityMethod: 'method of determining salinity',
                        determineSalinity: 'A reference or method used to determine salinity',
                        screen: 'screen',
                        enterCollectionDesign: 'Please enter the collection design and/or screening size of the mixed sample and the number of samples to be screened',
                        slope: 'slope',
                        slopeDirection: 'The direction of the slope. When looking down the slope, use a compass to record the direction (or Angle) the observer is facing, such as: NW or 315"',
                        agrotype: 'agrotype',
                        enterSoilSeriesName: 'Please enter a soil series name or another subcategory',
                        slopeGradient: 'slope gradient',
                        enterAngle: 'Please enter the Angle between the ground and the horizontal line (percentage)',
                        soilTypeDeterminationMethod: 'method for determination of soil type',
                        enterSoilTypeMethod: 'Please enter the reference or method used to determine the soil type',
                        holdingConditions: 'holding conditions',
                        enterSamplePriorStorage: 'Please enter the storage conditions of the sample prior to DNA extraction',
                        soilTexture: 'soil texture',
                        differentParticleSizesrelativeProportion: 'The relative proportion of different particle sizes of mineral particles in soil measured using a standard system',
                        soilTextureDeterminationMethods: 'methods for determination of soil texture',
                        enterReferenceOrMethod1: 'Please enter the reference or method used to determine soil texture',
                        farmingMethod: 'farming method',
                        selectFarmingMethod: 'Please select a farming method',
                        totalNitrogenContentDeterminationMethod: 'method for determination of total nitrogen content',
                        determiningTotalNitrogenContent: 'Please enter a reference or method for determining total nitrogen content',
                        totalNitrogenContent: 'total nitrogen content',
                        enterSampleTotalNitrogenContent: 'Please enter the total nitrogen content of the sample',
                        organicCarbonContentDeterminationMethod: 'method for determination of total organic carbon content',
                        enterOrganicCarbonContentDeterminingTotal: 'Please enter a reference or method for determining total organic carbon content',
                        organicCarbonTotalContent: 'total content of organic carbon',
                        enterTotalOrganicCarbonContent: 'Please enter the total organic carbon content in the soil, unit: g C/kg soil',
                        soilWaterContent: 'soil water content',
                        enterSoilWaterContent: 'Please enter soil water content (g/g or cm³/cm³)',
                        determiningSoilWaterContentMethods: 'methods for determining soil water content',
                        enterSoilMoistureDeterminationMethod: 'Please enter the soil moisture determination method',
                    },
                    microbe: {
                        authenticationMode: 'authentication mode',
                        enterTaxonomicFieldNamerInformation: 'Please enter the namer information for the taxonomic field',
                        laboratoryHost: 'laboratory host',
                        enterHostName: 'Please enter the name of the host used when testing in the laboratory, e.g. E. coli DH5a, HeLa cells',
                        copulativity: 'copulativity',
                        enterEukaryoticMicroOrganismMating: 'Please enter the mating type of eukaryotic microorganism',
                        generationHistory: 'generation history',
                        enterPassageMethod: 'Please enter the number of passage and passage method',
                        serotype: 'serotype',
                        serotypeIsASerologicalClassificationMethod: 'Serotype is a serological classification method of bacterial species, according to the specificity and physiological characteristics of bacterial antigen (H antigen) downward subspecies. Such as cy098518 influenza virus "H1N1" subtype',
                        serovariant: 'serovariant',
                        subspeciesTheFollowingCategories: 'Subspecies The following categories are commonly used in antigenically diverse bacteria, eueutheria, or viruses as species identifiers in bacterial phylogeny, such as Leptospira',
                    },
                    pathogenCl: {
                        hostDetails: 'host details',
                        enterHostAdditionalInformation: 'Please enter additional information about the host',
                        hostDiseaseOutcome: 'host disease outcome',
                        enterHostDiseaseResults: 'Please enter host disease results',
                        hostDiseaseStaging: 'staging of host disease',
                        enterSamplingPeriodDiseaseStage: 'Please enter the sampling period disease stage',
                        hostHealthStatus: 'host health status',
                        enterHostHealth: 'Please enter host health',
                        lesionType: 'lesion type',
                        enterDiseaseTypeClassificationInformation: 'Please enter the disease type classification information',
                        subset: 'subset',
                        subspeciesFollowingClassification: 'Subspecies The following classification, commonly used for viruses, represents a subgroup obtained from a single isolate',
                        subtype: 'subtype',
                        forVirusClassification: 'For virus classification, such as HIV type1',
                    },
                    plant: {
                        cultivar: 'cultivar',
                        enterSampleCultivarName: 'Please enter the cultivar name of the sample',
                        culturalMethod: 'cultural method',
                        enterSampleCultureMethod: 'Please enter the culture method of the sample',
                        heightOrlength: 'height/length',
                        enterSamplePlantHeightOrWidth: 'Please enter the plant height or width of the sample',
                    },
                    water: {
                        basicity: 'basicity',
                        alkalinityRefersNeutralizeAbility: 'Alkalinity refers to the ability to neutralize an acid into a carbon salt or bicarbonate',
                        alkylDiether: 'alkyl diether',
                        enterAlkylDietherConcentration: 'Please enter the concentration of alkyl diether',
                        aminopeptidaseActivity: 'aminopeptidase activity',
                        enterAminopeptidaseActivityValue: 'Please enter the aminopeptidase activity value',
                        ammoniumConcentration: 'ammonium concentration',
                        enterAmmoniumConcentration: 'Please enter the ammonium concentration',
                        atmosphereData: 'atmosphere data',
                        enterAtmosphericDataMeasurements: 'Please enter atmospheric data measurements, which can contain multiple data',
                        bacterialProduction: 'bacterial production',
                        enterBacteriaProduction: 'Please enter the production of bacteria in the water column as measured by isotope absorption method',
                        bacterialRespirationValue: 'bacterial respiration value',
                        enterMeasurement: 'Please enter the measurement of bacterial respiration in the water column',
                        bacterialCarbonProduction: 'bacterial carbon production',
                        enterBacterialCarbonProductionMeasurement: 'Please enter a measurement of bacterial carbon production',
                        biomass: 'biomass',
                        enterBiomass: 'Please enter the biomass, the record should include the name of the organism being measured, e.g., microorganism, biomass. Multiple measurements can be included',
                        literHolaneAlcoholConcentration: '2-liter holane-alcohol concentration',
                        enterLiterHolaneAlcoholConcentratio: 'Please enter the 2-litre holane-alcohol concentration',
                        bromideConcentration: 'bromide concentration',
                        enterBromideConcentration: 'Please enter the bromide concentration',
                        calciumConcentration: 'calcium concentration',
                        enterCalciumConcentration: 'Please enter calcium concentration',
                        carbonNitrogenRatio: 'carbon nitrogen ratio',
                        enterCarbonAndNitrogenQuantityRatio: 'Please enter the quantity or concentration ratio of carbon and nitrogen',
                        chlorideConcentration: 'chloride concentration',
                        enterChlorideConcentration: 'Please enter chloride concentration',
                        chlorophyllConcentration: 'chlorophyll concentration',
                        enterChlorophyllConcentration: 'Please enter chlorophyll concentration',
                        specificConductance: 'specific conductance',
                        enterWaterConductivity: 'Please enter the conductivity of the water',
                        density: 'density',
                        enterSampleDensity: 'Please enter the density of the sample',
                        dietherLipidConcentration: 'diether lipid concentration',
                        enterDietherLipidConcentration: 'Please enter the diether lipid concentration. Multiple diether lipids can be included',
                        dissolvedCarbonDioxideConcentration: 'dissolved carbon dioxide concentration',
                        enterDissolvedCarbonDioxideConcentration: 'Please enter dissolved carbon dioxide concentration',
                        dissolvedHydrogenConcentration: 'dissolved hydrogen concentration',
                        enterDissolvedHydrogenConcentration: 'Please enter dissolved hydrogen concentration',
                        dissolvedInorganicCarbonConcentration: 'dissolved inorganic carbon concentration',
                        enterDissolvedInorganicCarbonConcentration: 'Please enter dissolved inorganic carbon concentration',
                        dissolvedInorganicNitrogenConcentration: 'dissolved inorganic nitrogen concentration',
                        enterDissolvedInorganicNitrogenConcentration: 'Please enter dissolved inorganic nitrogen concentration',
                        dissolvedInorganicPhosphorusConcentration: 'dissolved inorganic phosphorus concentration',
                        enterDissolvedInorganicPhosphorusConcentration: 'Please enter dissolved inorganic phosphorus concentration',
                        dissolvedOrganicCarbonConcentration: 'dissolved organic carbon concentration',
                        enterDissolvedOrganicCarbonConcentration: 'Please enter dissolved organic carbon concentration',
                        dissolvedOrganicNitrogenConcentration: 'dissolved organic nitrogen concentration',
                        enterDissolvedOrganicNitrogenConcentration: 'Please enter dissolved organic nitrogen concentration',
                        dissolvedOxygenConcentration: 'dissolved oxygen concentration',
                        enterDissolvedOxygenConcentration: 'Please enter dissolved oxygen concentration',
                        downPAR: 'down PAR',
                        enterVisibleBandRadiation: 'Please enter visible band radiation and irradiance measurements in the water column',
                        fluorescence: 'fluorescence',
                        enterOriginalConverted: 'Please enter the original or converted fluorescence of the water',
                        glucosidaseActivity: 'glucosidase activity',
                        enteGlucoheadEnzymeActivityValue: 'Please enter the glucohead enzyme activity value',
                        lightIntensity: 'light intensity',
                        enterLightIntensity: 'Please enter the light intensity',
                        magnesiumConcentration: 'magnesium concentration',
                        enterMagnesiumConcentration: 'Please enter magnesium concentration',
                        meanFrictionVelocity: 'mean friction velocity',
                        enterAverageFrictionSpeed: 'Please enter the average friction speed',
                        averagePeakFrictionVelocity: 'average peak friction velocity',
                        enterAveragePeakFrictionVelocity: 'Please enter the average peak friction velocity',
                        normalAlkaneConcentration: 'normal alkane concentration',
                        enterNalkaneConcentration: 'Please enter the n-alkane concentration, which can include multiple n-alkanes and their concentration values',
                        nitrateConcentration: 'nitrate concentration',
                        enterNitrateConcentration: 'Please enter nitrate concentration',
                        nitriteConcentration: 'nitrite concentration',
                        enterNitriteConcentration: 'Please enter nitrite concentration',
                        nitrogenConcentration: 'nitrogen concentration',
                        enterNitrogenConcentration: 'Please enter the nitrogen concentration',
                        organicCarbonConcentration: 'organic carbon concentration',
                        enterOrganicCarbonConcentration: 'Please enter the organic carbon concentration',
                        organicMatterConcentration: 'concentration of organic matter',
                        enterOrganicConcentration: 'Please enter the organic concentration',
                        organicNitrogenConcentration: 'organic nitrogen concentration',
                        enterOrganicNitrogenConcentration: 'Please enter the organic nitrogen concentration',
                        particulateOrganicCarbonConcentration: 'particulate organic carbon concentration',
                        enterParticulateOrganicCarbonConcentration: 'Please enter particulate organic carbon concentration',
                        particleOrganicNitrogenConcentration: 'particle organic nitrogen concentration',
                        enterParticleOrganicNitrogenConcentration: 'Please enter particle organic nitrogen concentration',
                        petroleumHydrocarbonConcentration: 'petroleum hydrocarbon concentration',
                        enterPetroleumHydrocarbonConcentration: 'Please enter petroleum hydrocarbon concentration',
                        magnesiumChlorophyllinAcidConcentration: 'magnesium chlorophyllin acid concentration',
                        enterMagnesiumChlorophyllinAcidConcentration: 'Please enter the magnesium chlorophyllin concentration, which can contain multiple magnesium chlorophyllin acids and their concentrations',
                        phosphateConcentration: 'phosphate concentration',
                        enterPhosphateConcentration: 'Please enter the phosphate concentration',
                        phospholipidFattyAcidConcentration: 'phospholipid fatty acid concentration',
                        enterPhospholipidFattyAcidConcentration: 'Please enter the phospholipid fatty acid concentration, which can contain multiple phospholipid fatty acid concentrations and their concentrations',
                        photonFlux: 'photon flux',
                        enterPhotonFluxValue: 'Please enter the photon flux value',
                        potassiumConcentration: 'potassium concentration',
                        enterPotassiumConcentration: 'Please enter the potassium concentration',
                        pressure: 'pressure',
                        enterPressure: 'Please enter the atmospheric pressure of the sample',
                        primaryProduct: 'primary product',
                        enterPrimaryProductMeasurement: 'Please enter the primary product measurement',
                        redoxPotential: 'redox potential',
                        indicatingOxidationOrReduction: 'REDOX potential, relative to gas cell measurement, indicating oxidation or reduction potential',
                        nitrateConcentration1: 'nitrate concentration',
                        enterNitrateConcentration1: 'Please enter silicate concentration',
                        sodiumConcentration: 'sodium concentration',
                        enterSodiumConcentration: 'Please enter the sodium concentration',
                        solubleActivePhosphorusConcentration: 'soluble active phosphorus concentration',
                        enterSolubleActivePhosphorusConcentration: 'Please enter soluble active phosphorus concentration',
                        sulfateConcentration: 'sulfate concentration',
                        enterSulfateConcentration: 'Please enter the sulfate concentration',
                        sulfideConcentration: 'sulfide concentration',
                        enterSulfideConcentration: 'Please enter the sulfide concentration',
                        suspendedParticulateConcentration: 'suspended particulate concentration',
                        enterSuspendedParticulateConcentration: 'Please enter the concentration of suspended particles',
                        tidalStage: 'tidal stage',
                        selectTidalStage: 'Please select the tidal phase',
                        waterColumnTotalDepth: 'total depth of water column',
                        enterWaterColumnTotalDepth: 'Please enter the total depth measurement of the water column',
                        totalDissolvedAmmoniaConcentration: 'total dissolved ammonia concentration',
                        enterTotalDissolvedAmmoniaConcentration: 'Please enter the total dissolved ammonia concentration, calculated as: NH4 concentration +NONO2 concentration + dissolved organic ammonia concentration',
                        totalInorganicNitrogenContent: 'total inorganic nitrogen content',
                        enterTotalInorganicNitrogenContent: 'Please enter total inorganic nitrogen content',
                        totalParticleCarbonContent: 'total particle carbon content',
                        enterTotalParticleCarbonContent: 'Please enter total particle carbon content',
                        totalPhosphorusConcentration: 'total phosphorus concentration',
                        enterTotalPhosphorusConcentration: 'Please enter the total phosphorus concentration. The formula is: Total phosphorus concentration = total dissolved phosphorus concentration + particulate phosphorus concentration',
                        waterDischarge: 'water discharge',
                        enterWaterDischarge: 'Please enter the size and direction of the water flow',
                        DNAExtractionSampleSize: 'sample size for DNA extraction',
                        enterDNAExtractionSampleSize: 'Please enter the volume (mL) or weight (g) of the sample for DNA extraction.',
                    },
                },
            },
            Compound: {
                Compound: {
                    Title: 'Upload specific data',
                    common: {
                        Add: 'Add',
                        Submit: 'Submit',
                        Cancel: 'Cancel',
                        Delete: 'Delete',
                        Edit: 'Edit',
                        EditSubmit: 'Edit Submit'
                    },
                    UploadType: {
                        Title: 'Upload type',
                        NewCom: 'Upload new compounds',
                        SuppExCom: 'Supplement data on existing compounds',
                        CorrectExCom: 'Correct information about existing compounds',
                    },
                    NewCom: {
                        Form: {
                            common: {
                                Add: 'Add',
                                Submit: 'Submit',
                                Cancel: 'Cancel',
                                Delete: 'Delete',
                                Edit: 'Edit'
                            },
                            NameAndIdentifier: {
                                Title: '1. Name and identifier',
                                ComputationDescriptor: '1.1 Computation descriptor',
                                CharacterType: 'Character type:',
                                Value: 'Value:',
                                Name: '1.2 Name',
                                MolecularFormula: '1.3 Molecular formula',
                                OtherIdentifiers: '1.4 Other identifiers',
                                SynonymsOfMedicalSubjectWords: '1.5 Synonyms of medical subject words',
                                Tips: {
                                    CTTip: 'Please select a character type',
                                    CVTip: 'Please enter the value of the identifier',
                                    NTip: 'Please enter the name of the compound',
                                    MFTip: 'Please enter the molecular formula',
                                    OITTip: 'Select the character type',
                                    OIVTip: 'Single line input',
                                    SOMSWTip: 'Please enter synonyms of medical subject words',
                                }
                            },
                            ChemicalClassification: {
                                Title: '2. Chemical classification',
                                ClassificationTree: '2.1 Classification Tree',
                                TreeNode: 'Tree nodes:',
                                Value: 'Value:',
                                Tips: {
                                    TNTip: 'Please enter the tree node',
                                    TNVTip: 'Please enter the identifier of the Mesh record',
                                }
                            },
                            MedicinesAndDrugInformation: {
                                Title: '3. Medicines and drug information',
                                IndicationsForDrugUse: '3.1 Indications for Drug Use',
                                ClassificationOfDrugs: '3.2 Classification of drugs',
                                TherapeuticUses: '3.3 Therapeutic Uses',
                                Tips: {
                                    IFDUTip: 'Please enter the indication for the drug',
                                    CODTip: 'Please enter a medication category',
                                    TUTip: 'Please enter therapeutic use',
                                },
                            },
                            PharmacologyAndBiochemistry: {
                                Title: '4. Pharmacology and biochemistry',
                                Pharmacodynamics: '4.1 Pharmacodynamics',
                                AbsorptionDigestionAndExcretion: '4.2 Absorption, digestion and excretion',
                                DescriptionOfAbsorption: 'Absorption description:',
                                DescriptionOfDigestion: 'Description of digestion:',
                                DescriptionOfExcretion: 'Description of excretion:',
                                MetabolismMetabolites: '4.3 Metabolism/metabolites',
                                MechanismOfAction: '4.4 Mechanism of action',
                                Tips: {
                                    PTip: 'Please enter pharmacodynamic information',
                                    DOATip: 'Please enter the absorption description',
                                    DODTip: 'Please enter the digestion description',
                                    DOETip: 'Please enter a excretion description',
                                    MMTip: 'Please enter metabolism/metabolite information',
                                    MOATip: 'Please enter the mechanism information',
                                }
                            },
                            Toxicity: {
                                Title: '5. Toxicity',
                                ToxicitySummary: '5.1 Summary Of Toxicity',
                                RouteOfExposure: '5.2 Route of exposure',
                                Symptoms: '5.3 Symptoms',
                                DetoxificationAndEmergencyTreatment: '5.4 Detoxification and emergency treatment',
                                Tips: {
                                    TSTip: 'Please enter a toxicity summary',
                                    ROETip: 'Please enter the contact route',
                                    STip: 'Please enter symptoms',
                                    DAETTip: 'Please enter detoxification and emergency treatment information',
                                }
                            },
                            OtherCompoundRelatedInfo: {
                                Title: '6. Other compound related information',
                                RelatedItemName: 'Related item name:',
                                RelatedItems: 'Related items:',
                                RITitle: 'Title:',
                                RIContent: 'Content:',
                                Tips: {
                                    RITTip: 'Please enter the title of the relevant information',
                                    RICTip: 'Please enter the details'
                                }
                            },
                            References: {
                                Title: '7. References',
                                ArticleTitle: 'Article Title:',
                                ArticleAuthor: 'Article Author:',
                                Abstract: 'Abstract:',
                                Periodicals: 'Journals:',
                                PeriodicalsName: 'Journal Name:',
                                Tips: {
                                    ATTip: 'Please enter the title of the relevant information',
                                    AATip: 'Please enter the authors of the references separated by commas',
                                    PNTip: 'Please enter the name of the journal',
                                    AbTip: 'Please enter the details'
                                }
                            },
                            DataFile: {
                                Title: '8. Data file',
                                Tip: 'Supported formats: JSON、XML、CSV、DOCX、XLSX、PDF、CAJ'
                            },
                            Options: {
                                ComputationDescriptorOption: {
                                    IUPACName: 'IUPAC Name',
                                    InChI: 'InChI',
                                    InChIKey: 'InChIKey',
                                    SMILES: 'SMILES'
                                },
                                OtherIdentifiersOption: {
                                    CAS: 'CAS',
                                    ECCode: 'European Community (EC) number',
                                    UNCode: 'United Nations (UN) number',
                                    UNICode: 'Unique Identification(UNI)',
                                    Wiki: 'Wikipedia',
                                    WikiData: 'Wikipedia data',
                                    NCICode: 'NCI thesaurus code',
                                    RXCode: 'RX unique identifier'
                                },
                            }
                        },
                    },
                    SuppAndCorrectExCom: {
                        ReferencesDIO: 'Reference DOI',
                        SupplementaryContent: 'supplementary material',
                        SupplementaryItem: 'addition item',
                        SupplementaryItemName: 'Supplementary item name:',
                        SupplementaryItemContent: 'Supplementary items:',
                        ContentOfCorrection: 'Correction:',
                        TermOfCorrection: 'Correction item:',
                        TermOfCorrectionName: 'Correct item name:',
                        TermOfCorrectionContent: 'Correction item:',
                        Tips: {
                            RDIOTip: 'Please enter the reference DOI',
                            YYCIDTip: 'Please enter YYC ID',
                            SITTip: 'Please enter a supplementary item',
                            SICTip: 'Please enter supplementary content',
                            DelTip: 'Delete item or not',
                            TOCTip: 'Please enter corrections',
                            COCTip: 'Please enter corrections',
                        },
                        DataFile: {
                            Title: 'data file',
                            Tip: 'Support format: JSON、XML、CSV、DOCX、XLSX、PDF、CAJ',

                        },
                    }
                },
            },
            Protein: {
                HeadTitle: 'Upload specific data',
                Functions: 'Method:',
                UploadForm: 'Fill in the data',
                UploadFile: 'Upload file',
                Tips: 'Description: New protein data is assigned a new project identifier',
                Tips2: 'Description: You can directly upload files containing data information, xlsx/xls files',
                DownloadFile: 'Download template',
                NextStep: 'Next',
                Save: 'Save',
                Submit: 'Submit',
                BaseInfo: {
                    ProteinName: 'Protein name',
                    SpeciesSource: 'Source of species',
                    SequenceLength: 'Length of sequence',
                    MolMassOfSequence: 'Molecular mass of sequence(Da)',
                    SerialInformation: 'Sequence information:',
                    IncludedDataBase: 'Whether it is included in an external database',
                    DataBaseNum: 'External database project number',
                    ProteinFunc: 'Protein function Introduction:',
                    HomologyData: 'Data on homology:',
                    ProteinInteraction: 'Data from protein interaction experiments:',
                    TargetDrugBioassay: 'Target-drug bioactivity assay experimental data:',
                    PosttranslationalModification: 'Posttranslational modification data:',
                    InvolvementInDisease: 'Participation in disease data:',
                    Protein3DStructural: 'Protein 3D structural data:',
                    SubcellularLocalization: 'Subcellular localization data:',
                    Domain: 'Domain data:',
                    Other: 'Additional Information:',
                    Tips: {
                        PNTip: 'Please enter a protein name',
                        SSTip: 'Please enter species source',
                        SLTip: 'Please enter the sequence length',
                        MMOSTip: 'Please enter the molecular mass of the sequence(Da)',
                        SITip: 'Please enter the sequence information',
                        IDBTip: 'Null',
                        DBNTip: 'Please enter or search for products',
                        PFTip: 'Please enter protein function description',
                        HDTip: 'Please enter the isomer data',
                        PITip: 'Please enter the protein interaction experiment data',
                        TDBTip: 'Please enter target-drug bioactivity assay laboratory data',
                        PMTip: 'Please enter the post-translation modification data',
                        IIDTip: 'Please enter participating disease data',
                        P3STip: 'Support upload formats: pdb, cif',
                        SubLTip: 'Please enter subcellular localization data',
                        DTip: 'Please enter the domain data',
                        OTip: 'Additional Information',
                        FilesErr: 'Incorrect file format!',
                        IDBTip2: 'Please select'
                    },
                },
                HumanDisease: {
                    Tip: 'Description: New human disease data are assigned a new item identifier',
                    DiseaseName: 'Human Disease name:',
                    Abbreviations: 'Abbreviations:',
                    AliasName: 'Alias name:',
                    Definition: 'Definition:',
                    RelatedProtein: 'Related proteins:',
                    Tips: {
                        DNTip: 'Please enter a human disease name',
                        ATip: 'Please enter abbreviations',
                        ANTip: 'Please enter the human disease alias',
                        DTip: 'Please enter a definition of the human disease',
                        RPTip: 'Please search for proteins related to the human disease (search by keyword, yyhomePID)',
                    }
                },
                Articel: {
                    Tip: 'Description: New amino acid sequences associated with the article data will be assigned a new item identifier.',
                    ArticleTitle: 'Article Title:',
                    Author: 'Author:',
                    Journal: 'Journal:',
                    Abstract: 'Abstract:',
                    Introduce: 'Introduction:',
                    Result: 'Results:',
                    Discussion: 'Discussion:',
                    Thanks: 'Thanks:',
                    Quote: 'Quote:',
                    Conclusion: 'Conclusion:',
                    ConflictOfInterest: 'Conflict of interest:',
                    UploadForm: 'Upload form:',
                    UploadImg: 'Upload picture:',
                    SuppMaterials: 'Supplementary Material:',
                    FullArticle: 'Full article:',
                    ReferenceDataInfo: 'What data information is covered in this article:',
                    Tips: {
                        ATTip: 'Please enter the title of the article',
                        ATip: 'Please enter the author of the article, separated by commas',
                        JTip: 'Please enter the article journal',
                        AbTip: 'Please enter the abstract of the article',
                        ITip: 'Please enter the description of the article',
                        RTip: 'Please enter the results of this article',
                        DTip: 'Please enter the discussion of this article',
                        TTip: 'Please enter a thanks for this article',
                        QTip: 'Please enter a quote for the article',
                        CTip: 'Please enter the conclusion of the article',
                        CITip: 'Please enter the conflict of interest for this article',
                        UFTip: 'Support upload formats: docx, xlsx, xls, csv, pdf',
                        UITip: 'Support upload formats: tif, jpg, pdf, eps',
                        SMTip: 'Support upload formats: docx, xlsx, xls, csv, pdf, tif, jpg, eps',
                        FATip: 'Support upload formats: docx, csv, pdf',
                        RDIOptions: {
                            ProteinName: 'Protein name',
                            AminoAcidSeq: 'Amino acid sequence',
                            Function: 'Function',
                            Homeomer: 'Homeoisomer',
                            ProteinInteraction: 'Protein interaction',
                            TargetDrugBioassay: 'Target-drug bioactivity assay',
                            PostTranslationalModification: 'Post translational modification',
                            Disease: 'Disease',
                            Protein3D: 'Protein 3D structural data',
                            SubcellularLocalization: 'Subcellular localization',
                            Domain: 'Structural domain'
                        }
                    }
                }
            },
            Nature: {
                metabonomics: {
                    uploadFile: 'Upload File',
                    Downloadtemplate: 'Download Template',
                    projectdescription: 'Project Description',
                    projecttitle: 'Project Title',
                    projectbrief: 'Project Summary',
                    linkman: 'Linkman',
                    addusers: 'Add Users',
                    keyword: 'Keyword',
                    addkeywords: 'Add Keywords',
                    isorno: 'Whether there are published public publications',
                    y: "Yes, it's public",
                    y2: "Yes, in preparation",
                    n: 'Not have',
                    inputDOI: 'Input DOI or PMD：',
                    title: 'Title：',
                    summarize: 'Summarize：',
                    sample: 'Sample/analysis details',
                    name: 'Name',
                    email: 'Email',
                    phone1: 'Phone',
                    phone2: 'Phone2',
                    address: 'Address',
                    user: 'User',
                    nameholder: 'Please enter your name',
                    emailholder: 'Please fill in the correct email address',
                    userholder: 'Please enter the user role of the contact',
                    Analysislist: 'Analysis list:',
                    Newanalysis: 'New Analysis',
                    edit: 'Edit',
                    delete: 'Delete',
                    projecttitleP: 'Please enter the project title',
                    projectbriefP: 'Please enter a project summary',
                    linkmanP: 'Please add a contact',
                    keywordP: 'Please add a keyword',
                    poiP: 'Please input DOI or PMD',
                    titleP: 'Please enter the title',
                    summarizeP: 'Please enter a summary',
                    sampleP: 'Please add at least one experiment',
                    fileP: 'Currently, only.xlsx is supported for uploaded files',
                    lookdetail: 'View Details'
                },
                bioassay: {
                    baseInfo: 'Basic Information',
                    bioName: 'Bioassay Name:',
                    bioNameP: 'Please enter a biometric name',
                    targetName: 'Target name:',
                    targetNameP: 'Please enter the target name',
                    targetType: 'Target type:',
                    targetTypeP: 'Please enter the target type',
                    species: 'Experimental species:',
                    speciesP: 'Please enter experimental species',
                    test: 'Test compound count',
                    act: 'Number of active:',
                    actP: 'Please enter the active number',
                    notAct: 'Number of inactive:',
                    notActP: 'Please enter the number of inactive',
                    dataSource: 'Data Sources',
                    JournalName: 'Journal Name:',
                    JournalNameP: 'Please enter the journal name',
                    doiP: 'Please enter DOI',
                    chembl: 'ChEMBL target Name:',
                    chemblP: 'Please enter the ChEMBL target name',
                    relationshipType: 'Relationship Type:',
                    relationshipTypeP: 'Please enter the relationship type',
                    year: 'Year：',
                    yearP: 'Please enter the year',
                    abstract: 'Abstract：',
                    abstractP: 'Please enter abstract',
                    result: 'Experimental Result',
                    resultP: 'Please add at least one experiment information',
                    ExpDataList: 'Experimental data list:',
                    addExpData: 'New experimental data',
                    data: 'Data',
                    tag: 'Tags (e.g. lndnG150)',
                    tagP: 'Please enter a tag (e.g. lndnG150)',
                    value: 'value',
                    valueP: "Please enter value",
                    enterValueForReactionCondition: 'Please enter a value for the reaction condition',
                    reactionProcess: 'reaction process',
                    enterReactionProcess: 'Please enter the reaction process',
                    references: 'references',
                    patent: 'patent',
                    articleTitle: 'article title',
                    enterTheArticleTitle: 'Please enter the title of the article',
                    articleAuthor: 'article author',
                    enterTheArticleAuthor: 'Please enter the author of the article',
                    periodical: 'periodical',
                    dataFile: 'Data file',
                    uploadFiles: 'Upload files',
                    supportFormat: 'Support format',
                    enterReferenceDOI: 'Please enter the reference DOI',
                    enterPatentNumber: 'If the data is from a patent, enter the patent number',
                    journalTitle: 'journal title',
                    enterJournalName: 'Please enter the journal name',
                    // abstract: 'abstract',
                    enterAbstract: 'Please enter abstract',
                    delete: 'delete',
                    cancel: 'Cancel',
                    submit: 'Submit',
                    nextStep: 'Next step',
                },
            },
        },



        // 详情页
        Details: {
            Header: {
                HeadTitle: "Upload - Project Details",
                Identifier: 'Identifier',
                Type: 'Upload Project Category',
                Status: 'State',
                UploadDate: 'Upload Time',
                Controls: 'Controls',
                baseinfo: 'Basic project information'
            },
            common: {
                Title: 'Basic Project Information',
                EditBtn: 'Edit',
                DelBtn: 'Delete',
                Message: {
                    DelSuccess: 'Deleted successfully!',
                    DelDefult: 'Delete failed!',
                    DelErr: 'The server has strayed, please try again later!',
                    DelText: 'Do you want to delete?',
                    Comfirm: 'Comfirm',
                    Cancel: 'Cancel',
                },
            }
        },
        // 编辑页
        Edit: {
            Header: {
                HeadTitle: 'Upload - Edit Project',
                CardTitle: 'Edit Data'
            }
        },
        Step: {
            newProject: 'New Project',
            upload_newProject: 'Upload-New Project',
            enterUserInfo: 'Enter Submitter Information',
            select: 'Select the upload data type',
            uploadData: 'Upload specific data',
            pands: 'Preview & Submit',
            note1: 'The following steps will guide you through uploading your project. After submitting, you can view and edit your project via the yyHome URL',
            cancel: 'Cancel',
            create: 'Create',
            next: 'Next'
        },
        submitInfo: {
            submitInfo: 'Submitter Information',
            name: 'Name',
            namePlaceholder: 'Please enter your name',
            email: 'Email',
            emailPlaceholder: 'Please enter email',
            SecondaryEmail: 'Secondary Email',
            SecondaryEmailPlaceholder: 'Please enter secondary email',
            organization: 'Name of organization/business/academic institution/non-profit organization',
            organizationPlaceholder: 'Please enter the name of your organization/business/academic institution/non-profit organization',
            organizationURL: 'Organization/Enterprise/Academic institution/non-profit organization website',
            organizationURLPlaceholder: 'Please enter the website of your organization/enterprise/Academic institution/non-profit organization',
            department: 'Department',
            departmentPlaceholder: 'Please enter department',
            tel: 'Cellphone',
            telPlaceholder: 'Please enter your phone number',
            fax: 'Fax',
            faxPlaceholder: 'Please enter fax',
            state: 'State',
            china: 'China',
            Provinces: 'Provinces/autonomous regions/municipalities/special administrative regions',
            ProvincesPlaceholder: 'Please enter Province/Autonomous region/Municipality/Special Administrative Region',
            postcode: 'postcode',
            postcodePlaceholder: 'Please enter postal code'
        },
        // 上传-预览
        Preview: {
            Title: 'Preview & Submit',
            SubmiterInfo: 'Submitter information',
            DataTypeInfo: 'Data type information',
            DataInfo: 'Specific data information',
            UploadDataBase: 'Upload database',
            UploadBaseInfo: 'Upload specific data',
            Submiter: {
                Name: 'Name',
                Phone: 'Phone',
                Email: 'Email',
                Address: 'Address',
                Unit: 'Unit',
                Postcode: 'Postcode',
                Department: 'Department',
            }
        },
        Select: {
            selectDatabase: 'Select database',
            selectThing: 'Select upload material',
            DataBase: {
                Nature: 'Natural Product Library',
                Protein: 'Library of proteins',
                Compound: 'Library of compounds',
                Gene: 'Gene pool'
            },
            BaseMaterial: {
                Metabolomics: 'Metabolomics',
                Bioassay: 'Biological assay',
                ProteinBaseInfo: 'Basic protein information',
                HumanDisease: 'Human disease',
                AminoAcidSequenceRelatedArticles: 'Amino acid sequence related articles',
                Compound: 'Chemical compound',
                ChemicalReactions: 'Chemical reactions',
                BiologicalProject: 'Biological project',
                BiologicalSample: 'Biological sample'
            }
        },
    },



}