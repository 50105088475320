import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  accountInformation: '账户信息',
  profilePhoto: '头像',
  modifyProfilePicture: '修改头像',
  nickname: '昵称',
  changeNickname: '修改昵称',
  eMail: '邮箱',
  bindEmailAddress: '绑定邮箱',
  cellPhoneNumber: '手机号',
  bindMobilePhone: '绑定手机',
  password: '密码',
  changePassword: '修改密码',
  FetchCode: ' 获取验证码',
  Submit: '提交',
  Cancel: '取消',
}
const zhParagraphInfo = {
  tip01: '请输入新的昵称',
  tip02: '昵称不能为空',
  tip03: '中英文均可，最长14个英文或7个汉字',
  tip04: '请输入正确的昵称',
  tip05: '邮箱不能为空',
  tip06: '请输入正确的邮箱',
  tip07: '请输入新的邮箱',
  tip08: '手机号不能为空',
  tip09: '请输入正确的手机号',
  tip10: '请输入新的手机号',
  tip11: '密码必须同时包含大写字母，小写字母，特殊字符，且长度在8~30位之间',
  tip12: '请输入新的密码',
  tip13: '密码不能为空',
  tip14: '请输入符合格式的密码',
  tip15: '请输入合法的验证码',
  tip16: '验证码不能为空',
  tip17: '请输入旧的密码',
  tip18: '两次输入的密码不一样，请重新输入',
  tip19: '请确认密码',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(
  zhParagraphInfo,
  true
)
enParagraphInfo.tip01 = 'Please enter a new nickname'
enParagraphInfo.tip02 = 'Nickname cannot be empty'
enParagraphInfo.tip03 =
  'Can be written in English or Chinese, and the length is limited to 14 English or 7 Chinese characters'
enParagraphInfo.tip04 = 'Please enter a correct nickname'
enParagraphInfo.tip05 = 'Email cannot be empty'
enParagraphInfo.tip06 = 'Please enter a correct email'
enParagraphInfo.tip07 = 'Please enter a new email'
enParagraphInfo.tip08 = 'Phone number cannot be empty'
enParagraphInfo.tip09 = 'Please enter a correct phone number'
enParagraphInfo.tip10 = 'Please enter your new phone number'
enParagraphInfo.tip11 =
  'The password must contain 8 to 30 characters, including uppercase letters, lowercase letters, and special characters'
enParagraphInfo.tip12 = 'Please enter your new password'
enParagraphInfo.tip13 = 'Password cannot be empty'
enParagraphInfo.tip14 = 'Please enter a valid password'
enParagraphInfo.tip15 = 'Please enter a valid verification code'
enParagraphInfo.tip16 = 'The verification code cannot be empty'
enParagraphInfo.tip17 = 'Please enter your old password'
enParagraphInfo.tip18 = 'The two passwords are different. Please re-enter them'
enParagraphInfo.tip19 = 'Please enter your new password'
// 导出
export const zhPersonal = { ...zhBaseInfo, ...zhParagraphInfo }
export const enPersonal = { ...enBaseInfo, ...enParagraphInfo }
