import { RouteRecordRaw } from 'vue-router'
import RouteNames from '@/consts/RouteNames'
// 理化性质页面
const PPIndex = () => import('@/views/Gene/physicochemical-property/index.vue')
const PPForm = () => import('@/views/Gene/physicochemical-property/view/pp-form/index.vue')
const PPResult = () => import('@/views/Gene/physicochemical-property/view/pp-result/index.vue')

export default [
  // 理化性质页面
  {
    path: 'PhysicochemicalPropertyAnalyze',
    component: PPIndex,
    name: RouteNames.Protein.PhysicochemicalPropertyAnalyze,
    meta: {
      marginBottom: true,
    },
    redirect: '/protein/PhysicochemicalPropertyAnalyze/ppForm',
    children: [
      {
        path: 'ppForm',
        name: 'PPGeneForm',
        component: PPForm,
      },
      {
        path: 'ppResult',
        name: 'PPGeneResult',
        meta: { showWaitHeader: true },
        component: PPResult,
      },
    ],
  },
] as RouteRecordRaw[]
