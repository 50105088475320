// 全局的事件触发器
import globalMitt from 'mitt'
import { saveAs } from 'file-saver'
class CommonUtil {
  // 全局事件总线
  static mitt = globalMitt()
  // 模拟异步
  static sleep(time: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, time)
    })
  }
  // 判断是不是空对象
  static isEmptyObject(obj: object) {
    return Object.keys(obj).length === 0
  }
  // 下载一个url地址的文件
  static downloadUrlFile(url: string) {
    const fileName = CommonUtil.getFileName(url)
    saveAs(url, fileName)
  }
  // 截取文件名
  static getFileName(filePath: string) {
    return filePath.split('/').pop()
  }
  // 立即启动定时器
  static immediateSetInterval(executeFunc: () => void, duration = 5000) {
    return setInterval((() => (executeFunc(), executeFunc))(), duration)
  }
}

export const {
  mitt,
  sleep,
  isEmptyObject,
  downloadUrlFile,
  getFileName,
  immediateSetInterval,
} = CommonUtil
