import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  // 上传
  ADMETPrediction: 'ADMET预测',
  Example: '示例',
  DataUpload: '数据上传',
  PredictionStructure: '预测结果',
  UploadMethod: '输入方式',
  InputData: '输入数据',
  UploadDocument: '上传文档',
  MolecularFormula: 'Smiles式',
  DrawMolecules: '绘制分子',
  UploadFile: '上传文件',
  DownloadTemplate: '上传模板下载',
  Tip: '提示',
  Upload: '上传',
  DownloadCSVFormat: '下载.CSV格式',
  DownloadTXTFormat: '下载.TXT格式',
  DownloadSMIFormat: '下载.SMI格式',
  SmilesMolecularFormula: 'Smiles 分子式',
  InspectionResult: '检验结果',
  Action: '操作',
  SerialNumber: '序号',
  ReturnInputField: '返回输入框',
  Reset: '重置',
  CheckSmiles: '校验',
  Correct: '正确',
  InvalidFormat: '格式错误',
  Delete: '删除',
  Edit: '编辑',
  DeleteMolecularFormula: '删除分子式',
  EditMolecularFormula: '编辑分子式',
  NewMolecularFormula: '新的分子式',
  EmptySmiles: 'Smiles为空',
  EmptyFile: '文件为空',
  GenerateSmiles: '生成Smiles',
  Cancel: '取消',

  // 详情
  Name: '名称',
  ChemicalFormula: '化学式',
  MolecularWeight: '分子量',
  Total: '总计',
}
const zhParagraphInfo = {
  InputPlaceholder:
    '请在文本框中输入不超过100个SMILES分子式,要输入更多,请使用“上传文档”...',
  TopParagraph:
    'ADMET指的是化合物在人体内的吸收（Absorption）、分布（Distribution）、代谢（Metabolism）、排泄（Excretion）和毒性（Toxicity）。基于长期的药物研发实践，研究人员发现候选化合物在后期临床试验中的表现很大程度上与他们的ADMET性质相关。因此，这些性质的预测能够在药物研发早期快速删除有风险的化合物，从而提升候选药物进入临床试验后的成功率。',
  tip1: '1.支持上传格式:csv/txt/smi',
  tip2: '2.最多可上传1000个SMILES分子式,每个分子式单独放置一行,最多1000行',
  tip3: '输入多个Smiles请换行',
  tip4: '确认要删除该Smiles分子式吗?',
  tip5: '当前输入的Smiles超过最大限制100个',
  tip6: '数据为空,请重新输入！',
  tip7: 'Smiles错误，请重新输入',
  tip8: '当前没有请求中数据',
  tip9: '当前序号为{name}的Smiles无效，请修改后重新提交。',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(
  zhParagraphInfo,
  true
)
// 英文的段落式的数据
enParagraphInfo.InputPlaceholder =
  'Please enter no more than 100 SMILES molecular formulas in the text box, to enter more, please use "upload file"'
enParagraphInfo.TopParagraph =
  'ADMET refers to the absorption (Absorption) and distribution (Distribution) of compounds in the human body related to ADMET properties. Therefore, the prediction of these properties can quickly delete risky compounds in the early stage of drug development, thereby improving the success rate, metabolism, excretion and toxicity of candidate drugs after entering clinical trials. Based on long-term drug development practice, researchers found that the performance of candidate compounds in late-stage clinical trials is largely consistent with their'
enParagraphInfo.tip1 = '1.Support upload format: csv/txt/smi'
enParagraphInfo.tip2 =
  '2.Up to 1,000 SMILES molecular formulas can be uploaded, and each molecular formula can be placed on a separate line, with a maximum of 1,000 lines'
enParagraphInfo.tip3 = 'Enter multiple Smiles please wrap lines'
enParagraphInfo.tip4 = 'Are you sure you want to remove the Smiles formula?'
enParagraphInfo.tip5 =
  'The current input Smiles exceeds the maximum limit of 100'
enParagraphInfo.tip6 = 'Data is empty, please input again!'
enParagraphInfo.tip7 = 'Smiles error, please enter again'
enParagraphInfo.tip8 = 'Currently there is no data in the request'
enParagraphInfo.tip9 =
  'The current Smiles with sequence number {name} are invalid, please modify and resubmit.'
// 导出
export const zhAdmet = { ...zhBaseInfo, ...zhParagraphInfo }
export const enAdmet = { ...enBaseInfo, ...enParagraphInfo }
