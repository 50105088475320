import lang from '../compound'
export default {
  compound: {
    compound: '化合物',
    ImgFailed: '暂无图片',
    HeadText: '如切如磋，如琢如磨',
    chemicalreaction: '化学反应',
    bioassay: '生物测定',
    compoundSearch: '化合物搜索',
    NumberOfCompounds: '化合物数量',
    LearnMoreUpdate: '了解更多更新',
    text1: '是世界上最大的可免费访问的化学信息集合。按名称、分子式、结构和其他标识符搜索化学品。查找化学和物理特性、生物活性、安全性和毒性信息、专利、文献引用等。',
    SearchBox: {
      Label1: '化合物',
      Label2: '化学反应',
    },
    search: {
      compound: {
        MolecularFormula: '结构简式',
        MolecularWeight: '相对分子质量',
        IUPACName: 'IUPAC名称',
        createDate: '创建日期',
      },
      chemicalreaction: {
        chemId: '化学反应YYCReaction ID',
        inputs: '反应物',
        outcomes: '生成物',
        eactionConditions: '反应条件',
        temperature: '温度',
        stirring: '搅拌',
      },
    },
    details: {
      substance: {
        Summary: '概要',
        Struct2D: '2D结构',
        Code: '标识',
        Records: '相关记录',
      },
      compound: {
        cancelledOperation: '已取消操作',
        enterValidFileName: '请输入有效的文件名称',
        tooltips: '提示',
        enterPictureSaveName: '请输入图片的保存名称',
        attributeName: '属性名称',
        attributeValue: '属性值',
        GetImage: '预览图片',
        BasicInformation: '基础信息',
        Chemotaxonomy: '化学分类',
        PhysicochemicalProperty: '物理化学性质',
        CompoundSummary: '化合物摘要',
        MedicalSynonyms: '医学近义词',
        Struct2D: '2D结构',
        Refernence: '参考',
        ReferenceNumber: '参考号',
        EuropeanCommunities: '欧洲共同体',
        Number: '编号',
        TheUnitedNations: '联合国',
        UniqueIngredientIdentifier: '唯一标识码',
        Wiki: '维基百科',
        WikiData: '维基百科数据',
        ThesaurusCode: '同义词库代码',
        Pictograms: '象形图',
        Signal: '信号',
        GHSHazardStatement: '危害声明',
        GuardCodeDescription: '防范代码说明',
        ECHACNotificationSummary: 'ECHAC&通知摘要',
        // struct2D: '2D结构',
        GHSClassify: 'GHS分类',
        CreateDate: '创建日期',
        MolecularWeight: '分子量',
        Synonyms: '同义词',
        MolecularFormula: '分子式',
        name: '名称',
        structure: '结构',
        chemicalReaction: '化学反应',
        bioassay: '生物测定',
        similarCompound: '相似化合物',
        classify: '化学分类',
        nameCode: '名称和标识符',
        physicochemicalProperties: '物理化学性质',
        drugsInfo: '药物和药物信息',
        pharmacologyInfo: '药理学和生物化学',
        madeUse: '使用和制造',
        identification: '鉴定',
        safety: '安全与危害',
        toxicity: '毒性',
        summary: '摘要',
        struct: '结构',
        Struct3D: '3D结构',
        CrystalStructures: '晶体结构',
        CalculationDescriptor: '计算描述符',
        OtherIdentifiers: '其他标识符',
        ExperimentalProperties: '实验性质',
        ComputerProperties: '计算属性',

        DrugIndication: '药物适应症',
        LiverToxSummary: 'LiverTox汇总',
        DrugClasses: '药物分类',
        TherapeuticUses: '治疗用途',
        DrugWarnings: '药物使用警告',
        DrugIdiosyncrasies: '药物特性',
        ReportedFatalDose: '报告致死剂量',
        MaximumDrugDose: '最大药物剂量',

        Pharmacodynamics: '药效学',
        MeSHPharmacologicalClassification: 'MESH药理学分类',
        AbsorptionDistributionandExcretion: '吸收、消化和排泄',
        MetabolismMetabolites: '代谢/代谢产物',
        BiologicalHalfLife: '生物半衰期',
        MechanismofAction: '作用机制',

        Uses: '用途',
        MethodsofManufacturing: '制造方法',
        Impurities: '杂质',
        ConsumptionPatterns: '消费模式',
        GeneralManufacturingInformation: '一般制造信息',

        AnalyticLaboratoryMethods: '分析实验方法',
        ClinicalLaboratoryMethods: '临床实验方法',

        HazardsIdentification: '危害鉴定',
        SafetyandHazardProperties: '安全和危害',
        FirstAidMeasures: '急救措施',
        FireFighting: '消防',
        AccidentalReleaseMeasures: '意外处理措施',
        HandlingandStorage: '处理和储存',
        ExposureControlandPersonalProtection: '暴露控制和个人防护',
        StabilityandReactivity: '稳定性和反应性',
        TransportInformation: '转运信息',
        OtherSafetyInformation: '其他安全信息',

        ToxicologicalInformation: '毒理学信息',
        EcologicalInformation: '生态信息',

        ChemicalReactionFormula: '化学反应式',
        Details: '查看详情',
        PhysicalDescription: '物理描述',
        Source: '来源',
        Description: '描述',
        LicenseNote: '许可证说明',
        LicenseURL: '许可证链接',
        Colour: '颜色',
        Odor: '气味',
        BoilingPoint: '沸点',
        MeltingPoint: '熔点',
        FlashPoint: '燃点',
        Solubility: '溶解度',
        Density: '密度',
        VaporPressure: '蒸汽压',
        LogP: '疏水系数',
        HenrysLawConstant: '亨利常数',
        StabilityShelfLife: '稳定性/保质期',
        Decomposition: '分解',
        DissociationConstants: '解离常数',
        CollisionCrossSection: '碰撞截面',
        KovatsRetentionIndex: '科法兹保留指数',
        OtherExperimentalProperties: '其他实验性质',
        ChemicalClasses: '化学分类',
        UserExplain: '用途说明',
        UsageClassification: '使用分类说明',
        HazardClassesandCategories: '危害类别及分类',
        HealthHazards: '健康危害',
        HazardsSummary: '危害总结',
        Flammability: '可燃性',
        SkinEyeandRespiratoryIrritations: '皮肤、眼睛和呼吸道刺激',
        FlammableLimits: '易燃限值',
        PhysicalDangers: '物理危害',
        OSHAStandards: '职业安全管理标准',
        NIOSHRecommendations: 'NIOSH建议',
        FirstAid: '急救',
        IngestionFirstAid: '吸入急救措施',
        SkinFirstAid: '皮肤接触急救',
        EyeFirstAid: '眼睛接触急救',
        InhalationFirstAid: '摄入急救',
        IsolationandEvacuation: '隔离和疏散',
        SpillageDisposal: '溢出物处理',
        PreventiveMeasures: '预防措施',
        DisposalMethods: '处理方法',
        NonfireSpillResponse: '非火灾泄露响应',
        SafeStorage: '安全储存',
        StorageConditions: '储存条件',
        RecommendedExposureLimit: '建议暴露限值',
        PermissibleExposureLimit: '允许暴露限值',
        ImmediatelyDangeroustoLifeorHealth: '对生命或健康有直接危险',
        ThresholdLimitValues: '阈值限制值',
        InhalationRisk: '吸入风险',
        EffectsofShortTermExposure: '短期暴露的影响',
        EffectsofLongTermExposure: '长期暴露的影响',
        PersonalProtectiveEquipment: '个人防护装备',
        RespiratorRecommendations: '防护建议',
        FirePrevention: '防火',
        ExposurePrevention: '预防泄露',
        InhalationPrevention: '预防吸入',
        SkinPrevention: '预防接触',
        EyePrevention: '预防进入眼睛',
        IngestionPrevention: '预防摄入',
        AirandWaterReactions: '空气和水的反应',
        ReactiveGroup: '反应群',
        ReactivityProfile: '反应性概况',
        HazardousReactivitiesandIncompatibilities: '危险反应和不相容性',
        DOTLabel: '点标签',
        SpecialReports: '特别报道',
        ToxicitySummary: '毒性总结',
        Hepatotoxicity: '肝毒性',
        DrugInducedLiverInjury: '药物性肝损伤',
        ExposureRoutes: '接触途径',
        Symptoms: '症状',
        InhalationSymptoms: '吸入症状',
        SkinSymptoms: '皮肤症状',
        EyeSymptoms: '眼睛症状',
        IngestionSymptoms: '摄入症状',
        TargetOrgans: '靶器官',
        Interactions: '相互作用',
        AntidoteandEmergencyTreatment: '解毒和紧急治疗',
        HumanToxicityExcerpts: '人体毒性摘录',
        NonHumanToxicityExcerpts: '非人体毒性摘录',
        HumanToxicityValues: '人类毒性值',
        NonHumanToxicityValues: '非人类毒性值',
        EcotoxicityValues: '生态毒性值',
        EcotoxicityExcerpts: '生态毒性摘录',
        EnvironmentalFate: '环境归宿',
        ArtificialPollutionSources: '人为污染',
        EnvironmentalBiodegradation: '环境生物降解',
        EnvironmentalAbioticDegradation: '环境非生物降解',
        EnvironmentalBioconcentration: '环境生物富集',
        SoilAdsorptionMobility: '土壤吸附/迁移',
        VolatilizationfromWaterSoil: '水/土壤挥发',
        EnvironmentalWaterConcentrations: '环境水浓度',
        EffluentConcentrations: '流出物浓度',
        MilkConcentrations: '母乳浓度',
        ProbableRoutesofHumanExposure: '可能的人类暴露途径',
        BodyBurden: '身体负担',
        MolecularFramework: '分子结构',
        Substituents: '取代基',
        classificationTree: '分类树',
        FDAOrangeBook: 'FDA橙皮书',
        StickModel: '棍棒模型',
        SphereModel: '球模型',
        AnimationEffect: '动画效果',
        ShowingHydrogenBonds: '显示氢键',
        CRbtn: '查看详细内容',
        tradename: '商品名',
        applicant: '申请人',
      },
      reaction: {
        basicInfo: '基础信息',
        BasicInformation: '基本信息',
        References: '参考文献',
        ConditionOfReaction: '反应条件',
        REACTANT: '反应物',
        SOLVENT: '溶剂',
        REAGENT: '试剂',
        CATALYST: '催化剂',
        MICROLITER: '微升',
        MILLILITER: '毫升',
        LITER: '升',
        MILLIGRAM: '毫克',
        GRAM: '克',
        KILOGRAM: '千克',
        MOLE: '摩尔',
        MILLIMOLE: '毫摩尔',
        Product: '生成物',
        temperature: '温度',
        stirring: '搅拌',
        other: '其他',
        ProcedureDetails: '过程详情',
        ChemicalReactionQuery: '化学反应',
        ChemicalReactionDetails: '详情',
      },
      ADMET: {
        BaseInfo: {
          Name: '名称：',
          Structure: '结构：',
          ChemicalFormula: '化学式：',
          MolecularWeight: '分子量：',
        },
        compoundInfo: '化合物基本信息', //Basic information of compounds,
        absorption: '吸收（A）',
        distribution: '分布（D）',
        metabolism: '代谢（M）',
        excretion: '排泄（E）',
        Macrotoxicity: '宏观毒性（T）',
        Microtoxicity: '微观毒性（T）',
        title1: 'Caco-2细胞渗透性常被用于预测口服药物的吸收。此结果为0-1的实数，数值越高，则该化合物具有高Caco-2细胞渗透性（Papp>=8*10^-6cm/s）的概率就越大。',
        title2: '化合物被人肠道吸收的比率。',
        title3: '口服生物利用度反映了药物由口服给药进入体循环的药量比例。结果为0-1的实数。数字越接近1，该化合物的口服生物利用度高于30%的概率越高。',
        title4:
          'P糖蛋白能将化合物排出细胞外。若某种化合物为其抑制剂，则阻止P糖蛋白将该化合物转运出细胞，从而增加它在细胞内的浓度。该结果为0-1的实数。数字越接近1，该化合物成为P糖蛋白抑制剂的概率越高。',
        title5:
          'P糖蛋白能将化合物排出细胞外。若某种化合物为其底物，则更容易被P糖蛋白转运出细胞，从而降低它在细胞内的浓度。该结果为0-1的实数。数字越接近1，该化合物成为P糖蛋白底物的概率越高。',
        prob: '概率',
        Index: '序号',
        Name: '名称',
        Value: '数值',
        n1: 'Caco-2细胞渗透性（Caco-2 permeability）',
        n2: '人肠道吸收率（Human intestinal absorption）',
        n3: '口服生物利用度（Oral Availability）',
        n4: 'P糖蛋白抑制（P-glycoprotein inhibitor）',
        n5: 'P糖蛋白底物（P-glycoprotein substrate）',
        title6: '决定了化合物能否进入脑部。结果为0-1的实数。数字越接近1，该化合物能通过血脑屏障的概率越高。',
        title7: '标志着化合物有多大的比例会和血浆中的蛋白结合，这种结合会使得药物更多的分布于血液而非其他组织。',
        n6: '血脑屏障通过性（Blood-brain-barrier permeant）',
        n7: '血浆蛋白结合率（Plasma protein binding）',
        title8:
          '细胞色素P450(CYPs是人体内最主要的药物新陈代谢酶。如果化合物为CYPs的抑制剂（AC50<10μM），则可能增加其他药物的浓度，造成药物-药物相互作用（DDI）引起的人体毒性。结果为0-1的实数。数字越接近1，该化合物成为该CYP抑制剂的概率越高。',
        title9:
          '细胞色素P450是人体内最主要的药物新陈代谢酶。如果化合物为CYPs的底物，则它可能会被CYPs氧化，导致a)药物失效和/或b)产生有毒产物。同时，这些化合物的活性可能会受其他食品、药品中的CYPs抑制成分影响。此结果为0-1的实数。数字越接近1，该化合物成为该CYP底物的概率越高。',
        M_n1: '细胞色素P450 1A2族酶抑制（CYP1A2 inhibitor）',
        M_n2: '细胞色素P450 1A2族酶底物（CYP1A2 substrate）',
        M_n3: '细胞色素P450 2C19族酶抑制（CYP2C19 inhibitor）',
        M_n4: '细胞色素P450 2C19族酶底物（CYP2C19 substrate）',
        M_n5: '细胞色素P450 2C9族酶抑制（CYP2C9 inhibitor）',
        M_n6: '细胞色素P450 2C9族酶底物（CYP2C9 substrate）',
        M_n7: '细胞色素P450 2D6族酶抑制（CYP2D6 inhibitor）',
        M_n8: '细胞色素P450 2D6族酶底物（CYP2D6 substrate）',
        M_n9: '细胞色素P450 3A4族酶抑制（CYP3A4 inhibitor）',
        M_n10: '细胞色素P450 3A4族酶底物（CYP3A4 substrate）',
        title10: '该通路被激活后可能对大范围细胞进程和功能造成负面影响，还可能引起免疫毒性。结果为0-1的实数。数字越接近1，则化合物激活该通路的概率越高。',
        title11: '该通路被激活可能引发多种疾病，典型的如前列腺癌。结果为0-1的实数。数字越接近1，则化合物影响该通路的概率越高。',
        title12: '该通路被抑制会导致雌雄激素失衡。结果为0-1的实数。数字越接近1，则化合物抑制该通路的概率越高。',
        title13: '该通路被激活可能引发多种疾病，典型的如乳腺癌。结果为0-1的实数。数字越接近1，则化合物影响该通路的概率越高。',
        title14: '该通路被激活可能导致增重、骨流失、充血性心力衰竭等。结果为0-1的实数。数字越接近1，则化合物激活该通路的概率越高。',
        title15: '由氧化压力(oxidative stress)激活。结果为0-1的实数。数字越接近1，则化合物影响该通路的概率越高。',
        title16: '由DNA损伤激活。结果为0-1的实数。数字越接近1，则化合物激活该通路的概率越高。',
        title17: '由热休克（heat shocks）激活，导致蛋白去折叠，影响细胞活性等。结果为0-1的实数。数字越接近1，则化合物影响该通路的概率越高。',
        title18: '其干扰影响细胞有氧呼吸。结果为0-1的实数。数字越接近1，则化合物影响该通路的概率越高。',
        title19: '由DNA损伤等压力条件激活。结果为0-1的实数。数字越接近1，则化合物影响该通路的概率越高。',
        microTn1: '芳香烃受体激活（Aryl hydrocarbon receptor (AhR) activation）',
        microTn2: '雄激素受体激活（Androgen (AR) receptor activation）',
        microTn3: '芳香化酶（CYP19）抑制（Aromatase (CYP19) inhibition)',
        microTn4: '雌激素受体激活（Estrogen (ER) receptor activation）',
        microTn5: '过氧化物酶体增殖物激活受体γ激活（PPAR-γ activation）',
        microTn6: '抗氧化反应元素信号通路激活（Antioxidant response element activation）',
        microTn7: 'ATP酶族AAA结构域蛋白5型激活（ATAD5 activation）',
        microTn8: '热休克反应信号通路激活（HSE activation）',
        microTn9: '线粒体膜电位干扰（Mitochondrion membrane potential disturbance）',
        microTn10: 'p53蛋白激活（p53 activation）',
        Excrete: '半衰期（Half life）',
        ExcreteTitle:
          '化合物在体内的半衰期指其浓度下降到初始值的1/2所花费的时间，半衰期过短（<3h）一般来说意味着更短的治疗时间窗，更高的毒性和更频繁的给药。结果为0-1的实数。数字越接近1，该化合物的半衰期大于3小时的概率越高。',
        MacroToxicity: {
          Rodent: '啮齿类动物急性口服毒性（Acute oral toxicity on rodents）',
          Human: '人急性口服毒性（Acute oral toxicity on human）',
          Carcinogenicity: '致癌性（Carcinogenicity）',
          Ames: 'Ames致突变性（Ames mutagenicity）',
          Heptox: '肝毒性（Hepatotoxicity）',
          RodentTitle:
            'LD50：指在一定时间内，在啮齿类动物上进行急性口服毒性试验时引起半数动物死亡的剂量。结果为0-1的实数。数字越接近1，该化合物在啮齿类动物上诱发急性毒性反应的概率越高。化合物的对啮齿类动物的LD50小于500 mg/kg记为1，否则记为0。',
          HumanTitle:
            'TDlo：指在一定时间内，在人体上进行急性口服毒性试验时引起毒性反应的最低剂量。结果为0-1的实数。数字越接近1，该化合物在人体上诱发急性毒性反应的概率越高。化合物的对人类的TDlo小于500 mg/kg记为1，否则记为0。',
          CarcinogenicityTitle: '标志着化合物是否能够诱发癌症。结果为0-1的实数。数字越接近1，该化合物诱发癌症的概率越高。',
          AmesTitle: 'AMES致突变性与化合物的致癌性具有相关性，可以作为药物致癌性的先期测试。结果为0-1的实数。数字越接近1，该化合物引起AMES致突变性的概率越高。',
          HeptoxTitle: '标志着化合物是否具有肝毒性。结果为0-1的实数。数字越接近1，该化合物具有肝毒性的概率越高。',
        },
        ADMETP: 'ADMET预测',
        Forecastdetails: '预测详情',
      },
    },
    DataStatistic: {
      HeadTitle1: '化合物数量折线图',
      HeadTitle2: '化合物分类图示',
      HeadTitle3: '化合物录入情况图示',
      TitleText1: '下面展示了各年月YYHOME化合物库中的数据的变化折线图，可切换不同视图，选择具体年月日。',
      TitleText2: '下图为YYHOME化合物库中化合物分类层次的表示，单击一个部分展开它，然后单击中心按钮返回上一级别。',
      TitleText3: '下图展示了YYHOME化合物库中有机物和无机物数量及其占比。',
      Line: {
        TabText: '切换视图：',
        Radio1: '年',
        Radio2: '月',
        Radio3: '日',
        Tip: '请选择一个或多个年份',
        To: '至',
        CompoundNum: '化合物总数',
        ChemicalReactionNum: '化学反应总数',
        // OrganicMatterNum: '有机物数',
        // InorganicNum: '无机物数',
      },
      BarAndPie: {
        PieHeadTitle: 'YYHOME化合物录入情况扇形图',
        BarHeadTitle: 'YYHOME化合物录入情况柱状图',
        UpTo: '截至',
        Year: '年',
        Month: '月',
        Compound: '化合物',
        ChemicalReaction: '化学反应',
        // OrganicMatter: "Organic Matter",
        // Inorganic: "Inorganic Matter",
        Unit: '单位：条',
      },
    },
    //靶点预测
    TargetPrediction: {
      HeadTitle: '靶点预测',
      CompoundUpload: '化合物上传',
      TargetPredictionResults: '靶点预测结果',
      UploadTitle: '您可以在此预测一个化合物可能存在的靶点 (假设是生物活性的),这一预测是基于二维和三维相似性的结合,以及来自YYHome的56万多种蛋白质',
      UploadTip: '若Smiles号和结构式同时存在,则以Smiles号为主',
      Radio: {
        RadioText: '请选择物种',
        Radio1: '人类(Homo sapiens)',
        Radio2: '小鼠(Mus musculus)',
        Radio3: '大鼠(Rattus norvegicus)',
        Radio4: '猪(Sus scrofa)',
        Radio5: '牛(Bos taurus)',
        Radio6: '其他(Other)',
      },
      InputTip: '在这个方框中粘贴一个SMILES,或者在右侧绘图框绘制化合物结构',
      InputText: '请输入或粘贴一个SMILES...',
      EMailInput: '发送结构至邮箱',
      EMailInputText: '请输入邮箱号',
      WarningText1: '邮箱格式错误，请重新输入',
      WarningText2: '参数缺失,请输入Smiles或者绘制化学结构式',
      WarningText3: '当前输入的Smiles错误，请重新输入',
      ResetBtn: '重置',
      SubmitBtn: '开始预测',
      Dialog: {
        HeadTitle: '等待结果',
        LoadingText1: '计算中，预测时间比较长，请耐心等待',
        LoadingText2: '计算中，预测时间比较长，请耐心等待！或者你可以通过填写邮箱地址，成功之后会发送到你的邮箱。',
        ConfirmBtn: '确认',
        CancelBtn: '取消',
        BackBtn: '返回',
        CancelReqBtn: '取消请求',
        BtnText1: '你确认是否取消本次的数据请求？',
        BtnText2: '你确认是否取消本次的数据请求？并填写邮箱地址！',
      },
      Result: {
        NoData: '当前没有请求的数据',
        ComStructure: '化合物结构',
        DownloadRes: '下载结果',
        Target: '靶点',
        Possibility: '亲和度',
      },
    },
    //元素周期表
    PeriodicTable: {
      HeadTitle: '元素周期表',
      Tip: '说明：根据元素状态进行颜色分类（不同种类对应一种颜色）',
      Mnemonics: '元素周期表记忆法',
      PinyinMnemonics: '拼音助记法',
      MnemonicsOfAncientPoetry: '古诗词助记法',
      IntroduceYourselfToMnemonics: '自我介绍助记法',
      Select: {
        HeadTitle: '显示属性/趋势',
        Label1: '化学组块',
        Label2: '原子质量,u',
        Label3: '标准状态',
        Label4: '电子构型',
        Label5: '氧化态',
        Label6: '电负性(鲍林标度)',
        Label7: '原子半径(范德华力),pm',
        Label8: '电离能,eV',
        Label9: '电子亲和势,eV',
        Label10: '熔点,K',
        Label11: '沸点,K',
        Label12: '密度,g/cm³',
        Label13: '发现年份',
        Label14: '无',
      },
      Details: {
        label1: '原子质量',
        label2: '标准状态',
        label3: '电子构型',
        label4: '氧化态',
        label5: '电负性(鲍林标度)',
        label6: '原子半径(范德华力)',
        label7: '电离能',
        label8: '电子亲和势',
        label9: '熔点',
        label10: '沸点',
        label11: '密度',
        label12: '发现年份',
      },
    },
    // Admet页面预测
    AP: { ...lang.zhAdmet },
    // 逆合成预测
    IS: { ...lang.zhInverseSynthesis },
    // 靶点预测
    TP: { ...lang.zhTargetPrediction },
    // 结构绘制
    SD: { ...lang.zhStructDraw },
    // 相似化合物搜索
    SC: { ...lang.zhSimilarCompound },
    // 元素周期表
    PT: { ...lang.zhPeriodicTable },
  },
}
