import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  InverseSyntheticPrediction: '逆合成预测',
  PredictionResult: '预测结果',
  InputMethod: '输入方式',
  MaximumSearchDepth: '最大搜索深度',
  MaximumSearchWidth: '最大搜索宽度',
  NumberResults: '返回结果数量',
  ModelSelection: '模型选择',
  SmallModel: '小模型',
  LargeModel: '大模型',
  Prediction: '预测',
  Scheme: '方案',
  ParamSetting: '参数设置',
  downLoad: '下载',
  Format: '格式',
  Name: '名称',
  GoToDetail: '前往详情页',
  Task: '任务',
  TaskName: '任务名称',
  SubmitTime: '提交时间',
  RunTime: '预测时间',
  Status: '状态',
}
const zhParagraphInfo = {
  tip01: '逆合成预测指的是根据给定的目标化合物，设计出完整且合理的有机化学合成路线，这一过程在合成化学和药物发现中都起着重要作用。',
  tip02: '请在文本框中输入SMILES分子式，或者通过“绘制分子”获得SMILES分子式',
  tip03: '反应物在2个以内的训练集',
  tip04: '反应物在4个以内的训练集',
  tip05: '请输入返回结果数量',
  tip06: 'Smiles不能为空。',
  tip07: '请输入正确的Smiles',
  tip08: '文件中只允许输入一个SMILES分子式',
  tip09: '请上传文件',
  tip10: '查询数据为空',
  tip11: '可直接将文件拉拽至此处，支持格式：csv/txt/smi，文件只允许输入一个SMILES分子式',
  tip12: '逆合成预测指的是根据给定的目标化合物，设计出完整且合理的有机化学合成路线，这一过程在合成化学和药物发现中都起着重要作用。',
  tip13: '说明：最大搜索深度越大，遍历越深，最大搜索宽度越大，遍历越广。宽度和深度设置得越大，预测时问越长，获得可能性越多。若想减少预测时间，可通过减小这两个参数的数值来调节。',
  tip14: '放大图片',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)

enParagraphInfo.tip01 =
  'Inverse synthesis prediction refers to the process of designing a complete and rational organic chemical synthesis route based on a given target compound, which plays an important role in both synthetic chemistry and drug discovery.'
enParagraphInfo.tip02 = 'Please enter the SMILES molecular formula in the text box, or obtain the SMILES molecular formula by Drawing Molecules'
enParagraphInfo.tip03 = 'Training set within two reactants'
enParagraphInfo.tip04 = 'Training set within four reactants'
enParagraphInfo.tip05 = 'Please enter the number of results returned'
enParagraphInfo.tip06 = 'Smiles cannot be empty.'
enParagraphInfo.tip07 = 'Please input the correct Smiles'
enParagraphInfo.tip08 = 'The file is only allowed to enter a SMILES formula'
enParagraphInfo.tip09 = 'Empty file'
enParagraphInfo.tip10 = 'The query data is empty'
enParagraphInfo.tip11 = 'The file can be directly pulled and dropped here, supported format: csv/txt/smi, the file allows only one SMILES formula to be entered'
enParagraphInfo.tip12 =
  'Inverse synthesis prediction refers to the process of designing a complete and rational organic chemical synthesis route based on a given target compound, which plays an important role in both synthetic chemistry and drug discovery.'
enParagraphInfo.tip13 =
  "Description: The greater the maximum search depth, the deeper the traversal, and the greater the maximum search width, the wider the traversal. The larger the width and depth Settings, the longer the prediction time, and the greater the probability of obtaining. If you want to reduce the prediction time, you can adjust by reducing the values of these two parameters. 's"
enParagraphInfo.tip14 = 'Click to enlarge the image'

export const zhInverseSynthesis = { ...zhBaseInfo, ...zhParagraphInfo }
export const enInverseSynthesis = { ...enBaseInfo, ...enParagraphInfo }
