export default {
  home: {
    nucleobase: {
      restrictionEnzymesProtectBaseTable: '限制性内切酶保护碱基表',
      protectiveBaseDefinition:
        '保护碱基定义：限制性内切酶识别特定的DNA序列，除此之外，酶蛋白还要占据识别位点两边的若干个碱基，这些碱基对内切酶稳定的结合到DNA双链并发挥切割DNA作用是有很大影响的，被称为保护碱基。',
      homePage: '首页',
      gene: '基因',
      protectBaseTable: '保护碱基表',
      enzyme: '酶',
      oligonucleotideSequence: '寡核苷酸序列',
      chainLength: '链长',
      cuttingRate: '切割率',
    },
    knowledgeAtlas: {
      noData: '暂无数据',
      accuratelyLocateTheTargetObject:
        '注意:为精确定位目标对象，需输入目标对象的完整名称才能展示该对象的知识图谱(可通过输入提示列表选择目标对象)。',
      selectAssociatedObjectType: '请选择关联对象类型',
      inputFieldCannotEmpty: '输入框不能为空',
      advancedFilter: '高级筛选',
      associatedObjectTypeFiltering: '关联对象类型筛选',
      all: '全部',
      calculatingStepSize: '计算步长',
      theGraphWillNotUpdated: ' 注:完成筛选后点击"开始计算"按钮才会更新图谱',
      reset: '重置',
      confirm: '确认',
      beginToCalculate: '开始计算',
      disease: '疾病',
      goAnnotation: 'GO注释',
      protein: '蛋白质',
      medicine: '药物',
      compound: '化合物',
      gene: '基因',
      genome: '基因组',
      biologicalProject: '生物项目',
      cellLine: '细胞系',
      bioassay: '生物测定',
      foodPlant: '食用植物',
      medicinalPlant: '药用植物',
      nutritionalIngredient: '营养成分',
      speciestTaxonomy: '物种分类学',
      selectMapType: '选择图谱类型',
      targetObjectMapDisplay: '目标对象图谱展示',
      viewTheObjectsPropertiesAssociated: '查看与目标对象相关联的对象及属性',
      objectAssociationPathSearch: '对象关联路径搜索',
      viewTheRelationship: '查看两个目标对象之间的联系',
      theTargetObjectMap: '目标对象图谱展示操作流程',
      selectTheObjectType:
        '选目标对象类型,输入对象名称根据搜索联想结果选择具体对象',
      clickAdvancedFilter:
        '点击高级筛选,进行关联对象类型筛选,选择需要查询的图谱范围',
      clickStartCalculation: '点击开始计算,查询出的图谱关系将展示在画布中央',
      clickTheObject: '点击画布中央的对象,显示对象信息面板',
      objectAssociationPathOperationFlow: '对象关联路径操作流程',
      selectTargetPairType:
        '选择目标对类型,输入要比较的两个对象名称,根据搜索联想结果选择具体对象',
      clickAdvancedFilterAssociatedObjectType:
        '点击高级筛选,进行关联对象类型筛选,选择需要查询的图谱范围和计算步长(计算步长代表对象A-对象B之间的间隔几步)',
    },
    personal: {
      uploading: '上传',
      cancel: '取消',
    },
    physicochemicalPropertyAnalyze: {
      selectionRegionSequence: '选择区域序列',
      youCanSelectSequenceFragmentCorresponding:
        '说明：您可以通过选择下列一对端点来选择乡对应的序列片段',
      inputAreaFragment: '输入区域片段',
      youCanSelectCorrespondingSequenceFragmentEntering:
        '或者，你可以通过输入所需端点来选择对应的序列片段，你选择的序列片段至少有5个氨基酸长度，默认情况下，使用完整的序列',
      NTerminal: 'N端',
      CTerminal: 'C端',
      singleLineInput: '单行输入',
      reset: '重置',
      submit: '提交',
      currentOperationWillDeleteTheData:
        '请注意，当前操作将会删除当前项后面已经获取到的数据,请确认后再操作！',
    },
    primerDesignUploadDetail: {
      enterPCRTemplateName: '请输入PCR模板名称',
      mustFillIn: '必填',
    },
    registration: {
      enterYourMobileNumber: '请输入手机号码',
      accountConsistsDigitsStarting: '账号以1开头的11位数字组成',
      messageAuthenticationCode: '短信验证码',
      enterYourVerificationCode: '请输入您的验证码',
      getVerificationCodeFree: '免费获取验证码',
      password: '密码',
      enterYourPassword: '请输入密码',
      confirmPassword: '确认密码',
      confirmPasswordTwice: '请二次确认密码',
      personalInformation: '个人信息',
      familyName: '姓',
      firstName: '名',
      enterLastName: '请输入您的姓',
      enterYourName: '请输入您的名',
      address: '地址',
      enterYourAddress: '请输入您的地址',
      detailedAddress: '详细地址',
      enterYourFullAddress: '请输入您的详细地址',
      postalCode: '邮政编码',
      enterYourZipCode: '请输入您的邮政编码',
      organizationInformation: '机构信息',
      institutionOrOrganization: '机构/组织',
      department: '部门',
      laboratory: '实验室',
      duty: '职务',
      researchField: '研究领域',
      submit: '提交',
      back: '返回',
      enterYourPasswordAgain: '请再次输入密码',
      twoPasswordInconsistencies: '两次输入密码不一致!',
      enter11DigitPhoneNumber: '请输入11位手机号',
      emailFormatError: '邮箱格式错误',
      theMailboxCannotEmpty: '邮箱不能为空',
    },
    login: {
      unregisteredMobileLoginAutomaticallyCreates:
        '未注册手机登录时会自动创建新账号',
      readAndAgreeServiceAgreementAndPrivacyPolicy:
        '我已阅读并同意服务协议和隐私权条款',
      loginSuccessfully: '登录成功',
    },
    news: {
      thereNoNewsYourSearch: '您搜索的内容暂无新闻',
      detailedInformation: '详情信息',
    },
    alphaFold: {
      considerHumanProteinEncoded: '考虑由基因GNE（Q9Y223）编码的人类蛋白质',
      itObviouslyHasTwoDomains:
        '它显然有 2 个域;事实上，他们有已知的结构。AlphaFold 会预测它们的相对位置吗？为了回答这个问题，我们使用预测对齐误差（PAE）图',
      thePAEDiagramIsNotResidualToResidue:
        '请注意，PAE 图不是残基间距离图或接触图。相反，绿色阴影表示预期的距离误差（以 Ångströms 为单位）。（x， y） 处的颜色对应于残基 x 位置的预期距离误差，当预测和真实结构在残基 y 上对齐时，',
      darkGreenMeansGood:
        '深绿色表示良好（低误差），浅绿色表示差（高误差）。例如，对齐残留物 300',
      confidentRelativePositionOfResidue200:
        '我们对残留物200的相对位置充满信心',
      confidenceInTheRelativeLocationOfResidue600:
        '我们对残留物600的相对位置没有信心',
      twoLowErrorSquaresCorrespond:
        '当然，两个低误差方块对应于两个域。如果单击并拖动以突出显示它们，则它们会在结构上突出显示。单击十字以删除突出显示。',
      highlightingDiscontinuousAreasIsNot:
        ' 目前不支持突出显示不连续区域。选择一个非对角线区域将突出显示从最小选定残基到最大选定残基的整个范围。',
      theHighPredictiveAlignment:
        '整个域间区域的高预测对齐误差告诉我们，在这种情况下，AlphaFold 不会预测相对域位置。',
      morePromisingInterdomain:
        '一个更有希望的域间例子是由DIP2B（Q9P265）编码的人类蛋白质',
      alignedResiduesAround850:
        '在这种情况下，在850左右的残基上对齐，我们对1450左右残基的相对位置有信心，这表明在小的中心域之间有堆积',
    },
    balst: {
      querySequence: '查询序列',
      enterFasta: '输入序列',
      target: '目标',
      DS: '数据集',
      nucleotideSet: '核苷酸集',
      genome: '基因组',
      transcriptome: '转录组',
      submitTask: '提交任务',
      setTaskName: '设置任务名称',
      notificationViaEmail: '通过电子邮件通知',
      setUpParameters: '设置参数',
      outputFormat: '输出格式',
      generalParameter: '一般参数',
      maximumNumberAlignmentsRetain: '要保留的最大对齐数',
      expectationSetting: '期望值设置 ',
      wordLengthMatchingFragmentLength: '字长( 匹配片段长度 )',
      scoringParameter: '评分参数',
      filteringMaskingProcedures: '过滤与屏蔽程序',
      advertising:
        '基本局部比较搜索工具（BLAST）可以查找生物序列之间的相似区域，该程序将核苷酸或蛋白质序列与序列数据库进行比较，获得序列相似度等信息，从而判断序列的来源或进化关系，并有助于识别基因家族成员。',
      submit: '提交',
      reset: '清除',
      inputFieldCannotEmpty: '输入框不能为空',
      returnSubmitTask: '返回提交任务',
      batchDownload: '批量下载',
      selectTasksBatchDownload: '选择任务进行批量下载',
      taskIDNotificationEmail: '任务ID或通知电子邮件',
      retrieval: '检索',
      taskType: '任务类型',
      taskName: '任务名称',
      submitIn: '提交于',
      status: '状态',
      expirationTime: '到期时间',
      handle: '操作',
      thisListOnlyKeeps: '说明：此列表仅保留浏览器一周内的历史任务，您可以在',
      personalCenterinMyMission: '个人中心-我的任务',
      viewAllTasksYourCollection: '中查看您收藏的全部任务',
      thisListOnlyKeeps1: '说明：此列表仅保留浏览器一周内的历史任务，',
      logIn: '登录',
      afterThatYouCanView: '之后，您可查看您最近的全部任务',
      uploadSerialNumberError: '上传序列号出错',
      areYouSureDeleteThisResult: '确定删除这一条结果吗？',
      areYouSureBookmarkThisResult: '确定收藏这一条结果吗？',
      areYouSureAboutTheResultUnfavorites: '确定取消收藏这一条结果吗？',
      taskID: '任务ID',
      clickDownload: '点击下载',
      uploadTime: '上传时间',
      workingCondition: '工作状态',
      finish: '完成',
      program: '程序',
      quote: '引用',
      molecularType: '分子类型',
      database: '数据库',
      queryLength: '查询长度',
      enterTheCorrectSerialNumber: '暂无结果',
    },
  },
}
