/**
 * 注册 API
 */
import request from '@/utils/request'
import { SoaRecord } from 'dns'

//注册页面二维码
export function GETCode<T = any>() {
  return request<T>('/user/getVerifiCodeImage', 'GET')
}

//邮箱注册
export function POSTregistration<T = any>(data: any) {
  return request<T>(`/user/register`, 'POST', data)
}

//邮箱注册验证
export function POSTcheckregister<T = any>(data: any) {
  return request<T>(`/user/checkregister?code=${data.code}`, 'POST', null, {
    headers: { Email: `${data.mali}` },
  })
}

//再次发送邮箱验证
export function GETresendmail(data: any) {
  return request(`/user/resendmail`, 'GET', null, {
    headers: { Email: `${data.mali}` },
  })
}

//微信登录获取id
export function GETwechatlogin() {
  return request('/user/wechatlogin', 'GET')
}

//微信回调返回信息
export function GETwechatcheck<T = any>(code: any, state: any) {
  return request(`/user/wechatcheck?code=${code}&state=${state}`, 'get')
}

//微信刷新后调用返回用户信息
export function GETuserinfo<t = any>(cookie: string | undefined) {
  return request(`/user/getuserinfo`, 'get', null, {
    headers: { Authorization: cookie },
  })
}
//微信-用户退出
export function POSTlogout<t = any>(cookie: string | undefined) {
  return request(`/user/logout`, 'post', null, {
    headers: { Authorization: cookie },
  })
}

//手机登录设置昵称
export function POSTuserLogout<t = any>(
  cookie: string | undefined,
  name: string
) {
  return request(`/user/userinfo?name=${name}`, 'post', null, {
    headers: { Authorization: cookie },
  })
}
