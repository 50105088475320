import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  ChineseName: '中文名称',
  Hydrogen: '氢',
  Helium: '氦',
  Lithium: '锂',
  Beryllium: '铍',
  Boron: '硼',
  Carbon: '碳',
  Nitrogen: '氮',
  Oxygen: '氧',
  Fluorine: '氟',
  Neon: '氖',
  Sulfur: '硫',
  Chlorine: '氯',
  Argon: '氩',
  Potassium: '钾',
  Calcium: '钙',
  Scandium: '钪',
  Titanium: '钛',
  Vanadium: '钒',
  Chromium: '铬',
  Manganese: '锰',
  Iron: '铁',
  Cobalt: '钴',
  Nickel: '镍',
  Copper: '铜',
  Zinc: '锌',
  Gallium: '镓',
  Germanium: '锗',
  Arsenic: '砷',
  Selenium: '硒',
  Bromine: '溴',
  Krypton: '氪',
  Rubidium: '铷',
  Strontium: '锶',
  Yttrium: '钇',
  Zirconium: '锆',
  Nistium: '铌',
  Cesium: '铯',
  Barium: '钡',
  Lanthanum: '镧',
  Neodymium: '钕',
  Promethium: '钷',
  Samarium: '钐',
  Europium: '铕',
  Gadolinium: '钆',
  Terbium: '铽',
  Dysprosium: '镝',
  Holmium: '钬',
  Erbium: '铒',
  Thulium: '铥',
  Iridium: '铱',
  Francium: '钫',
  Sodium: '钠',
  Magnesium: '镁',
  Radium: '镭',
  Hafnium: '铪',
  Rutherfordium: '钌',
  Actinium: '锕',
  Niobium: '铌',
  Tantalum: '钽',
  Dubnium: '镝',
  Cerium: '铈',
  Thorium: '钍',
  Molybdenum: '钼',
  Tungsten: '钨',
  Technetium: '锝',
  Seaborgium: '锫',
  Praseodymium: '镨',
  Protactinium: '镤',
  Rhenium: '铼',
  Bohrium: '锇',
  Uranium: '铀',
  Ruthenium: '钌',
  Osmium: '锇',
  Hassium: '鿏',
  Neptunium: '镎',
  Rhodium: '铑',
  Meitnerium: '鿔',
  Plutonium: '钚',
  Americium: '镅',
  Darmstadtium: '鿕',
  Platinum: '铂',
  Palladium: '钯',
  Silver: '银',
  Gold: '金',
  Roentgenium: '鿨',
  Curium: '锔',
  Berkelium: '锫',
  Copernicium: '鿦',
  Mercury: '汞',
  Cadmium: '镉',
  Aluminum: '铝',
  Indium: '铟',
  Thallium: '铊',
  Nihonium: '鿬',
  Californium: '锎',
  Silicon: '硅',
  Tin: '锡',
  Lead: '铅',
  Flerovium: '鿦',
  Einsteinium: '锿',
  Fermium: '镄',
  Moscovium: '鿬',
  Bismuth: '铋',
  Antimony: '锑',
  Phosphorus: '磷',
  Tellurium: '碲',
  Polonium: '钋',
  Livermorium: '鿫',
  Mendelevium: '钔',
  Nobelium: '锘',
  Ytterbium: '镱',
  Tennessine: '鿭',
  Astatine: '砹',
  Iodine: '碘',
  Xenon: '氙',
  Radon: '氡',
  Oganesson: '鿬',
  Lutetium: '镥',
  Lawrencium: '铹',
}
const zhParagraphInfo = {}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)

export const zhPeriodicTable = { ...zhBaseInfo, ...zhParagraphInfo }
export const enPeriodicTable = { ...enBaseInfo, ...enParagraphInfo }
