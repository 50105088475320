import lang from '../compound'
export default {
  compound: {
    compound: 'Compound',
    ImgFailed: 'No Picture',
    HeadText: 'As knife and file make smooth the bone,so jade is wrought by chisel and stone',
    chemicalreaction: 'Chemical Reaction',
    bioassay: 'bioassay',
    compoundSearch: 'Compound Search',
    NumberOfCompounds: 'Compounds Number',
    LearnMoreUpdate: 'Learn More Updates',
    text1:
      'It is the world s largest collection of freely accessible chemical information. Search for chemicals by name, molecular formula, structure and other identifiers. Find chemical and physical properties, biological activity, safety and toxicity information, patents, literature citations, etc',
    SearchBox: {
      Label1: 'Compound',
      Label2: 'Chemical Reactions',
    },
    search: {
      compound: {
        MolecularFormula: 'Skeleton Symbol',
        MolecularWeight: 'Molecular Weight',
        IUPACName: 'IUPAC Name',
        createDate: 'Create Date',
      },
      chemicalreaction: {
        chemId: 'Chemical Reaction YYCReaction ID',
        inputs: 'Reactant',
        outcomes: 'Product',
        eactionConditions: 'Reaction Conditions',
        temperature: 'Temperature',
        stirring: 'Stirring',
      },
    },
    details: {
      compound: {
        cancelledOperation: 'Cancelled operation',
        enterValidFileName: 'Please enter a valid file name',
        tooltips: 'Tooltips',
        enterPictureSaveName: 'Please enter a save name for the picture',
        attributeName: 'Attribute Name',
        attributeValue: 'Attribute Value',
        GetImage: 'Look Image',
        BasicInformation: 'Basic Information',
        Chemotaxonomy: 'Chemotaxonomy',
        PhysicochemicalProperty: 'Physico Chemical Property',
        CompoundSummary: 'Compound Summary',
        MedicalSynonyms: 'Medical Synonyms',
        Struct2D: '2D Structure',
        Refernence: 'Reference',
        ReferenceNumber: 'Reference Number',
        EuropeanCommunities: 'European Communities',
        Number: 'Number',
        TheUnitedNations: 'United Nations',
        UniqueIngredientIdentifier: 'The Unique Identification Code',
        Wiki: 'Wikipedia',
        WikiData: 'Wikipedia Data',
        ThesaurusCode: 'Synonym Dictionary Code',
        Pictograms: 'Pictograms',
        Signal: 'Signal',
        GHSHazardStatement: 'GHS Hazard Statement',
        GuardCodeDescription: 'Guard Code Description',
        ECHACNotificationSummary: 'ECHAC&Notification Summary',
        GHSClassify: 'GHS Classify',
        CreateDate: 'Create Date',
        MolecularWeight: 'Molecular Weight',
        Synonyms: 'Synonyms',
        MolecularFormula: 'Molecular Formula',
        name: 'Name',
        structure: 'Structure',
        bioassay: 'bioassay',
        similarCompound: 'Analogue Compound',
        classify: 'Chemotaxonomy',
        nameCode: 'Names And Identifiers',
        physicochemicalProperties: 'Physicochemical Property',
        drugsInfo: 'Drugs And Drug Information',
        pharmacologyInfo: 'Pharmacology And Biochemistry',
        madeUse: 'Use And Manufacture',
        identification: 'Identification',
        safety: 'Safety And Hazards',
        toxicity: 'Toxicity',
        summary: 'Abstract',
        struct: 'Structure',
        Struct3D: '3D Structure',
        CrystalStructures: 'Crystal Structure',
        CalculationDescriptor: 'Computer Descriptors',
        OtherIdentifiers: 'Other Identifiers',
        ExperimentalProperties: 'Experimental Properties',
        ComputerProperties: 'Computer Properties',

        DrugIndication: 'Drug Indication',
        LiverToxSummary: 'LiverTox Summary',
        DrugClasses: 'Drug Classification',
        TherapeuticUses: 'Therapeutic Applications',
        DrugWarnings: 'Drug Use Warnings',
        DrugIdiosyncrasies: 'Medicinal Properties',
        ReportedFatalDose: 'Report Lethal Dose',
        MaximumDrugDose: 'Maximum Drug Dose',

        Pharmacodynamics: 'Pharmacodynamics',
        MeSHPharmacologicalClassification: 'MESH Pharmacological Classification',
        AbsorptionDistributionandExcretion: 'Absorption, Digestion And Excretion',
        MetabolismMetabolites: 'Metabolism/Metabolites',
        BiologicalHalfLife: 'Biological Half-life',
        MechanismofAction: 'Action Mechanism',

        Uses: 'Usage',
        MethodsofManufacturing: 'Manufacturing Method',
        Impurities: 'Impurities',
        ConsumptionPatterns: 'Pattern Of Consumption',
        GeneralManufacturingInformation: 'General Manufacturing Information',

        AnalyticLaboratoryMethods: 'Analytical Experimental Methods',
        ClinicalLaboratoryMethods: 'Clinical Laboratory Methods',

        HazardsIdentification: 'Hazard Identification',
        SafetyandHazardProperties: 'Safety And Hazards',
        FirstAidMeasures: 'First-aid Measures',
        FireFighting: 'Firefighting',
        AccidentalReleaseMeasures: 'Accident Handling Measures',
        HandlingandStorage: 'Processing And Storage',
        ExposureControlandPersonalProtection: 'Exposure Control And Personal Protection',
        StabilityandReactivity: 'Stability And Reactivity',
        TransportInformation: 'Transshipment Information',
        OtherSafetyInformation: 'Other Security Information',

        ToxicologicalInformation: 'Toxicological Information',
        EcologicalInformation: 'Eco-information',
        ChemicalReactionFormula: 'Chemical Reaction Formula',
        Details: 'Details',
        PhysicalDescription: 'Physical Description',
        Source: 'Source',
        Description: 'Description',
        LicenseNote: 'License Description',
        LicenseURL: 'License Link',
        Colour: 'Color',
        Odor: 'Odour',
        BoilingPoint: 'Boiling Point',
        MeltingPoint: 'Melting Point',
        FlashPoint: 'Ignition Point',
        Solubility: 'Solubility',
        Density: 'Density',
        VaporPressure: 'Vapor Pressure',
        LogP: 'Hydrophobic Coefficient',
        HenrysLawConstant: 'Henrys LawConstant',
        StabilityShelfLife: 'Stability/Shelf Life',
        Decomposition: 'Decomposition',
        DissociationConstants: 'Dissociation Constant',
        CollisionCrossSection: 'Collision Cross Section',
        KovatsRetentionIndex: 'Kofaz Retention Index',
        OtherExperimentalProperties: 'Other Experimental Properties',
        ChemicalClasses: 'Chemotaxonomy',
        UserExplain: 'Description Of Use',
        UsageClassification: 'Use Classification Instructions',
        HazardClassesandCategories: 'Hazard Categories And Classification',
        HealthHazards: 'Health Hazards',
        HazardsSummary: 'Hazards Summary',
        Flammability: 'Flammability',
        SkinEyeandRespiratoryIrritations: 'Skin, Eye And Respiratory Irritation',
        FlammableLimits: 'Flammability Limit',
        PhysicalDangers: 'Physical Hazards',
        OSHAStandards: 'OSHA Standards',
        NIOSHRecommendations: 'NIOSH Recommendations',
        FirstAid: 'First-aid',
        IngestionFirstAid: 'Inhalation First Aid Measures',
        SkinFirstAid: 'Skin Contact First Aid',
        EyeFirstAid: 'Eye Contact First Aid',
        InhalationFirstAid: 'Intake First Aid',
        IsolationandEvacuation: 'Isolation And Evacuation',
        SpillageDisposal: 'Spillage Disposal',
        PreventiveMeasures: 'Preventive Measures',
        DisposalMethods: 'Disposal Methods',
        NonfireSpillResponse: 'Non-fire Leakage Response',
        SafeStorage: 'Safe Storage',
        StorageConditions: 'Storage Conditions',
        RecommendedExposureLimit: 'Suggested Exposure Limit',
        PermissibleExposureLimit: 'Allowable Exposure Limit',
        ImmediatelyDangeroustoLifeorHealth: 'There is a direct danger to life or health',
        ThresholdLimitValues: 'Threshold Limit Value',
        InhalationRisk: 'Inhalation Risk',
        EffectsofShortTermExposure: 'Effects Of Short-term Exposure',
        EffectsofLongTermExposure: 'Effects Of Long-term Exposure',
        PersonalProtectiveEquipment: 'Individual Protective Equipment',
        RespiratorRecommendations: 'Protection Suggestion',
        FirePrevention: 'Fire Prevention',
        ExposurePrevention: 'Exposure Prevention',
        InhalationPrevention: 'Inhalation Prevention',
        SkinPrevention: 'Skin Prevention',
        EyePrevention: 'Eye Prevention',
        IngestionPrevention: 'Ingestion Prevention',
        AirandWaterReactions: 'Air And Water Reactions',
        ReactiveGroup: 'Reactive Group',
        ReactivityProfile: 'Overview Of Reactivity',
        HazardousReactivitiesandIncompatibilities: 'Dangerous Reactions and Incompatibility',
        DOTLabel: 'DOT Label',
        SpecialReports: 'Special Reports',
        ToxicitySummary: 'Toxicity Summary',
        Hepatotoxicity: 'Hepato Toxicity',
        DrugInducedLiverInjury: 'Drug-induced Liver Injury',
        ExposureRoutes: 'Contact Ways',
        Symptoms: 'Symptom',
        InhalationSymptoms: 'Inhalation Symptoms',
        SkinSymptoms: 'Skin Symptoms',
        EyeSymptoms: 'Eye Symptoms',
        IngestionSymptoms: 'Ingestion Symptoms',
        TargetOrgans: 'Target Organs',
        Interactions: 'Interactions',
        AntidoteandEmergencyTreatment: 'Detoxification and Emergency Treatment',
        HumanToxicityExcerpts: 'Extracts Of Human Toxicity',
        NonHumanToxicityExcerpts: 'Non-human Toxicity Excerpts',
        HumanToxicityValues: 'Human Toxicity Value',
        NonHumanToxicityValues: 'Non-human Toxicity Value',
        EcotoxicityValues: 'Ecotoxicity Value',
        EcotoxicityExcerpts: 'Ecotoxicity Excerpts',
        EnvironmentalFate: 'Environmental Fate',
        ArtificialPollutionSources: 'Man-made Pollution',
        EnvironmentalBiodegradation: 'Environmental Biodegradation',
        EnvironmentalAbioticDegradation: 'Environmental Non-biodegradation',
        EnvironmentalBioconcentration: 'Environmental Biological Enrichment',
        SoilAdsorptionMobility: 'Soil Adsorption/Migration',
        VolatilizationfromWaterSoil: 'Water/Soil Volatilization',
        EnvironmentalWaterConcentrations: 'Environmental Water Concentration',
        EffluentConcentrations: 'Effluent Concentrations',
        MilkConcentrations: 'Breast Milk Concentration',
        ProbableRoutesofHumanExposure: 'Possible Human Exposure Pathways',
        BodyBurden: 'Physical Burden',
        MolecularFramework: 'Molecular Structure',
        Substituents: 'Substituents',
        classificationTree: 'Classification Tree',
        FDAOrangeBook: 'FDA orange book',
        StickModel: 'Stick Model',
        SphereModel: 'Sphere Model',
        AnimationEffect: 'Animation Effect',
        ShowingHydrogenBonds: 'Showing Hydrogen Bonds',
        CRbtn: 'View details',
        tradename: 'Trade Name',
        applicant: 'Applicant',
        chemicalReaction: 'Chemical Reaction',
      },
      substance: {
        Summary: 'Summary',
        Struct2D: 'Struct2D',
        Code: 'Code',
        Records: 'Records',
      },
      reaction: {
        basicInfo: 'Basic Information',
        BasicInformation: 'Basic Information',
        References: 'References',
        ConditionOfReaction: 'Condition of reaction',
        REACTANT: 'Reactant',
        SOLVENT: 'Solvent',
        REAGENT: 'Reagent',
        CATALYST: 'Catalyst',
        MICROLITER: 'μL',
        MILLILITER: 'mL',
        LITER: 'L',
        MILLIGRAM: 'mg',
        GRAM: 'g',
        KILOGRAM: 'kg',
        MOLE: 'mol',
        MILLIMOLE: 'mmol',
        Product: 'Product',
        temperature: 'Temperature',
        stirring: 'Stirring',
        other: 'Other',
        ProcedureDetails: 'Procedure Details',
        ChemicalReactionQuery: 'Chemical Reaction',
        ChemicalReactionDetails: 'Detail',
      },
      ADMET: {
        BaseInfo: {
          Name: 'Name:',
          Structure: 'Structure:',
          ChemicalFormula: 'Formula:',
          MolecularWeight: 'Molecular Weight:',
        },
        compoundInfo: 'Basic information of compounds',
        absorption: 'Absorption(A)',
        distribution: 'Distribution(D)',
        metabolism: 'Metabolism(M)',
        excretion: 'Excretion(E)',
        Macrotoxicity: 'Macrotoxicity(T)',
        Microtoxicity: 'Microtoxicity(T)',
        title1:
          'Caco-2 cells permeability is widely used to predict the absorption of oral drugs.The result is a real number in the range from 0 to 1. The closer the result is to 1, the higher the probability that the compound has a high Caco-2 permeability.',
        title2: 'The percentage of the compound absorbed by the human intestine.',
        title3:
          'Oral bioavailability refers to the fraction of a drug that reaches the systemic circulation. The result is a real number in the range from 0 to 1. The closer the result is to 1, the higher the probability that the oral bioavailability of the compound is greater than 30%. ',
        title4:
          'P-glycoprotein pumps substance out of the cell.  If a compound is a p-glycoprotein inhibitor, it prevents p- glycoprotein from transporting other compounds out of the cell, thereby increasing its intracellular concentration. The result is a real number in the range from 0 to 1. The closer the result is to 1, the higher the probability that the compound is an inhibitor of p-glycoprotein.',
        title5:
          'P-glycoprotein pumps substance out of the cell. If a compound is a p-glycoprotein substrate, it is easily exported out of the cell, thereby a lower intracellular concentration. The result is a real number in the range from 0 to 1. The closer the result is to 1, the higher the probability that the compound is a substrate of p-glycoprotein.',
        prob: 'prob',
        Index: 'Index',
        Name: 'Name',
        Value: 'Value',
        n1: 'Caco-2 permeability',
        n2: 'Human intestinal absorption',
        n3: 'Oral Availability',
        n4: 'P-glycoprotein inhibitor',
        n5: 'P-glycoprotein substrate',
        title6:
          'Determines whether a compound can enter the brain region. The result is a real number in the range from 0 to 1. The closer the result is to 1, the higher the probability that the compound can pass the blood-brain barrier.',
        title7: 'The fraction of a compound that attaches to the plasm proteins.  A high PPB implies a higher distribution of the drug in the blood, rather than in other tissue.',
        n6: 'Blood-brain-barrier permeant',
        n7: 'Plasma protein binding',
        title8:
          'Cytochromes P450 (CYPs) are the major enzymes that play a role in drug metabolism. If a compound is a CYP inhibitor (AC50<10μM), it may increase the concentration of other drugs, result in Drug-drug interaction (DDI)-mediated toxicity. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound is an inhibitor of the indicated CYP. ',
        title9:
          'Cytochromes P450 (CYPs) are the major enzymes that play a role in drug metabolism. If a compound is the substrate of CYPs, it might be oxidized by the enzymes, result in inactive and/or toxic products. Meanwhile, activities of these compounds can be affected by other food/drugs containing CYP inhibitors. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound is a substrate of the indicated CYP. ',
        M_n1: 'CYP1A2 inhibitor',
        M_n2: 'CYP1A2 substrate',
        M_n3: 'CYP2C19 inhibitor',
        M_n4: 'CYP2C19 substrate',
        M_n5: 'CYP2C9 inhibitor',
        M_n6: 'CYP2C9 substrate',
        M_n7: 'CYP2D6 inhibitor',
        M_n8: 'CYP2D6 substrate',
        M_n9: 'CYP3A4 inhibitor',
        M_n10: 'CYP3A4 substrate',
        title10:
          'Activation of which may adversely affect a wide range of cell progression and function, and can lead to immunotoxicity. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will affect the indicated pathway.',
        title11: 'Activation of which may trigger several diseases, such as prostate cancer.',
        title12:
          'Inhibition of which may lead to imbalance of androgen and estrogen. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will affect the indicated pathway.',
        title13:
          'Activation of which may trigger several siseases, such as breast cancer. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will affect the indicated pathway.',
        title14:
          'Activation of which may lead to weight gain, bone loss，congestive heart failure and etc. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will affect the indicated pathway.',
        title15:
          'Activated by oxidative stress. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will induce the indicated pathway.',
        title16:
          'Activated by DNA damage. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will induce the indicated pathway.',
        title17:
          'Activated by heat shorcks, leads to protein unfolding and affect cellular activities. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will induce the indicated pathway.',
        title18:
          'Disruption of which affects aerobic respiration in the cells. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will induce the indicated pathway.',
        title19:
          'Activated by stress conditions such as DNA damage. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will induce the indicated pathway.',
        microTn1: 'Aryl hydrocarbon receptor (AhR) activation',
        microTn2: 'Androgen (AR) receptor activation',
        microTn3: 'Aromatase (CYP19) inhibition',
        microTn4: 'Estrogen (ER) receptor activation',
        microTn5: 'PPAR-γ activation',
        microTn6: 'Antioxidant response element activation',
        microTn7: 'ATAD5 activation',
        microTn8: 'HSE activation',
        microTn9: 'Mitochondrion membrane potential disturbance',
        microTn10: 'p53 activation',
        Excrete: 'Half life',
        ExcreteTitle:
          'Half-life is the time (h) required for a compound to reach one-half its initial concentration in the body. A short half-life (<3h) usually indicates a narrow therapeutic window, high toxicity, and more frequent dosing. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound has a half-life greater than 3h. ',
        MacroToxicity: {
          Rodent: 'Acute oral toxicity on rodents',
          Human: 'Acute oral toxicity on human',
          Carcinogenicity: 'Carcinogenicity',
          Ames: 'Ames mutagenicity',
          Heptox: 'Hepatotoxicity',
          RodentTitle:
            'LD50 refers to dose that, when administered in an acute toxicity test on rodents, is expected to cause death in 50% of the treated animals in a given period. It is usually used to measure the toxicity of a compound. A compound with LD50<500mg/kg is considered moderate to extremely high toxic to rodents. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound exhibits moderate to extremely high toxicity (<500mg/kg) in rodents.',
          HumanTitle:
            'TDLo is the lowest dose causing a toxic effect in acute oral toxicity test conducted in human. The lower the value, the higher the toxicity. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound exhibits moderate to extremely high toxicity (<500mg/kg) in human. ',
          CarcinogenicityTitle:
            'Carcinogenicity indicates whether a compound may induce cancer in experimental animals. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will induce cancer.',
          AmesTitle:
            'Ames mutagenicity is correlated to the carcinogenicity of a compound and can be used as a preliminary test for drug carcinogenicity. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will exhibit Ames mutagenicity.',
          HeptoxTitle:
            'Hepatotoxicity indicates whether a compound can cause liver damages including morphology changes or loss of function. The result is a real number in the range from 0 to 1. The closer the value is to 1, the higher the probability that the compound will cause hepatotoxicity.',
        },
        ADMETP: 'ADMET Prediction',
        Forecastdetails: 'Forecast Details',
      },
    },
    DataStatistic: {
      HeadTitle1: 'Line chart of compound database data',
      HeadTitle2: 'Diagram of compound classification',
      HeadTitle3: 'Compound database entry situation diagram',
      TitleText1: 'The following shows the line chart of YYHOME compound database data change by year and month. You can switch different views to select specific year and month.',
      TitleText2:
        'The figure below is a representation of the compound classification hierarchy in the YYHOME Compound database. Click on a section to expand it, and then click the center button to return to the previous level.',
      TitleText3: 'The following figure shows the entry and proportion of data in the YYHOME compound database.',
      Line: {
        TabText: 'Switch views：',
        Radio1: 'Year',
        Radio2: 'Month',
        Radio3: 'Day',
        Tip: 'Pick one or more years',
        To: 'To',
        CompoundNum: 'Number of compound',
        ChemicalReactionNum: 'Number of chemical reactions',
        // OrganicMatterNum: 'Organic Number',
        // InorganicNum: 'Inorganic Number',
      },
      BarAndPie: {
        PieHeadTitle: 'Sector chart of YYHOME compound database data entry',
        BarHeadTitle: 'Bar chart of YYHOME compound database data entry',
        UpTo: 'Up to ',
        Year: '-',
        Month: '',
        Compound: 'Compound',
        ChemicalReaction: 'Chemical Reaction',
        // OrganicMatter: "Organic Matter",
        // Inorganic: "Inorganic Matter",
        Unit: 'Unit: piece',
      },
    },
    //靶点预测
    TargetPrediction: {
      HeadTitle: 'Target Prediction',
      CompoundUpload: 'Compound Upload',
      TargetPredictionResults: 'Target Prediction Results',
      UploadTitle:
        'Here you can predict the likely target of a compound (assuming it is biologically active) based on a combination of two-dimensional and three-dimensional similarities, as well as more than 560,000 proteins from YYHome',
      UploadTip: 'If Smiles and the structural formula exist at the same time, the Smiles is the main one',
      Radio: {
        RadioText: 'Please select species',
        Radio1: 'Human(Homo sapiens)',
        Radio2: 'Mouse(Mus musculus)',
        Radio3: 'Rat(Rattus norvegicus)',
        Radio4: 'Pig(Sus scrofa)',
        Radio5: 'Cow(Bos taurus)',
        Radio6: 'Other(Other)',
      },
      InputTip: 'Paste a SMILES in this box, or draw the compound structure in the right drawing box',
      InputText: 'Please input or paste a SMILES...',
      EMailInput: 'Send structure to email',
      EMailInputText: 'Please input your email number',
      WarningText1: 'Email format is incorrect, please re-enter',
      WarningText2: 'Parameters are missing, please enter Smiles or draw chemical structure formula',
      WarningText3: 'The currently entered Smiles error, please re-enter',
      ResetBtn: 'Reset',
      SubmitBtn: 'Start Prediction',
      Dialog: {
        HeadTitle: 'Waiting for results',
        LoadingText1: 'In the calculation, the prediction time is relatively long, please wait patiently',
        LoadingText2: 'In the calculation, the prediction time is relatively long, please wait patiently! Or you can input your email and it will be sent to you if you succeed.',
        ConfirmBtn: 'OK',
        CancelBtn: 'Cancel',
        BackBtn: 'Back',
        CancelReqBtn: 'Cancel Request',
        BtnText1: 'Do you confirm whether you will cancel this data request?',
        BtnText2: 'Do you confirm whether you will cancel this data request? And input the email!',
      },
      Result: {
        NoData: 'There is currently no data requested',
        ComStructure: 'Structure of compound',
        DownloadRes: 'Download Results',
        Target: 'Target',
        Possibility: 'Affinity',
      },
    },
    //元素周期表
    PeriodicTable: {
      HeadTitle: 'Periodic Table of Elements',
      Tip: 'Description: Color classification according to element state (one color for each type)',
      Mnemonics: 'Periodic table memorization',
      PinyinMnemonics: 'Pinyin mnemonics',
      MnemonicsOfAncientPoetry: 'Mnemonics of ancient poetry',
      IntroduceYourselfToMnemonics: 'Introduce yourself to mnemonics',
      Select: {
        HeadTitle: 'DISPLAY PROPERTY/TREND',
        Label1: 'Chemical Group Block',
        Label2: 'Atomic Mass,u',
        Label3: 'Standard State',
        Label4: 'Electron Configuration',
        Label5: 'Oxidation States',
        Label6: 'Electronegativity(Pauling Scale)',
        Label7: 'Atomic Radius(van der Waals),pm',
        Label8: 'Ionization Energy,eV',
        Label9: 'Electron Affinity,eV',
        Label10: 'Melting Point,K',
        Label11: 'Boiling Point,K',
        Label12: 'Density,g/cm³',
        Label13: 'Year Discovered',
        Label14: 'None',
      },
      Details: {
        label1: 'Atomic Mass',
        label2: 'Standard State',
        label3: 'Electron Configuration',
        label4: 'Oxidation States',
        label5: 'Electronegativity(Pauling Scale)',
        label6: 'Atomic Radius(van der Waals)',
        label7: 'Ionization Energy',
        label8: 'Electron Affinity',
        label9: 'Melting Point',
        label10: 'Boiling Point',
        label11: 'Density',
        label12: 'Year Discovered',
      },
    },
    // Admet页面预测
    AP: { ...lang.enAdmet },
    // 逆合成页面预测
    IS: { ...lang.enInverseSynthesis },
    // 靶点预测
    TP: { ...lang.enTargetPrediction },
    // 结构绘制
    SD: { ...lang.enStructDraw },
    // 相似化合物搜索
    SC: { ...lang.enSimilarCompound },
    // 元素周期表
    PT: { ...lang.enPeriodicTable },
  },
}
