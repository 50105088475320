import lang from '@/lang/gene'

export default {
  gene: {
    biosample: '生物样本',
    bioproject: '生物项目',
    nucleotide: '核苷酸',
    nucleotideSequence: '核苷酸序列',
    clinvar: '表型与变异',
    nlp: 'NLP小鼠表达式',
    gene: '基因',
    title: '基因科技改变世界',
    title2: '物竞天择，适者生存',
    text1:
      '建立有2019新冠病毒库、生物项目数据库、生物样本数据库、基因数据库、变异数据库、表型数据库、变异与表型关联数据库等，可以进行数据的上传/下载，序列比对分析，引物设计，外显子查找，识别基因开放阅读框等，整合科研、技术、市场、咨源优势，覆盖贯穿多组学多技术平台，赋能基因技术突破性变革，完善基因组学应用全产业链条，打造成为全球基因数据科学服务行业的领导者。',
    Blast: '局部序列排比搜索基本工具（BLAST）',
    Blast1: '局部序列排比搜索基本工具',
    SearchBox: {
      Label1: '全部',
      Label2: '基因',
      Label3: '生物项目库',
      Label4: '生物样本库',
      Label5: '核苷酸序列库',
      Label6: '表型与变异关联库',
      Label7: 'NLP小鼠表达库',
    },
    search: {
      gene: {
        organism: '物种名称',
        alsoKnownAsName: '基因别称',
        englishName: '基因英文名称',
        chineseName: '基因中文名称',
        OfficialSymbol: '官方符号',
        SequenceMatching: 'BLAST序列比对',
      },
      biosample: {
        organism: '物种名称',
        sampleType: '样本类型',
        submissionData: '发布日期',
        SampleNumber: '样本编号',
        belongPorjuct: '所属项目',
      },
      nucleotide: {
        organism: '物种名称',
        AccessionId: 'Accession编号',
        length: '序列长度',
        yyMolType: '分子类别',
        GraphicVisualization: '图形可视化',
      },
      clinvar: {
        proteinChange: '蛋白质变化',
        accession: 'Accession编号',
        chromosomeNumber: '染色体号',
        relatedGene: '相关基因',
        interpretation: '临床意义',
        genomicLocation: '具体定位',
        reviewStatus: '审核状态',
      },
      bioproject: {
        name: '生物项目名称',
        organismName: '物种名称',
        dataType: '数据类型',
        revelance: '涉及领域',
        sampleRange: '样本范围',
      },
      nlp: {
        quotation: '引文',
        belongSpecies: '所属物种',
      },
    },
    details: {
      gene: {
        Summary: {
          Species: '所属物种',
          GeneAliases: '基因别名',
          GeneType: '基因类型',
          expression: '表达',
          CodingForProteins: '编码蛋白质',
        },
        source: '来源',
        externalId: '外部ID',
        classify: '分类',
        geneSummary: '基因摘要',
        summarize: '概述',
        description: '描述',
        BasicInformation: '基础信息',
        GenomicRegions: '基因组区域、转录物和产物',
        GenomicBackground: '基因组背景',
        phenotype: '表型',
        InteractsWithHIV: '与HIV-1相互作用',
        geneExpression: '基因表达',
        expression: '表达',
        reference: '参考书目',
        PubChemApproach: 'PubChem途径',
        approachTitle: '途径标题',
        interaction: '互作',
        Profile: '基因概要',
        GeneName: '基因名称',
        NCBIRefSeq: 'NCBI 参考序列',
        correlationSequences: '相关序列',
        BiologyName: '物种名称',
        relevantContent: '相关内容',
        Bloodlines: '谱系',
        CodeProteinInfo: '编码蛋白质信息',
        PreferredName: '首选名称',
        CommonName: '常用名称',
        LoginNumber: '登录号',
        MainSource: '主要来源',
        GeneType: '基因类型',
        RelatedContent: '查看相关内容',
        SequenceStatus: '参考序列状态',
        ExpressPart: '基因表达部位',
        CorrelationSequence: '相关序列',
        Protein: '蛋白质',
        locatingInformation: '定位信息',
        NumberOfExons: '外显子数量',
        chineseName: '基因中文名称',
        englishName: '基因英文名称',
        alsoKnownAsName: '基因别称',
        projectTitle: '项目标题',
        projectDescription: '项目描述',
        projectID: '项目ID',
        publication: '出版物',
        analysisDate: '分析日期',
        viewMore: '查看更多',
        GeneChineseName: '基因中文名称',
        GeneEnglishName: '基因英文名称',
        GeneAlsoKnownAsName: '基因别称',
        Title: '标题',
        ProteinCode: '蛋白质编码',
        product: '产物',
        interactant: '相互作用物',
        otherGene: '其他基因',
      },
      nucleotide: {
        NucleotideName: '核苷酸序列摘要',
        BasicInformation: '基础信息',
        Position: '定位',
        Definition: '定义',
        Accession: 'Accession编号',
        Version: 'Version编号',
        DatabaseLink: '数据库链接',
        Keyword: '关键字',
        Source: '来源',
        ScientificName: '物种名称',
        Reference: '参考',
        Annotation: '注释信息',
        Characteristic: '特征',
        Blast: '源序列',
        length: '序列长度',
        MolecularCategory: '分子类别',
        GeneTopologyType: '基因拓扑类型',
        articletitles: '文章标题',
        author: '作者',
        HightStruct: '核苷酸序列高级结构',
        periodical: '期刊',
        remark: '备注',
      },
      PhenotypeMutation: {
        Abstract: '摘要',
        baseInformation: '基础信息',
        detail: '变体详细信息',
        condition: '条件',
        relatedGene: '相关信息',
        variationAndLocation: '变异和位置信息',
        Paraphrase: '释义',
        AuditStatus: '审核状态',
        SubmitInformation: '提交资料',
        FirstSubmissionToPhenotypeAndVariantTime: '首次提交时间',
        TimeOfLastSubmission: '最近一次提交时间',
        TimeOfLASTAssessment: '上一次评估时间',
        AccessionNumber: 'Accession编号',
        NumberOfVariants: '变异编号',
        SpecificDescription: '具体描述',
        alleleId: 'Allele ID(等位基因编号)',
        variantType: '变体类型',
        variantLength: '变体长度',
        location: '细胞遗传学位置',
        genomicLocation: '基因组定位',
        HGVSPerformance: 'HGVS表现',
        NucleotideSequence: '核苷酸序列',
        protein: '蛋白质',
        CorollaryOfMolecules: '分子推论',
        proteinChange: '蛋白质改变',
        otherNames: '变异体别称',
        canonicalSpdi: 'SPDI准则',
        functionalConsequence: '功能性结果',
        frequency: 'GMAF频率',
        alleleFrequency: '等位基因频率',
        link: '相关数据库的链接',
        ConditionsOfInterpretation: '解读条件',
        NumberOfSubmissions: '提交数目',
        StatusOfReview: '审查状态',
        VariationOrConditionRecording: '变异/条件记录',
        Gene: '基因',
        ClinGenGeneDoseSensitivityRanking: 'ClinGen基因剂量敏感度排序',
        HaploidFunctionalScores: '单倍体功能分数',
        ThreeTimesSensitivity: '三倍敏感度',
        VariantViewer: '变体查看器',
        VariantsOfInterest: '相关变体',
        InternalGenes: '内部基因',
        AllGenes: '全部基因',
      },
      bioSample: {
        sampleCode: '样本编号',
        BiologyName: '物种名称',
        SampleType: '样本模型',
        descriptionInfo: '描述信息',
        sampleType: '样本类型',
        sampleProperties: '样本属性',
        releaseDate: '发布日期',
        BioProject: '生物项目',
        Submittingunit: '提交单位',
        BaseInfo: '基础信息',
      },
      bioProject: {
        projectCode: '项目编号',
        AreasInvolved: '涉及领域',
        dataType: '数据类型',
        sampleRange: '样本范围',
        scientificName: '物种名称',
        descriptionInfo: '描述信息',
        publicationInfo: '出版信息',
        TrackLabelPrefix: '轨迹标签前缀',
        ArticleTitle: '文章标题',
        MagazineName: '杂志名称',
        AuthorName: '作者名称',
        Yearofpublication: '发表年份',
      },
      nlp:{
        
      }
    },
    resetMap: {
      click: 'Click',
      reset: 'Reset',
    },
    DataStatistic: {
      DataOverview: '数据概览',
      genedosage: '基因数量',
      Lines: {
        gene: '单条基因数量',
        nucleotide: '序列数量',
        clinvar: '变体数量',
        bioProject: '项目数量',
        bioSample: '样本数量',
        nlp: 'NLP小鼠表达数量',
      },
      biologicalsample: '生物样本',
      Biologicalprojects: '生物项目',
      Phenotypeandvariation: '表型与变异',
      nucleotide: '核苷酸',
      title1: '基因子库数量折线图',
      title2: '基因子库收录数据量折线图',
      time: '时间',
      title3: '收录数量/条',
      title4: '基因库数量折线图',
      content1: '下图展示了各年月YYHOME数据库中基因数据的变化折线图，可切换不同视图。',
      title5: '核苷酸序列库数量折线图',
      content2: '下图展示了各年月YYHOME数据库中核苷酸序列数据的变化折线图，可切换不同视图。',
      title6: '表型与变异关联库数量折线图',
      content3: '下图展示了各年月YYHOME数据库中表型与变异关联数据的变化折线图，可切换不同视图。',
      title7: '生物项目库数量折线图',
      content4: '下图展示了各年月YYHOME数据库中生物项目数据的变化折线图，可切换不同视图。',
      title8: '生物样本库数量折线图',
      content5: '下图展示了各年月YYHOME数据库中生物样本数据的变化折线图，可切换不同视图。',
      tabTitle1: '基因库数据量统计',
      tabTitle2: '核苷酸序列库数据量统计',
      tabTitle3: '表型与变异关联库数据量统计',
      tabTitle4: '生物项目库数据量统计',
      tabTitle5: '生物样本库数据量统计',
      tabTitle6: 'NLP小鼠表达库数据量统计',
      data: '数据',
      tableTitle1: '基因子库数据版本号统计',
      verName: '版本名称',
      verNumber: '版本号',
      onlinetime: '上线时间',
    },
    // 限制性酶切位点分析工具
    ResetMap: lang.zhResetMap,
    NLPDetail: lang.zhNLPDetail,
    // ORF finder
    OF: lang.zhOrfFinder,
  },
}
