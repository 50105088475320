// 中文
const zhBaseInfo = {
  compound: '化合物',
  chemicalReaction: '化学反应',
  protein: '蛋白质',
  humanDiseases: '人类疾病',
  gene: '基因',
  bioproject: '生物库项目',
  biosample: '生物样本库',
  nucleotide: '核苷酸序列库',
  nature: '天然产物库',
  bioassay: '生物测定',
  plant: '药用植物',
  food: '食用植物',
  cell: '细胞系',
  biosample_list: '生物样本',
  gene_list: '基因',
  bioproject_list: '生物项目',
  target: '靶点信息',
  nucleotide_list: '核苷酸序列库',
  clinvar_list: '表现与变异关联库',
  SearchHistory: '历史搜索记录',
  DownLoad: '下载',
  Upload: '上传',
  KnowledgeAtlas: '知识图谱',
  AISpecialistLifeChemistry: 'AI 生命化学专家',
  RemoveAllHistory: '删除全部历史记录',
  NoHistory: '暂无搜索历史记录',
  Other: '其他',
  Tool: '工具库',
  KnowledgeGraph: '知识图谱',
}
const enBaseInfo = {
  compound: 'Compound',
  chemicalReaction: 'Chemical Reaction',
  protein: 'Protein',
  humanDiseases: 'Human Diseases',
  gene: 'Gene',
  bioproject: 'Bioassay',
  biosample: 'Biological Project',
  nucleotide: 'Nucleotide',
  nature: 'Nature',
  bioassay: 'Bioassay',
  plant: 'Plant',
  food: 'Food',
  cell: 'Cell Line',
  gene_list: 'Gene',
  biosample_list: 'Biological Sample',
  bioproject_list: 'Biological Project',
  target: 'target',
  nucleotide_list: 'Nucleotide',
  clinvar_list: 'Phenotype And Variation',
  Tool: 'Tool ',
  DownLoad: 'Download',
  KnowledgeGraph: 'KnowledgeGraph',
  Upload: 'Upload',
  AISpecialistLifeChemistry: 'AI Specialist Life Chemistry',
  Other: 'Other',
}

export const zhSearch = { ...zhBaseInfo }
export const enSearch = { ...enBaseInfo }
