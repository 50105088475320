import RouteNames from '@/consts/RouteNames'
import { RouteRecordRaw } from 'vue-router'
import GYCompound from './GY/compound'
export const CompoundRoutes: RouteRecordRaw[] = [
  {
    path: '/compound',
    name: RouteNames.Compound.Compound,
    component: () => import('@/views/Compound/layout/CompoundLayout.vue'),
    children: [
      {
        path: '',
        name: RouteNames.Compound.HomePage,
        component: () => import('@/views/Compound/Home/CompoundHome.vue'),
      },
      {
        path: 'aboutus', //关于我们页面
        name: RouteNames.Compound.AboutUsPage,
        component: () => import('@/views/Compound/AboutUs/AboutUs.vue'),
      },
      {
        path: 'search/:keyword',
        name: RouteNames.Compound.SearchPage,
        component: () => import('@/views/Compound/Search_Refactoring/compoundSearch_new.vue'),
      },
      {
        path: 'BatchSearch',
        name: RouteNames.Compound.BatchSearchPage,
        component: () => import('@/views/Compound/BatchSearch/CompoundBatchSearch.vue'),
      },
      {
        path: 'compound/:id',
        name: RouteNames.Compound.CompoundDetailsPage,
        component: () => import('@/views/Compound/Details/CompoundDetails/CompoundDetails.vue'),
      },
      {
        path: 'periodic',
        name: RouteNames.Compound.PeriodicTablePage,
        component: () => import('@/views/Compound/PeriodicTable/PeriodicTable.vue'),
      },
      {
        path: 'reaction/:id',
        name: RouteNames.Compound.ChemicalReactionPage,
        component: () => import('@/views/Compound/Details/ChemicalReactionDetails/ChemicalReactionDetails.vue'),
      },
      {
        path: 'dataStatistic',
        name: RouteNames.Compound.DataStatisticPage,
        component: () => import('@/views/Compound/DataStatisitc/CompoundDataStatisitc.vue'),
      },
      {
        path: 'ADMET',
        name: RouteNames.Compound.ADMETPage,
        component: () => import('@/views/Compound/Details/ADMETDetails/ADMETDetails.vue'),
      },
      ...GYCompound,
    ],
  },
]
