import { options } from './../components/BatchUpload/Options/options'
import RouteNames from '@/consts/RouteNames'
import { ElMessage } from 'element-plus'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'
export const NatureRoutes: RouteRecordRaw[] = [
  {
    path: '/nature',
    name: RouteNames.Nature.Nature,
    component: () => import('@/views/Nature/layout/NatureLayout.vue'),
    children: [
      {
        path: '',
        name: RouteNames.Nature.HomePage,
        component: () => import('@/views/Nature/Home/NatureHome.vue'),
      },
      {
        path: 'search/overview/:keyword',
        name: RouteNames.Nature.SearchOverviewPage,
        component: () => import('@/views/Nature/SearchOverview/NatureSearchOverview.vue'),
      },
      {
        path: 'aboutus',
        name: RouteNames.Nature.AboutUsPage, //关于我们
        component: () => import('@/views/Nature/AboutUs/AboutUs.vue'),
      },
      {
        path: 'taxonomy',
        name: RouteNames.Nature.Taxonomy,
        component: () => import('@/views/Nature/Taxonomy/Taxonomy.vue'),
      },
      {
        path: 'taxonomy/search/:keyword',
        name: RouteNames.Nature.TaxonomyList,
        component: () => import('@/views/Nature/Taxonomy/TaxonomyList/TaxonomyList.vue'),
      },
      // {
      //   path: 'search/:keyword',
      //   name: RouteNames.Nature.SearchPage,
      //   component: () => import('@/views/Nature/Search/NatureSearch.vue'),
      // },
      {
        path: 'search/:keyword',
        name: RouteNames.Nature.SearchPage2,
        component: () => import('@/views/Nature/Search/NatureSearch_new.vue'),
        /* beforeEnter: (to: any, from: any, next: any) => {
          // reject the navigation
          if (!from.params.keyword || !from.query.option) {
            next({
              to,
            })
          }
        }, */
      },
      {
        path: 'search3/:keyword/:option',
        name: 'NatureSearchPage3',
        component: () => import('@/views/Nature/Search/NatureSearch2.vue'),
        beforeEnter: (to: RouteLocation, from: RouteLocation, next: any) => {
          // reject the navigation
          const keyword = (to.params.keyword as string).trim() || ''
          const option = (to.params.option as string).trim() || ''
          const type = (to.hash.replace('#', '') as string).trim() || option

          if (!keyword.length || !option.length) {
            ElMessage.error('路径参数错误,请输入正确路径!')
            next({
              name: RouteNames.Generic.NotFoundPage,
            })
          } else if (!checkOption(option) || !checkType(option, type)) {
            ElMessage.error('路径参数错误,请输入正确路径!')
            next({
              name: RouteNames.Generic.NotFoundPage,
            })
          } else {
            next()
          }
        },
      },
      {
        path: 'BatchSearch',
        name: RouteNames.Nature.BatchSearchPage,
        component: () => import('@/views/Nature/BatchSearch/NatureBatchSearch.vue'),
      },
      {
        path: 'compound/:id',
        name: RouteNames.Nature.CompoundDetailsPage,
        component: () => import('@/views/Nature/Details/CompoundDetails/CompoundDetails.vue'),
      },
      {
        path: 'food/:id',
        name: RouteNames.Nature.FoodDetailsPage,
        component: () => import('@/views/Nature/Details/FoodDetails/FoodDetails.vue'),
      },
      {
        path: 'plant/:id',
        name: RouteNames.Nature.PlantDetailsPage,
        component: () => import('@/views/Nature/Details/PlantDetails/PlantDetails.vue'),
      },
      {
        path: 'bioassay/:id',
        name: RouteNames.Nature.BioassayDetails,
        component: () => import('@/views/Nature/Details/BioassayDetails/BioassayDetails.vue'),
      },
      {
        path: 'cellLines/:id',
        name: RouteNames.Nature.CellLinesDetails,
        component: () => import('@/views/Nature/Details/CellLineDetails/CellLinesDetails.vue'),
      },
      {
        path: 'browser',
        name: RouteNames.Nature.BrowserPage,
        component: () => import('@/views/Nature/Browser/NatureBrowser.vue'),
      },
      {
        path: 'dataStatistic',
        name: RouteNames.Nature.DataStatisticPage,
        component: () => import('@/views/Nature/DataStatistic/NatureDataStatistic.vue'),
      },
      {
        path: 'reaction/:id',
        name: RouteNames.Nature.ChemicalReactionPage,
        component: () => import('@/views/Compound/Details/ChemicalReactionDetails/ChemicalReactionDetails.vue'),
      },
    ],
  },
]

function checkOption(option: string) {
  const options = ['compound', 'plant', 'food', 'cell', 'bioassay']
  return options.findIndex((item) => item == option) != -1
}

function checkType(option: string, type: string) {
  if (option == 'compound') {
    const types = ['compound', 'bioassay', 'chemicalReaction', 'taxonomy']
    return types.findIndex((item) => item == type) != -1
  } else {
    return option == type
  }
}
