import { enResetMap, zhResetMap } from './reset-map'
import { zhNLPDetail, enNLPDetail } from './search/nlp/detail'
import { zhOrfFinder, enOrfFinder } from './orf-finder'
export default {
  zhResetMap,
  enResetMap,
  zhNLPDetail,
  enNLPDetail,
  zhOrfFinder,
  enOrfFinder,
}
