import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  Assignment: '提交任务',
  Result: '我的结果',
  Example: '例子',
  Clear: '清除',
  ChooseParameter: '选择参数',
  Use: '使用',
  Codon: '密码子',
  Target: '目标',
  Molecule: '分子',
  Output: '输出',
  Color: '颜色',
  Linear: '线性',
  Circular: '环形',
  blackAndWhite: '黑白',
  Reference: '引用',
  click: '点击',
  OutputForm: '输出形式',
}
const zhParagraphInfo = {
  tip01: '限制性酶切位点分析工具',
  tip02:
    '限制性酶切位点分析：同一DNA用不同的限制酶进行切割，从而获得各种限制酶的切割位点，由此建立的位点图谱有助于对DNA的结构进行分析。该功能以格式化文字的形式将限制性酶切位点标记在序列中。输出结果还会根据阅读框的位置，列出翻译后的蛋白序列。',
  tip03: '在下面的文本框中输入原始序列或者一条或者多条的FASTA序列。最大限制在10000条之内。',
  tip04: '注意：序列可为全部大写或者全部小写，但不能大小写存在于同一条序列中',
  tip05: '每行显示',
  tip06: '碱基',
  tip07: '显示翻译结果，阅读框位于',
  tip08: '限制性酶切位点分布图',
  tip09: '限制性酶切位点结果汇总',
  tip10: '请输入FASTA格式序列',
  tip11: '大写字符',
  tip12: 'Stothard P（2000）The Sequence Manipulation Suite： JavaScript programs for analyzing and formatting protein and DNA sequences．Biotechniques 28：1102-1104．',
  tip13: '注意：序列可为全部大写或全部小写，但不能大小写存在于同一序列中',
  click: '运行',
  reset: '重置',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)
enParagraphInfo.click = 'Run'
enParagraphInfo.reset = 'Reset'
enParagraphInfo.tip01 = 'Restriction site analysis tool'
enParagraphInfo.tip02 =
  'Restriction Map accepts a DNA sequence and returns a textual map showing the positions of restriction endonuclease cut sites. The translation of the DNA sequence is also given, in the reading frame you specify. Use the output of this program as a reference when planning cloning strategies. Restriction Map supports the entire IUPAC alphabet and several genetic codes.'
enParagraphInfo.tip03 = 'Enter the original sequence or one or more FASTA sequences in the text box below. Input limit is 100000 characters.'
enParagraphInfo.tip05 = 'Each line shows'
enParagraphInfo.tip06 = 'basic group'
enParagraphInfo.tip07 = 'The translation results are displayed with the reading box located'
enParagraphInfo.tip08 = 'Restriction Map result'
enParagraphInfo.tip09 = 'Restriction Summary'
enParagraphInfo.tip11 = 'uppercase text'
enParagraphInfo.tip12 =
  'Stothard P（2000）The Sequence Manipulation Suite： JavaScript programs for analyzing and formatting protein and DNA sequences．Biotechniques 28：1102-1104．'
enParagraphInfo.tip13 = 'Note: Sequences can be all uppercase or all lowercase, but no case can exist in the same sequence'

export const zhResetMap = { ...zhBaseInfo, ...zhParagraphInfo }
export const enResetMap = { ...enBaseInfo, ...enParagraphInfo }
