import { RouteRecordRaw } from 'vue-router'
import RouteNames from '@/consts/RouteNames'

import { useOrfFinder, useResetMap } from '@/store/gene'
// ORF finder
const OFIndex = () => import('@/views/Gene/orf-finder/index.vue')
const OFForm = () => import('@/views/Gene/orf-finder/view/form/index.vue')
const OFResult = () => import('@/views/Gene/orf-finder/view/result/index.vue')
// reset map 页面
const RSIndex = () => import('@/views/Gene/reset-map/index.vue')
const RSForm = () => import('@/views/Gene/reset-map/view/form/index.vue')
const RSResult = () => import('@/views/Gene/reset-map/view/result/index.vue')

export default [
  // NLP搜索详情
  {
    path: 'nlp/:id',
    meta: { marginBottom: true },
    name: 'NLPDetail',
    component: () => import('@/views/Gene/Details/NLP/index.vue'),
  },
  // orf 阅读框
  {
    path: 'orfFinder',
    component: OFIndex,
    redirect: '/gene/orfFinder/OF-Form',
    name: RouteNames.Gene.ORFFinder,
    meta: { marginBottom: true },
    children: [
      {
        path: 'OF-Form',
        component: OFForm,
        meta: {},
        beforeEnter: (to, from) => {
          // 证明是刷新，需要清空,不是从OF-Result进入来的也需要清空
          if (from.fullPath === '/' || from.name != 'OF-Result') {
            useOrfFinder().$reset()
          }
        },
      },
      {
        path: 'OF-Result',
        name: 'OF-Result',
        component: OFResult,
        meta: { showWaitHeader: true },
      },
    ],
  },
  // 限制性酶切位点分析工具页面
  {
    path: 'resetMap',
    name: RouteNames.Gene.ResetMap,
    component: RSIndex,
    redirect: '/gene/resetMap/RM-Form',
    meta: { marginBottom: true },
    children: [
      {
        path: 'RM-Form',
        name: 'RM-Form',
        component: RSForm,
        beforeEnter: (to, from) => {
          // 证明是刷新，需要清空,
          if (from.fullPath === '/' || from.name != 'RM-Result') {
            useResetMap().$reset()
          }
        },
      },
      {
        path: 'RM-Result',
        name: 'RM-Result',
        meta: { showWaitHeader: true },
        beforeEnter: (to, from, next) => {
          // 刷新回退到提交页面
          if (from.path === '/') {
            history.back() // 会额外增加一条历史记录，需要回退
            next({ name: 'RM-Form', replace: true })
            return
          }
          next()
        },
        component: RSResult,
      },
    ],
  },
] as RouteRecordRaw[]
