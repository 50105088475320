import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  PhysicochemicalPropertyAnalyze: '理化性质分析',
  OrganismAffiliation: '所属生物',
  ProteinName: '蛋白质名称',
  TotalAminoAcids: '氨基酸总数',
  SequenceFragment: '序列片段',
  SelectedSequenceFragment: '选中的序列片段',
  NumOfAminoAcids: '氨基酸数量',
  MolecularWeight: '分子量',
  Aromaticity: '芳香度',
  IsoelectricDegree: '等电度',
  PHCharge: '给定的PH的电荷',
  AminoAcidComposition: '氨基酸组成',
  FractionOfHelix: '螺旋分数',
  NumOfRevolutions: '转数',
  MolarExtinctionCoefficient: '摩尔消光系数',
  IndexOfInstability: '不稳定指数',
  NumOfPlates: '板数',
  TotalMeanHydrophilicity: '亲水性总平均值',
  reset: '重置',
  CalculationParameter: '计算参数',
  proteinName: '蛋白质名称',
  Totalaminoacids: '氨基酸总数',
  Proteomycin: '蛋白质霉素',
  fruitfly: '果蝇',
  NoData: '暂无数据',
}
const zhParagraphInfo = {
  tip01: '输入yyHome蛋白质ID（例如1426）',
  tip02: '请输入 YYHomePID',
  tip03: '或者，你可以在下面的输入框中黏贴自己的氨基酸序列',
  tip04: '请输入PH值',
  tip05: '以FASTA为格式的蛋白质或核苷酸序列，长度不小于5',
  tip06: '输入PH值',
  tip07: 'PH值为数字',
  tip08:
    '说明：yyHome理化性质分析工具可以计算存储在yyHome蛋白质数据库中给定的蛋白质Id或用户输入的蛋白质序列的各种物理和化学参数，计算参数包括标准氨基酸数量、分子量、带负电残基总数、带正电残基总数、氨基酸组成、方程式、原子总数、原子组成、消光系统、估计半衰期不稳定指数、脂肪指数、亲水性总平均值（GRAVY）',
  tip09: '蛋白质ID只能是数字',
  tip10: '该蛋白质在库中不存在！',
  tip11: '请输入蛋白质的序列',
  tip12: '蛋白质ID与序列只能存在一种',
  tip13: '蛋白质序列必须大于5',
  tip14: '此序列格式不符合，请重新输入',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)

enParagraphInfo.tip01 = 'Input yyHome Protein ID such as 1426'
enParagraphInfo.tip02 = 'Please Input yyHome Protein ID'
enParagraphInfo.tip03 = 'Or, you can paste your amino acid sequence in the input box'
enParagraphInfo.tip04 = 'Please Input PH value'
enParagraphInfo.tip05 = 'Protein or nucleic acid sequence in FASTA format, length not less than 5'
enParagraphInfo.tip06 = 'Input PH value'
enParagraphInfo.tip07 = 'Please Input PH value'
enParagraphInfo.tip08 =
  'Description : The yyHome physicochemical property analysis tool can calculate various physical and chemical parameters of a given protein Id or a user-entered protein sequence stored in the yyHome protein database, the calculation parameters include the number of standard amino acids, molecular weight, total number of negatively charged residues, total number of positively charged residues, amino acid composition, equation, total number of atoms, atomic composition, extinction system, estimated half-life instability index, fat index, total hydrophilicity average (GRAVY).'
enParagraphInfo.tip09 = 'Protein id can only be numbers'
enParagraphInfo.tip10 = 'This protein is not present in the database!'
enParagraphInfo.tip11 = 'Please input the sequence of the protein'
enParagraphInfo.tip12 = 'Only one protein ID and sequence can exist'
enParagraphInfo.tip13 = 'The protein sequence must be greater than 5'
enParagraphInfo.tip14 = 'This sequence format does not match, please re-enter'

export const zhPhysicochemicalProperty = { ...zhBaseInfo, ...zhParagraphInfo }
export const enPhysicochemicalProperty = { ...enBaseInfo, ...enParagraphInfo }
