import lang from '../protein'
export default {
  protein: {
    human_diseases: '人类疾病',
    go: 'GO注释',
    protein: '蛋白质',
    virtualScreening: '分子对接',
    molecularDockingTool: '基于web的分子对接工具',
    references: '文献',
    target: '靶点-化合物生物测定',
    total: '总数',
    HeadTitle: '开始您的蛋白质探索之旅',
    HeadText: '所有数据，专业分析，深度挖掘',
    LearnMore: '了解更多更新',
    AboutText:
      'yyHome蛋白质数据库是国际性的、创新的、跨学科的、并且是生命科学领域开放数据的拥护者。我们是yyHome战略数据平台（yyHome Strategic Data Platform）的一部分，该战略平台是由一支30人规模的研发团队组成，由宁波甬恒瑶瑶智能科技有限公司独家资助、运行和维护。我们位于中华人民共和国浙江省宁波市中官西路1277号启迪科技园。',
    LearnMoreBtn: '了解更多',
    HumanDiseasesSuggest: '人类疾病',
    proteinSuggest: '蛋白质',
    alpha: 'AlphaFold蛋白质结构预测',
    proteinAlphaFoldSuggest: 'AlphaFold蛋白质结构预测',
    AlphaFold: 'AlphaFold蛋白质结构预测',
    SearchBox: {
      Label1: '全部',
      Label2: '人类疾病',
      Label3: '蛋白质',
    },
    Statisitc: {
      Protein: '蛋白质',
      Target: '靶点',
      Protein3D: '蛋白3D结构',
      ProteinInteraction: '蛋白互作',
      HumanDisease: '人类疾病',
      HomologyIsomers: '同源异构体',
      DrugTargetInteraction: '药物靶点相互作用',
    },
    functionNav: {
      Batch: '批量查找',
      DataStatistic: '数据统计',
      Blast: '局部序列排比搜索基本工具（BLAST）',
      Msa: '多序列对比（MSA）',
      phylogenetictree: '系统发生树',
      AnalysisPhyPro: '理化性质分析',
    },
    search: {
      ProteinBaseInfo: '蛋白质基础信息',
      HumanDiseaseData: '人类疾病数据',
      TargetBaseInfo: '靶点基础信息',
      protein: {
        sequence: '蛋白质序列',
        createtime: '创建日期',
        keywords: '关键字',
        Type: '分类',
      },
      go: {
        sourceDatabase: '数据库来源',
        sourceDatabaseID: '数据库ID',
        term: '术语',
        index: '序号',
        aspect: '层面',
      },
      target: {
        ActivitiesCount: '生物活性',
        targetOrganism: '物种名称',
        CompoundsCount: '化合物数量',
        prefName: '靶点名称',
        baoLabel: '类型',
      },
      AlphaFold: {
        Organism: '源生物',
      },
    },
    details: {
      protein: {
        summary: '蛋白质摘要',
        name: '蛋白质名称',
        englishname: '蛋白质英文名',
        alternativenames: '蛋白质别名',
        id: '蛋白质序列号',
        pid: '蛋白质 Pid',
        uniprotid: '蛋白质 Uniprot id',
        sequencesandfeature: '分子序列及分子量',
        sequences: '一维氨基酸序列信息',
        sequencesfeature: '特征',
        locationandfeature: '位置',
        subcellularlocation: '蛋白质亚细胞定位',
        subcellularfeature: '特征',
        organisms: '分类',
        scientificName: '物种名称',
        organism: '分类谱系',
        functions: '功能',
        processingandfeature: '翻译后修饰',
        processing: '翻译后修饰',
        processingfeature: '特征',
        diseaseandvariants: '疾病&病原体',
        disease: '参与疾病',
        natural_variants: '突变',
        diseasefeature: '特征',
        interactionandSubunit: '相互作用',
        interaction: '二元互作',
        interactionSubunit: '亚基',
        family: '家族&结构域',
        familyDomainInformation: '结构域',
        familyDomainInformationSimilarity: '序列相似性',
        familyDomainFeature: '特征',
        expression: '表达',
        tissueSpecificity: '组织特异性',
        InductionOfFeeling: '感应',
        StageOfDevelopment: '发展阶段',
        type: '类型',
        loginNum1: '登录号1',
        loginNum2: '登录号2',
        testNum: '实验次数',
        intact: 'intact',
        sequence: '一维氨基酸序列',
        species: '分类',
        molweight: '分子量',
        recommendedname: '蛋白质全名',
        references: '文献',
        functionfeature: '特征',
        function: '功能',
        structure: '蛋白质3D结构',
        structureFeature: '特征',
        structure3D: '3D结构',
        notice: '注意',
        describe: '描述',
        Features: {
          Type: '类型',
          Location: '位置',
          Description: '描述',
        },
        Structure3D: {
          DataBase: '数据库',
          Identifier: '标识符',
          Function: '方法',
          Resolution: '分辨率',
          CHAIN: '链',
          Positions: '位置',
        },
        AminoAcidSequenceInformation: {
          Select: {
            highlight: '高亮显示',
            PolarAminoAcids: '极性氨基酸',
            HydrophobicAminoAcids: '疏水性氨基酸',
          },
          Copy: '复制序列',
          CopySuccess: '已复制到剪贴板上!',
          CopyError: '复制失败!',
          LengthOfSequence: '序列长度',
          LastUpdatedTime: '最近更新时间',
          MolarMass: '摩尔质量',
          CheckOut: '校验',
          Distinction: '与规范序列的区别：',
          BtnTextShow: '展开序列',
          BtnTextClose: '收起序列',
        },
        References: {
          ReferencesEngName: '文献英文名',
          AuthorName: '作者名称',
          QuoteFrom: '引用自',
          AcademicJournal: '学术期刊',
        },
        protein_loginNumber: '蛋白质登录号',
        yyhomeProteinId: 'yyhome蛋白质数据库登录号',
        uniprotId: 'Uniprot数据库登录号',
        BiologicalName: '生物学名',
        baseInfo: '基础信息',
        knowledge: '知识图谱',
        more: '检索更多知识图谱信息',
      },
      humanDiseases: {
        define: '定义',
        abbreviation: '缩写',
        alias: '别名',
        keyword: '关键词',
        crossReference: '交叉引用',
        disclaimers: '免责声明',
        correlationYYhomeProtein: '相关YYhome蛋白质',
        Protein: '蛋白质',
        EnglishName: '英文名',
        ScientificName: '物种名称',
        Length: '长度',
        Statement:
          '本条目中存在的任何医学或遗传信息仅用于研究、教育和信息目的、它不以任何方式用作专业医疗建设、诊断、治疗或护理的替代品。我们的员工由生物学家、生物化学家、化学家、计算机专家、算法专家组成，他们没有接受过提供医疗建议的培训。',
        HeadTitle: '疾病 - ',
        baseInfo: '基础信息',
      },
      proteinTarget: {
        NameAndClassification: '靶点摘要',
        BiologicalActivityVisualizationChart: '生物活性可视化图表',
        VisualizationChartOfLigandEfficiency: '配体效率可视化图表',
        DetailedExperimentalDataSheet: '详细实验数据表',
        Type: '类型：',
        PreferredName: '首选名称：',
        Synonyms: '同义词：',
        BiologicalName: '物种名称:',
        GroupsOfSpecies: '物种群：',
        ProteinTargetsClassification: '蛋白质靶点分类：',
        TotalProject: '总项目：',
        StandardValue: '标准值',
        Title: '配体效率图绘制了结合效率指数(BEI)与表面效率指数(SEI),其中:',
        NoData: '暂无数据',
        StructureDiagram: '结构图',
        StructuralFormula: '结构式',
        Activity: '活性',
        StandardType: '标准类型',
        StandardRelation: '标准关系',
        StandardUnit: '标准单位',
        target: '靶点-化合物生物测定',
      },
      AlphaFold: {
        AlphaFold: 'AlphaFold蛋白结构预测',
        baseInfo: '基础信息',
        references: '参考文献',
        cif: 'mmCIF 文件',
        Predicted: '预测对齐误差',
        protein: '蛋白质',
        gene: '基因',
        organism: '源生物',
        ProteinPredicted: '蛋白结构预测',
        error: '预测对齐误差教程',
        struct3D: '3D结构',
        struct3Dtool: '3D结构查看器',
      },
    },
    analysis: {
      DataUpload: '数据上传',
      AnalysisDetails: '分析详情',
      Uploadanewtree: '上传一个新树',
      note1: '注意：您可将树直接粘贴到树文本输入框中，或者使用树文件选择器上传一个文件。',
      treeName: '树名字(可选):',
      SingleLineInput: '单行输入',
      TreeText: '树文本:',
      MultiLineInput: '多行输入',
      treeFile: '树文件:',
      note2: '可直接将文件拉拽至此处，支持格式：newick、txt',
      Reset: '重置',
      Submit: '提交',
      Toolbar: '工具栏',
      Mode: '模式:',
      Round: '圆形',
      Rectangle: '矩形',
      BranchLength: '分支长度: ',
      Use: '使用',
      Ignore: '忽略',
      downloadExmaple: '下载示例',
      returnUpload: '返回数据上传',
      unrooted: '无根',
    },
    msa: {
      SubmitTask: '提交任务',
      MyMSATask: '我的MSA任务',
      SelectionSequence: '选择序列',
      FindProteinSequenceByYYHomePID: '通过YYHomePID找到一个蛋白质序列（例如：1426），以与Clustal Omega程序对齐。',
      pieces: '条',
      PasteAListOfIds: '还可以粘贴id列表，以英文逗号分隔（例如：1426,1427,1428）',
      PleaseEnterYYHomePID: '请输入YYHomePID或id列表...',
      OrEnterMultiple: '或   输入多个蛋白质或核苷酸序列，以FASTA标题分隔',
      SelectFile: '选择文件',
      ProteinOrNucleotide: '以FASTA为格式的蛋白质或核苷酸序列...',
      NameYourMSAJob: 'MSA任务命名',
      Unnamed: '未命名',
      OutputSequenceOrder: 'MSA输出排序',
      PleaseSelectOutputSort: '请选择输出排序',
      Iterations: '迭代次数',
      PleaseSelectIterations: '请选择迭代次数',
      Submit: '提交',
      Reset: '清除',
      FindYourMSATask: '查找您的MSA任务',
      YourMSAResultsAreKept: '您的MSA结果最多保留1周',
      PleaseEnterNameToQueryTheJob: '请输入名称查询作业...',
      Search: '搜索',
      Task: '任务',
      Name: '名称',
      SortBySimilarity: '根据相似性排序',
      SortByInputOrder: '根据输入顺序排序',
      Overview: '概述',
      Trees: '系统发生树',
      PercentIdentityMatrix: '百分比单位矩阵',
      TextOutput: '文本输出',
      InputParameters: '输入参数',
      APIRequest: 'API参数',
      Download: '下载',
      SubmitTo: '提交于',
      Status: '状态',
      ExpirationTime: '到期时间',
      Handle: '操作',
      ThisOperationIsNotRecoverable: '删除后不可恢复，确认删除吗？',
      Confirm: '确定',
      Cancel: '取消',
      WithoutDay: '无期限',
      SuccessfullyDelete: '删除成功',
      FailToDelete: '删除失败',
      FailedStatus: '失败状态，不可查看',
      RunningStatus: '运行状态，不可查看',
      PleaseSelectAtLeastSequences: '请至少选择3条序列',
      NoMoreThanPieces: '请勿超过300条',
      TheJobWasSubmittedWithTheFollowing: '作业已使用以下原始输入值提交，作业ID',
      MSAJobName: 'MSA 作业名',
      CommitSequence: '提交序列',
      UsingCurl: '使用curl，您可以通过运行以下命令在命令行上运行与此作业相同的输入的新作业',
      ResultingAlignmentInClustalFormat: '以clustal格式生成对齐',
      OriginalCommitSequence: '原始提交序列',
      PhylogeneticTreeInNewickNotation: 'Newick系统发生树',
      EnterTheSameSequence: '请勿输入相同序列',
      IncorrectFormat: '格式有误，请检查',
    },
    DataStatistic: {
      LineTitle: '蛋白质子库数量折线图',
      LineHeadText: '蛋白质子库收录数据量折线图',
      LineUnit: '收录数量/条',
      LineText: '数据量',
      VersionTitle: '蛋白质子库历史版号统计',
      VersionName: '版本名称',
      Version: '版本号',
      VersionOnlineDate: '上线时间',
      PieTitle: '蛋白质子库收录物种分类扇形图',
      PieHeadText: '蛋白质子库物种分类扇形图',
      LargeDataTitle: '蛋白质子库收录氨基酸序列长度统计',
      LargeDataHeadText: '蛋白质子库氨基酸长度分布图',
      LargeDataUnit: '序列长度/AA',
      BarTitle: '蛋白质子库收录氨基酸组成统计',
      BarHeadText: '蛋白质子库氨基酸组成占比柱状图',
      BarUnit: '占比/%',
      BarText: '氨基酸种类',
      TableTitle: '氨基酸种类表',
      ChineseName: '中文名称',
      EngName: '英文名称',
      Abbreviation: '三字母缩写',
      Symbol: '单字母符号',
    },
    // 虚拟筛选
    VF: { ...lang.zhVF },
    // 理化性质
    PP: { ...lang.zhPhysicochemicalProperty },
  },
}
