import { RouteRecordRaw } from 'vue-router'
import RouteNames from '@/consts/RouteNames'
// 结构绘制的页面
const SDIndex = () => import('@/views/Compound/structure-draw/index.vue')
const SDForm = () => import('@/views/Compound/structure-draw/view/sd-form/index.vue')
const SDResult = () => import('@/views/Compound/structure-draw/view/sd-result/index.vue')
// admet 预测页面
const APIndex = () => import('@/views/Compound/admet-prediction/index.vue')
const APForm = () => import('@/views/Compound/admet-prediction/view/ap-form/index.vue')
const APResult = () => import('@/views/Compound/admet-prediction/view/ap-result/index.vue')
// 相似化合物搜索页面
const SCIndex = () => import('@/views/Compound/similar-compound/index.vue')
const SCForm = () => import('@/views/Compound/similar-compound/view/sc-form/index.vue')
const SCResult = () => import('@/views/Compound/similar-compound/view/sc-result/index.vue')

export default [
  // 靶点预测
  {
    path: 'target/prediction',
    name: RouteNames.Compound.TargetPredictionPage,
    meta: {
      marginBottom: true, //设置距离底部
    },
    redirect: '/compound/target/prediction/targetForm',
    component: () => import('@/views/Compound/target-prediction/index.vue'),
    children: [
      {
        path: 'targetForm',
        component: () => import('@/views/Compound/target-prediction/view/target-form/index.vue'),
      },

      {
        path: 'result',
        name: 'TPResult',
        component: () => import('@/views/Compound/target-prediction/view/target-result/index.vue'),
      },
      // 公共
      {
        path: 'wait',
        name: 'TPWait',
        component: () => import('@/views/Common/history-wait-view/index.vue'),
      },
      {
        path: 'history',
        name: 'TPHistory',
        component: () => import('@/views/Common/history-result-view/index.vue'),
      },
    ],
  },
  // 逆合成预测
  {
    path: 'inverseSynthesis',
    name: RouteNames.Compound.InverseSynthesis,
    meta: {
      marginBottom: true, //设置距离底部
    },
    redirect: '/compound/inverseSynthesis/ISForm',
    component: () => import('@/views/Compound/inverse-synthesis/index.vue'),
    children: [
      {
        path: 'ISForm',
        name: 'ISForm',
        component: () => import('@/views/Compound/inverse-synthesis/view/IS-form/index.vue'),
      },
      {
        path: 'result',
        name: 'ISResult',
        component: () => import('@/views/Compound/inverse-synthesis/view/IS-result/index.vue'),
      },
      // 公共
      {
        path: 'wait',
        name: 'ISWait',
        component: () => import('@/views/Common/history-wait-view/index.vue'),
      },
      {
        path: 'history',
        name: 'ISHistory',
        component: () => import('@/views/Common/history-result-view/index.vue'),
      },
    ],
  },
  // admet预测
  {
    path: 'AdmetPrediction',
    name: 'AdmetPrediction',
    redirect: '/compound/AdmetPrediction/AP-Form',
    component: APIndex,
    meta: {
      marginBottom: true,
      showWaitHeader: false,
    },
    children: [
      {
        path: 'AP-Form',
        name: 'AP-Form',
        meta: { showWaitHeader: false },
        component: APForm,
      },
      {
        path: 'AP-Result',
        name: 'AP-Result',
        meta: { showWaitHeader: true },
        beforeEnter: (to, from, next) => {
          // 刷新需要回到原始的页面，否则数据会有错误，
          // 当上传文件的时候，刷新页面文件会丢失，导致获取不到文件，文件不想持久化
          if (from.path === '/') next({ name: 'AP-Form', replace: true })
          next()
        },
        component: APResult,
      },
    ],
  },
  // 结构绘制
  {
    path: 'StructureDraw',
    component: SDIndex,
    name: RouteNames.Compound.StructureDrawPage,
    redirect: '/compound/StructureDraw/SD-Form',
    children: [
      {
        path: 'SD-Form',
        name: 'SD-Form',
        component: SDForm,
      },
      {
        path: 'SD-Result',
        name: 'SD-Result',
        component: SDResult,
        beforeEnter: (to, from, next) => {
          if (from.path === '/') {
            history.back() // 会额外增加一条历史记录，需要回退
            next({ name: 'SD-Form', replace: true })
            return
          }
          next()
        },
        meta: { showWaitHeader: true },
      },
    ],
  },
  // 相似化合物搜索
  {
    path: 'similarCompound',
    component: SCIndex,
    name: RouteNames.Compound.SimilarCompoundPage,
    redirect: '/compound/similarCompound/SC-Form',
    children: [
      {
        path: 'SC-Form',
        name: 'SC-Form',
        component: SCForm,
      },
      {
        path: 'SC-Result',
        name: 'SC-Result',
        component: SCResult,
        // beforeEnter: (to, from, next) => {
        //   if (from.path === '/') {
        //     history.back() // 会额外增加一条历史记录，需要回退
        //     next({ name: 'SD-Form', replace: true })
        //     return
        //   }
        //   next()
        // },
        meta: { showWaitHeader: true },
      },
    ],
  },
] as RouteRecordRaw[]
