import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  BatchSearch: '批量查找',
  EnterTheSearch: '输入查找内容',
  MaxAdd: '最多添加',
  Piece: '条',
  Search: '查找',
  Reset: '重置',
  MolecularFormula: '结构简式',
  MolecularWeight: '相对分子质量',
  Name: '名称',
  CreateTime: '创建时间',
  ProjectCategory: '生物测定类型',
  TestCompoundsNumber: '测试化合物数量',
  TestSubstancesNumber: '测试测物数量',
  DataSource: '数据来源',
  DepositDate: '上传日期',
  Description: '描述',
  LatinName: '拉丁名',
  EnglishName: '英文名',
  SearchResult: '搜索结果',
  DownloadTemplate: '下载模板',
  Code: '编码',
  Tag: '标识符',
  EmptyFile: '请上传文件',
  ChromosomeNumber: '染色体编号',
  ProteinChange: '蛋白质变化',
  RelatedGene: '相关基因',
  ReviewStatus: '审核状态',
  Interpretation: '临床意义',
  SourceId: '来源数据库ID',
  OtherName: '别名',
  OfficialName: '官方名称',
  GenomeMapping: '基因组定位',
  SequenceLength: '序列长度',
  MolecularSpecies: '分子种类',
}
const zhParagraphInfo = {
  tip01: '查找方式一：选择标识符类型，并输入以逗号或空格分隔符的标识符，如：1234，1427s',
  tip02: '请选择标识符类型',
  tip03: '请输入标识符...',
  tip04: '查找更多标识符类型',
  tip05: '删除该筛选条件',
  tip06: '是否重置所有条件？',
  tip07: '请输入或选择查找的条件!  注意，同一数据两者都需要！',
  tip08: '方法二:上传标识符列表文件',
  tip09: '可直接将文件拖拽至此处，支持格式xlsx,xls',
  tip10: '一维氨基酸序列',
  tip11: '文件内容不符合规范，请检查重新上传',
}

const zhOption = {
  Nature: {
    label1: '根据生物测定id查找-YYAid,如:83187',
    label2: '根据药用植物id查找-YYPid,如:14',
    label3: '根据食用植物id查找-YYFid,如:21',
    label4: '根据化合物id查找-YYNPsid,如:83187',
    label5: '根据smiles查找,如:C1=CC=CC=C1',
    label6: '根据cas查找,如:25053-22-9',
    label7: '根据结构简式查找,如:C6H6',
    label8: '根据InchI查找,如:InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
    label9: '根据物种拉丁名查找,如:Daucus carota',
    type1: '生物测定id',
    type2: '药用植物id',
    type3: '食用植物id',
    type4: '化合物id',
    type5: 'smiles',
    type6: 'cas',
    type7: '结构简式',
    type8: 'InchI',
    type9: '物种拉丁名',
  },
  Compound: {
    label1: '根据化合物id查找-YYCID,如1476',
    label2: '根据smiles号查找,如:C1=CC=CC=C1',
    label3: '根据cas查找,如:50-78-2',
    label4: '根据结构简式查找,如C6H6',
    label5: '根据InchI查找,如:InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
    type1: '化合物id',
    type2: 'smiles号',
    type3: 'cas',
    type4: '结构简式',
    type5: 'InchI',
  },
  Protein: {
    label1: '蛋白质ID查找-yyhomePID,如:1476',
    label2: '蛋白质名称查找,如:TP53-binding protein 1',
    label3: '分类查找,如:Homo sapiens',
    label4: '氨基酸序列长度查找,如:214',
    label5: 'uniprotID查找,如:A0A0C5B5G6',
    type1: '蛋白质ID',
    type2: '蛋白质名称',
    type3: '分类查找',
    type4: '氨基酸序列长度',
    type5: 'uniprotID',
  },
  Gene: {
    label1: '基因名称,如:TP53 - tumor protein p53',
    label2: '基因 - YYGN id,如:YYGN27299',
    label3: '核苷酸 - YYNC id,如:YYNC1000365143',
    label4: '生物项目 - YYPJ id,如:YYPJ132541',
    label5: '生物样本 - YYBSid,如:YYBS10130874',
    label6: '表型和变异 - YYCL id,如:2368866',
    label7: '物种名称(物种),如: Homo sapien',
    type1: '基因名称',
    type2: '基因',
    type3: '核苷酸',
    type4: '生物项目',
    type5: '生物样本',
    type6: '表型和变异',
    type7: '物种名称(物种)',
  },
}

const enOption = {
  Nature: {
    label1: 'Search by Bioassay id-YYAid,such as 83187',
    label2: 'Search by Medicinal plant id-YYPid,such as 14',
    label3: 'Search by Food plant id-YYFid,such as 21',
    label4: 'Search by Compound id-YYNPsid,such as 83187',
    label5: 'Search by Smiles,such as C1=CC=CC=C1',
    label6: 'Search by Cas,such as 25053-22-9',
    label7: 'Search by Simple structural formula,such as C6H6',
    label8: 'Search by InchI,such as InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
    label9: 'Search by Species Latin name,such as Daucus carota',
    type1: 'Bioassay id',
    type2: 'Medicinal plant id',
    type3: 'Food plant id',
    type4: 'Compound id',
    type5: 'Smiles',
    type6: 'Cas',
    type7: 'Simple structural formula',
    type8: 'InchI',
    type9: 'Species Latin name',
  },
  Compound: {
    label1: 'Search by Compound id-YYCID,such as 1476',
    label2: 'Search by Smiles,such as C1=CC=CC=C1',
    label3: 'Search by Cas,such as 50-78-2',
    label4: 'Search by Simple structural formula,such as C6H6',
    label5: 'Search by InchI,such as InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
    type1: 'Compound id',
    type2: 'Smiles',
    type3: 'Cas',
    type4: 'Simple structural formula',
    type5: 'InchI',
  },
  Protein: {
    label1: 'Search by Protein id-yyhomePID,such as 1476',
    label2: 'Search by Protein Name,such as TP53-binding protein 1',
    label3: 'Search by Category,such as Homo sapiens',
    label4: 'Search by Amino acid sequence length,such as 214',
    label5: 'Search by uniprotID,such as A0A0C5B5G6',
    type1: 'Protein id',
    type2: 'Protein Name',
    type3: 'Category',
    type4: 'Amino acid sequence length',
    type5: 'uniprotID',
  },
  Gene: {
    label1: 'Gene Name,such as TP53 - tumor protein p53',
    label2: 'Gene Id - YYGN id,such as YYGN27299',
    label3: 'Nucleotide - YYNC id,such as YYNC1000365143',
    label4: 'Biological project - YYPJ id,such as YYPJ132541',
    label5: 'Biological sample - YYBSid,such as YYBS10130874',
    label6: 'Phenotypes AND VARIANTS - YYCL id,such as 2368866',
    label7: 'Species name (species),such as Homo sapien',
    type1: 'Gene Name',
    type2: 'Gene Id',
    type3: 'Nucleotide',
    type4: 'Biological project',
    type5: 'Biological sample',
    type6: 'Phenotypes AND VARIANTS',
    type7: 'Species name (species)',
  },
}
/* tab栏的对应中英文 */
const zhTabArr = {
  nature_compound: '化合物',
  nature_bioassay: '生物测定',
  plant: '食用植物',
  food: '药用植物',
  cell: '细胞系',
  protein: '蛋白质',
  disease: '人类疾病',
  alphafold: 'alphafold 蛋白质结构预测',
  target: '靶点-化合物生物测定',
  compound: '化合物',
  chemicalReaction: '化学反应',
  gene: '基因',
  nucleotide: '核苷酸',
  bioproject: '生物项目',
  biosample: '生物样本',
  clinvar: '表现与变异',
}

// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)

enParagraphInfo.tip01 = 'Search method 1: Select the identifier type and enter the identifier separated by a comma or space, such as: 1234, 1427s'
enParagraphInfo.tip02 = 'Please select the identifier type'
enParagraphInfo.tip03 = 'Please enter the identifier...'
enParagraphInfo.tip04 = 'Add more identifier types'
enParagraphInfo.tip05 = 'Delete the filter'
enParagraphInfo.tip06 = 'Reset all conditions?'
enParagraphInfo.tip07 = 'Please enter or select the search criteria! Note that you need both for the same data!'
enParagraphInfo.tip08 = 'Method 2: Upload the identifier list file'
enParagraphInfo.tip09 = 'You can drag and drop files directly here, and the formats xlsx,xls are supported'
enParagraphInfo.tip10 = 'One-dimensional amino acid sequence'
enParagraphInfo.tip11 = 'The content of the file does not conform to the specification, please check and upload again'

export const zhBatchSearch = { ...zhBaseInfo, ...zhParagraphInfo, ...{ Options: zhOption }, ...{ zhTabArr: zhTabArr } }
export const enBatchSearch = { ...enBaseInfo, ...enParagraphInfo, ...{ Options: enOption } }
