// 主要是用来处理一些额外的数据
class DataUtil {
  private constructor() {}
  // 对象到get参数的转变
  static objectToGetQuery(obj: Record<string, any>, temp: string[] = []) {
    for (let k in obj) {
      // 判断对象值里面是否还是对象
      if (obj[k] instanceof Object) {
        DataUtil.objectToGetQuery(obj[k], temp)
        continue
      }
      temp.push(k + '=' + encodeURIComponent(obj[k]))
    }
    // 返回一个字符串
    return temp.join('&')
  }

  // 将对象的key映射到值上面
  /**
   *
   * @param obj 值
   * @param isValueEmpty 是否需要映射一个值是空的对象
   * @returns
   */
  static objectToKeyValue<T extends string>(
    obj: Record<T, string>,
    isValueEmpty: boolean
  ) {
    type KeyToValueObj = { [key in T]: string }
    // 准备需要返回的数据
    const keyToValueObj: KeyToValueObj = {} as KeyToValueObj
    // 生成数据
    for (const key in obj) {
      if (!isValueEmpty) {
        keyToValueObj[key] = DataUtil.capitalizeAndAddSpace(key)
      } else {
        keyToValueObj[key] = ''
      }
    }
    // 返回生成的对象
    return keyToValueObj
  }
  // 使用正则表达式将大写字母前添加一个空格，但排除第一个字母
  private static capitalizeAndAddSpace(str: string) {
    const regex = /(?<!^)(?=[A-Z])/g
    return str.replace(regex, ' ')
  }
}

export const { objectToGetQuery, objectToKeyValue } = DataUtil
