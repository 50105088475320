// i18n/zh.js
import nature_zh from './zh/nature.zh'
import gene_zh from './zh/gene.zh'
import compound_zh from './zh/compound.zh'
import protein_zh from './zh/protein.zh'
import upload_zh from './zh/upload.zh'
import home_zh from './zh/home.zh'
import { zhSearch } from './home'
import lang from './view'
import profile from './profile'

import * as other from './other'

export default {
  ...nature_zh,
  ...gene_zh,
  ...compound_zh,
  ...protein_zh,
  ...upload_zh,
  ...home_zh,
  nav: {
    home: '首页',
    login: '登录',
    userName: '用户名',
    password: '密码',
    instruction: '使用说明',
    download: '下载',
    about: '关于',
    upload: '上传',
    batchSearch: '批量查找',
    Home: '主页',
    transaction: '交易',
    forum: '论坛',
    exhibition: '展示',
    serviceLibrary: '服务库',
    HeadTitle: '生命化学智识库门户网',
    Hello: '你好！',
    Mine: '账户设置',
    Exit: '退出',
    myCollection: '我的收藏',
    more: '更多',
    dataupload: '上传数据',
    kindmodel: '类ChatGPT模型',
    transactionShow: '交易展示',
    supermoleculeFitted: '超分子拟合',
    informationcenter: '资讯中心',
    SyntheticBiology: '合成生物',
    resource: '资源',
    database: '数据库',
    result: '结果',
    knowledgeGraph: '知识图谱',
    Protects: '限制性内切酶保护碱基表',
    Restriction: '限制性酶切位点分析工具',
    ADMET: 'ADMET 预测', //ADEMT 预测
    DrugAnalysisTool: '药物分析工具', //ADEMT ,
    searchRes: '搜索结果',
    AI: 'AI生命化学专家',
    DownloadData: '下载数据',

    SearchOption: {
      All: '全部',
      Nature: '天然产物',
      Compound: '化合物',
      Gene: '基因',
      Protein: '蛋白质',
      NatureCompound: '全部化合物',
      NatureBioassay: '生物测定',
      NatureFood: '食物',
      NaturePlant: '药用植物',
      NautreCellLine: '细胞系',
      GeneBioPro: '生物项目库',
      GeneBioSam: '生物样本库',
      GeneCli: '表型与变异关联库',
      GeneNuc: '核苷酸序列库',
      CompoundR: '化学反应',
    },
    Statisitc: {
      CompoundNum: '化合物数量',
      ProteinNum: '蛋白质数量',
      GeneNum: '基因数量',
      BioassayNum: '生物测定数量',
    },
    HomeCard: {
      Nature: {
        text: '天然产物数据库',
        classify1: '天然产物',
        classify2: '生物测定',
        classify3: '分类学',
      },
      Protein: {
        text: '蛋白质数据库',
        classify1: '蛋白质',
        classify2: '靶点',
        classify3: '互作',
      },
      Compound: {
        text: '化合物数据库',
        classify1: '化合物',
        classify2: '靶点',
        classify3: '化学合成',
      },
      Gene: {
        text: '基因数据库',
        classify1: '基因',
        classify2: '核苷酸',
        classify3: '表型与变异',
      },
    },
    Resource: {
      Blast: 'BLAST',
      BlastText: '基本局部比较搜索工具',
      targetPrediction: '靶点预测',
      Msa: 'MSA',
      MsaText: '多序列比较工具',
      PhysicalAndChemicalProperties: '理化性质分析',
      PhysicalAndChemicalPropertiesText: '物理化学参数计算工具',
      SimilarCompound: '相似化合物搜索',
      SimilarCompoundText: '相似化合物对比工具',
      JBrowse: 'JBrowse',
      JBrowseText: '基因组可视化工具',
      Taxonomy: '分类学',
      TaxonomyText: '物种分类工具',
      PrimerDesign: '引物设计',
      PrimerDesignText: 'PCR模板特异性引物设计工具',
      ORFfinder: 'ORF finder搜索',
      ORFfinderText: '查找DNA序列开放阅读框工具',
      ChemicalReactionPrediction: '化学反应预测',
      ChemicalReactionPredictionText: '血缘展示工具',
      SeeMore: '查看更多',
      OpenRead: '开放阅读框查找',
    },
    Advertising: {
      Title: '生命化学智识库门户网',
      BtnText: '立即使用',
    },
    DataSources: {
      DataSourceDescription: '数据来源说明',
      NatureAndCompound: '天然产物库和化合物',
      Protein: '蛋白质库',
      Gene: '基因库',
      DatabaseName: '数据库名称',
      DataSourceWeb: '源数据网址',
      DataSourceLink: '源数据详细网址',
    },
  },
  search: {
    hotKey: '热搜词条',
    searchResultPrefix: '在数据库中共找到',
    searchResultSuffix: '个结果',
    youCan: '您可以在',
    globalSearchPage: '全局搜索页',
    searchYouWant: '中搜索您想要的数据',
  },
  download: {
    result: '结果',
    format: '格式',
    pic: '图片',
    batchDownload: '批量下载',
    thisPage: '下载当前页',
    cancel: '取消选择',
    warn: '请先选择要下载的内容',
  },
  personal: {
    accountInformation: '账户信息',
    profilePhoto: '头像',
    modifyProfilePicture: '修改头像',
    nickname: '昵称',
    changeNickname: '修改昵称',
    eMail: '邮箱',
    bindEmailAddress: '绑定邮箱',
    cellPhoneNumber: '手机号',
    bindMobilePhone: '绑定手机',
    password: '密码',
    changePassword: '修改密码',
    note: '注意：中英文均可，最长14个英文或7个汉字',
    enterNewNickname: '请输入新的昵称',
    confirm: '确认',
    cancel: '取消',
    enterNewEmailAddress: '请输入新的邮箱',
    enterVerificationCode: '请输入四位数验证码',
    getCode: ' 获取验证码',
    enterNewPhoneNumber: '请输入新的手机号',
    enterOldPassword: '请输入旧的密码',
    enterNewPassword: '请输入新的密码',
    confirmPassword: '请确认密码',
    yourNickname: '您的昵称',
    yourEmailAddress: '您的邮箱',
    yourPhone: '您的手机',
    modifySuccessfully: '修改成功',
    acquireSuccess: '获取成功',
    bindingSuccessful: '绑定成功',
    secondPasswordDifferentFromNewPassword: '二次输入密码与新密码不同',
    WeChat: '微信',
    bindToWechat: '绑定微信',
    organizationInformation: '机构信息',
    institutionOrganization: '机构/组织',
    department: '部门',
    laboratory: '实验室',
    post: '职务',
    researchField: '研究领域',
    save: '保存',
    personalInformation: '个人信息',
    familyName: '姓',
    name: '名',
    detailedAddress: '详情地址',
    postalCode: '邮政编码',
    thirdPartyBinding: '第三方绑定',
    Name: '名字',
  },
  common: {
    compoundRetrosynthesis: '化合物逆合成',
    LogoutOrNot: '退出登录吗',
    enterUserName: '请输入用户名',
    FeaturesAreUnderDevelopment: '功能正在开发中，敬请期待',
    myCollect: '我的收藏',
    showAll: '显示全部',
    cancelCollection: '取消收藏',
    SuccessfulCollection: '收藏成功',
    pleaseLoginIn: '请先登录',
    viewMore: '查看更多',
    loading: '加载中...',
    hotKeys: '热搜词条',
    downloadCurrentSearchResult: '下载当前搜索',
    currentSearch: '本次搜索',
    smilesInputTip: '请输入smiles',
    search: '搜索',
    cancel: '取消',
    confirm: '确认',
    reset: '重置',
    browerVersionTooLow: '浏览器版本过低',
    noData: '没有找到你想要的数据.',
    goto: '前往第',
    page: '页',
    bestMatch: '最佳匹配',
    all: '全部',
    type: '类型',
    number: '编码',
    ComputableFeatures: '计算特征',
    value: '值',
    reference: '引用',
    source: '来源',
    description: '描述',
    detail: '详情',
    resultCount: '个结果',
    nature: '天然产物',
    protein: '蛋白质',
    compound: '化合物',
    gene: '基因',
    humanDisease: '人类疾病',
    BatchUpload: '批量查找',
    DataStatistic: '数据统计',
    StructureDraw: '结构绘制',
    Login: '登录',
    IsLogin: '已登录',
    GoTop: '返回顶部',
    None: '暂无',
    targetPrediction: '靶点预测',
    NoData: '暂无数据',
    ProjectDetail: '项目详情',
    EditProject: '编辑项目',
    Menu: '目录',
  },
  breadcrumb: {
    Compound: '化合物库',
    Gene: '基因库',
    Nature: '天然产物库',
    Protein: '蛋白质库',
    Browser: '天然产物-数据概览',
    PeriodicTable: '元素周期表',
    PhenotypeMutationDetails: '表型与变异详情页',
    SimilarCompound: '相似化合物搜索',
    HomeSimilarCompound: '相似化合物搜索',
    phylogenetictree: '系统发生树',
    NatureCompound: '化合物-化合物',
    NatureCellLine: '细胞系',
    NatureFood: '食用植物',
    NaturePlant: '药用植物',
    NatureBioassay: '生物测定',
    ProteinProtein: '蛋白质',
    dataSources: '数据来源说明',
    ProteinHumanDis: '人类疾病',
    JBrowse: '基因图谱',
    Upload: '上传',
  },
  homedownload: {
    historyDownload: '下载历史版本',
    SerialNumber: '序号',
    name: '名称',
    OnlineTime: '上线时间',
    describe: '描述',
    DownloadFormat: '下载格式',
    Selectdownloadformat: '选择下载格式',
    back: '返回',
    text1:
      '说明：YYhomeNPs作为免费资源提供给公众。出于商业目的使用和重新分发全部或部分数据需要作者的明确许可，并明确确认源材料（FooDB）和原始出版物。我们要求下载数据库重要部分的用户在在发表的出版物中注明信息来源。有关商业许可证，请咨联系我们。',
    text2: '本工作根据知识共享署名-非商业性使用4.0国际许可协议进行许可。',
  },
  childdownload: {
    Compound: '化合物',
    Gene: '基因',
    Nature: '天然产物',
    Protein: '蛋白质',
    downloadPage: '下载页',
    latestversion: '最新版本',
    theversionofhistory: '历史版本',
  },
  batchUpload: {
    Title: '批量查找-',
    NatureDataBase: '天然产物库',
    CompoundDataBase: '化合物库',
    ProteinDataBase: '蛋白质库',
    GeneDataBase: '基因库',
    Nature: '天然产物',
    Compound: '化合物',
    Protein: '蛋白质',
    Gene: '基因',
    OneFuncTitle: '方法一:选择标识符类型,并输入以分号分隔的标识符,如:1436;1427;1428',
    inputTitle: '请输入标识符...',
    SelectTitle: '请选择标识符类型',
    AddBtn: '查找更多标识符类型',
    DeleteBtn: '删除该筛选条件',
    TwoFuncTitle: '方法二:上传标识符列表文件',
    SearchBtn: '立即查找',
    UploadBtn: '点击上传',
    DownloadBtn: '下载模板',
    UnuploadedText: '上传文件以预览，暂未上传',
    UploadFailedText: '上传失败：请检查文件格式',
    FileTooLarge: '文件大小不能超过5MB!',
    Preview: '预览',
    FileName: '文件名',
    SerialNum: '序号',
    IdentifierType: '标识符类型',
    Identifier: '标识符',
    LineNum: '行数',
    Rows: '行',
    WarningStr: '请输入搜索条件！',
    WarningFile: '请上传文件进行搜索！',
    UploadTip: '请上传xlsx/xls/csv文件,文件大小不超过5MB,且只能上传一个文件。',
    MaxAdd: '最多添加',
    Piece: '条',
    MinDel: '无法删除第一条筛选条件',
    Options: {
      Nature: {
        lable1: '根据生物测定id查找-YYAid,如:83187',
        lable2: '根据药用植物id查找-YYPid,如:14',
        lable3: '根据食用植物id查找-YYFid,如:21',
        lable4: '根据化合物id查找-YYNPsid,如:83187',
        lable5: '根据smiles查找,如:C1=CC=CC=C1',
        lable6: '根据cas查找,如:25053-22-9',
        lable7: '根据结构简式查找,如:C6H6',
        lable8: '根据InchI查找,如:InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
        lable9: '根据物种拉丁名查找,如:Daucus carota',
        type1: '生物测定id',
        type2: '药用植物id',
        type3: '食用植物id',
        type4: '化合物id',
        type5: 'smiles',
        type6: 'cas',
        type7: '结构简式',
        type8: 'InchI',
        type9: '物种拉丁名',
      },
      Compound: {
        lable1: '根据化合物id查找-YYCID,如1476',
        lable2: '根据smiles号查找,如:C1=CC=CC=C1',
        lable3: '根据cas查找,如:50-78-2',
        lable4: '根据结构简式查找,如C6H6',
        lable5: '根据InchI查找,如:InChI=1S/C9H8O4/c1-6(10)13-8-5-3-2-4-7(8)9(11)12/h2-5H,1H3,(H,11,12)',
        type1: '化合物id',
        type2: 'smiles号',
        type3: 'cas',
        type4: '结构简式',
        type5: 'InchI',
      },
      Protein: {
        lable1: '蛋白质ID查找-yyhomePID,如:1476',
        lable2: '蛋白质名称查找,如:TP53-binding protein 1',
        lable3: '分类查找,如:Homo sapiens',
        lable4: '氨基酸序列长度查找,如:214',
        lable5: 'uniprotID查找,如:A0A0C5B5G6',
        type1: '蛋白质ID',
        type2: '蛋白质名称',
        type3: '分类查找',
        type4: '氨基酸序列长度',
        type5: 'uniprotID',
      },
      Gene: {
        lable1: '基因名称,如:TP53 - tumor protein p53',
        lable2: '基因 - YYGN id,如:YYGN27299',
        lable3: '核苷酸 - YYNC id,如:YYNC1000365143',
        lable4: '生物项目 - YYPJ id,如:YYPJ132541',
        lable5: '生物样本 - YYBSid,如:YYBS10130874',
        lable6: '表型和变异 - YYCL id,如:2368866',
        lable7: '物种名称(物种),如: Homo sapien',
        type1: '基因名称',
        type2: '基因',
        type3: '核苷酸',
        type4: '生物项目',
        type5: '生物样本',
        type6: '表型和变异',
        type7: '物种名称(物种)',
      },
    },
  },
  SimilarCompound: {
    DataBase: '数据库：',
    BackDraw: '返回数据上传',
    Title: '相似化合物搜索',
    Draw: '结构绘制',
    Result: '搜索结果',
    InputText: '请输入Smiles...',
    Index: '序号',
    Structure: '结构',
    Name: '名称',
    SimpleStructuralFormula: '结构简式',
    Similarity: '相似度',
    Waring: '数据暂无',
    Sort: {
      Sort: '排序',
      ASC: '升序',
      DESC: '降序',
    },
    Filter: {
      Filter: '筛选',
      Similar5: '相似度高于0.5',
      Similar8: '相似度高于0.8',
    },
    ConfirmBtn: '确定',
    ResetBtn: '重置',
  },
  Footer: {
    Connection: '联系: yyhome',
    Email: '邮箱: ',
    Telephone: '电话: 151 6859 8681',
    Website: '网址: ',
    Address: '地址：',
    DetailAddress: '浙江省宁波市镇海区启迪科技园A座1101室',
    Statement: '许可证和免费声明',
    DataSource: '数据来源',
    Record: '备案号: 浙ICP备2021039509号',
    License: {
      License: '许可证：',
      LicenseText: '我们选择将知识共享署名4.0国际（CC BY4.0）许可应用于我们数据库的所有受版权保护部分。',
      Disclaimer: '免责声明: ',
      DisclaimerText:
        '我们不保证数据的正确性，也不对因使用数据而造成的损害承担责任。我们无法提供有关数据使用的不受限制的许可，因为某些数据可能受专利或其他权利的保护。任何医学或遗传信息仅用于研究、教育和信息目的。它不以任何方式用作专业医疗建议、诊断、治疗或护理的替代品。',
    },
  },
  blast: {
    SubmitTask: '提交任务',
    myTask: '我的任务',
    MyResults: '我的结果',
    inquiry: '查询',
    inputFASTA: '输入序列',
    example: '例子',
    clear: '清除',
    placeholder1: '请输入序列',
    uploadFile: '上传文件',
    tips1: '请上传本地FASTA文件',
    goal: '目标',
    radio1: '对比数据库中的序列',
    radio2: '比对输入序列',
    database: '数据库',
    NucleotideSet: '核苷酸集',
    genome: '基因组',
    transcriptome: '转录组',
    programselection: '程序选择',
    programoptimization: '程序优化',
    setTaskName: '设置任务名称',
    viaemail: '通过电子邮件通知',
    settingparameter: '设置参数',
    outputformat: '输出格式',
    text1: '要保留的最大对齐序列数',
    text2: '期望值设置',
    text3: '字长（匹配片段长度）',
    default: '默认为',
    Scoringparameters: '评分参数',
    filter: '过滤和屏蔽程序',
    submit: '提交',
    errorText1: '请上传你的序列号（文件上与序列号二选一）',
    successText1: '上传成功请稍等',
    errorText2: '(二选一)',
    errorText3: '上传文件不能为空（请选择上传文件形式）',
    errorText4: '请提交文件',
    successText2: '上传成功请稍等查看',
    errorText5: '序列号与对比序列号不能为空(请选择上传方式)',
    errorText6: '上传序列号或者文件不能为空（请选择上传方式）',
    errorText7: '请提交文件',
    errorText8: '上传文件不能为空(请选择上传方式)',
    errorText9: '上传序列号不能为空',
    errorText10: '必须是fasta的文件',
    errorText11: '上传文件不能大于500MB',
    errorText12: '上传文件不能为空',
    text4: '产生显著对比的序列',
    download: '下载',
    selectcol: '选择列',
    generalparameter: '一般参数',
    show: '展示',
    sequencenumber: '序列号',
    Maximumscore: '最大得分',
    TotalPoints: '总得分',
    TargetSequenceCoverage: '目标序列覆盖度',
    ConsistentPercentage: '一致百分比',
    sequencelength: '序列长度',
    sequence: '序列',
    nucleotide: '核苷酸',
    protein: '蛋白质',
    Nucleotidequality: '核苷酸质',
    TaskName: '任务名称',
    TaskID: '任务ID',
    cdownload: '点击下载',
    UploadTime: '上传时间',
    WorkingCondition: '工作状态',
    complete: '完成',
    Return: '返回提交任务',
    procedure: '程序',
    quote: '引用',
    moleculartype: '分子类型',
    querylength: '查询长度',
    aminoacid: '氨基酸',
    run: '运行',
    tishi1: '你的blast作业已提交',
    tishi2: '你可以在这里等到它完成',
    tishi3: '此状态将一秒检查一次',
    tishi4: '注意：如果文件太大，你可以复制作业ID并稍后在我的BLAST任务页面中检索结果。',
    tishi5: '请上传您的序列号，或者在我的任务中选择。',
    myBlastTask: '我的BLAST任务',
    BLASTres: 'BLAST结果',
    errorText13: '没有结果，请上传结果或者查看我的任务',
    text5: '查找您的BLAST任务 (您的BLAST结果最多保留一周)',
    text6: '任务ID或通知电子邮件',
    append: '添加',
    Submitin: '提交于',
    state: '状态',
    Maturitytime: '到期时间',
    operation: '操作',
    confirmText1: '确定删除这一条结果吗？',
    errorText14: '序列号出错',
  },
  primer: {
    primerdesign: '引物设计',
    PCR: 'PCR模板',
    text1: '请输入序列，或上传文件',
    uploadFile: '上传文件',
    NOselect: '未选择任何文件',
    placeholder1: '在此处输入或粘贴PCR模板，最大长度限制为50000bps……',
    text2: '暂未上传或输入序列',
    text3: '没有填写PCR长度限制不能输入',
    PCRname: 'PCR模板名称',
    placrholder2: '请输入PCR名称...',
    Primerparameters: '引物参数',
    NumberofPCRproducts: 'PCR产物数量',
    PCRproductlength: 'PCR产物长度',
    Primerlength: '引物长度',
    PrimerTM: '引物解链温度（Tm）',
    run: '运行',
    reset: '重置',
    error1: '请输入序列',
    error2: '请输入正确的fasta序列',
    error3: '请注意，此序列必须以>开头',
    error4: '请注意，序列中只能包含一个换行符！',
    error5: '当前输入的序列长度超过定义的PCR产物长度',
    error6: '当前输入的序列长度小于定义的PCR产物长度',
    error7: '上传文件与fasta序列只能选择其中之一',
    error8: '文件必须是以.fasta格式结尾的文件',
    title1: '引物参数上传',
    title2: '引物设计详情',
    required: '必填',
    des: '当前没有正在请求中的数据！',
    PCRinfo: 'PCR模板信息',
    PCRrange: 'PCR模板范围',
    Primerpairresultview: '引物对结果视图',
    detailedresults: '详细结果',
    Primerpairs: '引物对',
    sequence: '序列',
    templatecolumn: '模板链',
    length: '长度',
    initiation: '起始',
    termination: '终止',
    Selfcomplementarity: '自身互补性',
    isselfcomplementary: "3'端自身互补性",
    forwardprimer: '正向引物',
    reverseprimer: '反向引物',
    sizeoftheproducts: '产物长度',
  },
  aboutus: {
    ContactUs: '联系我们',
    aboutNature: '关于天然产物',
    missions: '任务使命',
    cooperation: '合作',
    Ourinfluence: '我们的影响',
    naturelib: '天然产物库',
    text1:
      'yyhome天然产物库成立于2022年，是世界上最大的可免费访问的化学信息集合。按名称、分子式、结构和其他标识符搜索化学品，查找化学和物理特性、生物活性、安全性和毒性信息、专利、文献引用等。',
    Ourmission: '我们的使命',
    text2:
      '我们是yyHome战略数据平台（yyHome Strategic Data Platform）的一部分，该战略平台是由一支50人规模的研发团队组成，由宁波甬恒瑶瑶智能科技有限公司独家资助、运行和维护。中心主要致力于以下三方面的工作：围绕中国人群普惠健康的精准医学相关组学信息资源，完善建立中国人群基因组遗传变异图谱，形成中国人群精准医学信息库；基于高通量测序的海量原始组学数据资源，建立符合国际标准的原始组学数据归档库，形成中国原始组学数据的共享平台；围绕国家重要战略生物资源，建立海量组学数据的整合、挖掘与应用体系，形成综合性的多组学数据库系统。',
    annualreport: '年报',
    text3: '2021年主要统计数据和亮点',
    text4: '我们与世界各地的科学家和工程师合作，并提供在生命科学领域公开共享数据所需的基础设施。',
    text5: '了解遗传学如何影响人类、植物和动物的健康对于疾病预防、粮食安全和生物多样性的进步至关重要。',
    text6: '我们开发数据库、工具和软件，使公共资助研究中产生的各种数据保持一致、验证和可视化成为可能，并将这些信息免费提供给所有人。',
    text7: '平均每天对我们网站发出的网络请求',
    text8: '访问我们',
    text9: '横跨52个国家/地区的学术机构进行合作',
    text10: '原始数据存储数量',
    text11: '探索我们2021年的热门故事，包括AlphaFold 数据库的推出以及EMBL-EBL数据资源和研究如何支持 COVID - 19 大流行应对措施',
    text12: '主要数据库合作',
    text13: '我们的核心数据库是与其他世界领导者共同创建的，包括',
    litext1: '学术机构合作',
    litext2: '企业合作',
    litext3: '数据库合作',
    aboutProtein: '关于蛋白质',
    outline: '概述',
    text14: '我们是谁',
    text15: '在yyhome蛋白质知识库，我们帮助科学家实现大数据在蛋白质研究中的潜力，利用复杂的信息做出有益于人类的发现。',
    text16: 'yyhome蛋白质知识库是国际性的、创新的、跨学科的、并且是生命科学领域开放数据的拥护者。',
    text17: '我们是yyHome大型综合战略数据平台（yyhome Strategic Data Platform）的一部分，该战略平台是由一支50人规模的研发团队组成',
    text18: '由宁波甬恒瑶瑶智能科技有限公司独家资助、运行和维护。我们位于中华人民共和国浙江省宁波市中官西路1277号启迪科技园。',
    text19: '我们是一个高度协作的组织，与世界各地的组织合作提供数据服务，具有全球意义的国际研究项目以及于 各种规模的公司联合计划。',
    // text20: '我们还是欧洲几个新兴研究基础设施的合作伙伴，并托管了',
    center: '中心',
    // text21: '这是欧洲生命科学信息的分布式基础设施。',
    // text22:
    //   '我们是全球协调数据收集和传播项目的欧洲节点，例如全球蛋白质数据库（wwPDB）和国际核苷酸序列数据库合作（INDSC）。',
    aboutGene: '关于基因',
    genedatabase: '基因数据库',
    text23:
      '基因数据库中心成立于2022年，中心针对我国基因组学数据“存管用”的实际需求以及“数据孤岛”、“数据主权”的重大问题，围绕人、动物、植物、微生物等基因组数据，重点开展数据库体系及数据资源建设，开展数据服务、系统运维、技术研发、数据挖掘等系列工作，建成有国际影响力的基因组科学数据中心，促进科学数据开放共享，保障科学数据安全可控，支撑国家科技创新和经济社会发展',
    aboutCompound: '关于化合物',
    overview: '总体概况',
    databaseService: '数据库服务',
    databaseAdvantages: '数据库优势',
    databaseCollaboration: '数据库协作',
    compounddatabase: '化合物数据库',
    text24:
      'YYhomeC化合物数据库成立于2022年，为解决我国现有化学专业数据库存在的数据量小、内容少、维护不好等缺陷，杜绝国外化合物数据库数据断供，国内技术及数据流失等风险，重点开展的化合物数据资源整合共享平台建设。',
    text25:
      'YYhomeC化合物数据库是世界上最大的可免费访问的化学信息集合，可按名称、分子式、结构和其他标识符搜索化学品，查找化合物化学和物理特性、生物活性、安全性和毒性信息、专利、文献引用等；具备相似化合物搜索以及靶点预测功能；同时包含化学反应子库，提供化学反应路线。',
    Service1: '一维中文名字/SMILES/InChI形式查询',
    Service2: '提供2D化学结构式查询',
    Service3: '绘制2D化学结构式工具便于分子查询',
    Service4: '提供3D化学结构可视化展示',
    Service5: 'AI化合物骨架识别算法',
    Service6: '相似化合物搜索',
    Service7: '化合物靶点预测',
    Advantages1: '开源数据，免费下载，加强学术共享',
    Advantages2: '数据库功能多样化，数据可视化',
    Advantages3: '囊括中英多语言数据来源，数据完整，避免冗余',
    Advantages4: '内容汉化，方便阅读，提升工作效率',
    Advantages5: '打破国外生化数据垄断，解决数据断供风险',
  },
  JBrowse: {
    HeadTitle: '基因组图谱',
    HeadText:
      '基因图谱是一种可更新的转录组资源，该库有相关基因组的信息，包含序列、图谱、染色体、组装和注释。为研究不同基因组详细信息，基因图谱提供了对转录景观变化的见解，并准确注释遗传元素。',
    DownloadBtn: '下载',
    Details: {
      PublicationDate: '公布日期：',
      BioProject: '生物项目：',
      Overview: '概述',
      GenomicInformation: '基因组信息',
    },
  },
  News: {
    LatestNews: '最新资讯',
    List: {
      Nature: '天然产物',
      Protein: '蛋白质',
      Compound: '化合物',
      Gene: '基因库',
    },
    Years: '年份',
    Day: '日',
    MonthList: {
      January: '1月',
      February: '2月',
      March: '3月',
      April: '4月',
      May: '5月',
      June: '6月',
      July: '7月',
      August: '8月',
      September: '9月',
      October: '10月',
      November: '11月',
      December: '12月',
    },
    Select: '搜索资讯',
    InputText: '关键字',
    SelectBtnText: '搜索',
    HotTags: '热门标签',
    DetailsBtn: '查看详细',
    Details: {
      NewHome: '新闻中心',
      NewDetail: '新闻详情页',
      Source: '来源：',
      PreviousPage: '上一篇：',
      NextChapter: '下一篇：',
      Home: '大主页',
      RecommendedReading: '推荐阅读',
    },
  },
  login: {
    login1: '登录YYHOME账号',
    login2: '您将可以',
    text1: '上传序列-贡献开源世界',
    text2: '参与论坛-讨论学术议题',
    text3: '采购器材-仪器试剂服务',
    wechat: '微信扫码',
    Account: '账号密码',
    phone: '手机验证',
    p1: '邮箱或手机号',
    pwd: '密码',
    code: '验证码',
    text4: '看不清？请刷新验证码',
    noact: '没有账号？',
    creatact: '创建账号',
    p2: '请输入手机号码',
    text5: '免费获取验证码',
    setName: '设置名称',
    verify: '确认',
    set: '请设置名称',
    err1: '请输入邮箱或者手机号',
    err2: '请输入密码',
    err3: '请输入昵称',
    err4: '请输入手机号',
    err5: '请输入手机验证码',
    err6: '请输入验证码',
    err7: '验证码失效，刷新后重新登录',
  },
  register: {
    title: '欢迎注册YYHOME账号',
    EmailRegistration: '邮箱注册',
    form1: '账户登录信息',
    email: '邮箱',
    p1: '请输入您的邮箱',
    pwd: '密码',
    p2: '请输入密码',
    ConfirmPassword: '确认密码',
    PersonalInformation: '个人信息',
    surname: '姓',
    name: '名',
    address: '地址',
    address1: '详细地址',
    Postalcode: '邮政编码',
    Organizationinformation: '机构信息',
    l1: '机构/组织',
    section: '部门',
    laboratory: '实验室',
    job: '职务',
    Researchfield: '研究领域',
    code: '验证码',
    codetext: '看不清？请刷新二验证码',
    submit: '提交',
    back: '返回',
    err1: '请再次输入密码',
    err2: '两次输入密码不一致',
    err3: '请输入您的邮箱',
    err4: '请输入姓',
    err5: '请输入名',
    err6: '邮箱格式错误！',
    err7: '邮箱不能为空',
    err8: '地址不能为空，请选择地址',
    err9: '请输入你的详细地址',
    err10: '请输入密码',
    err11: '请输入8-30位英文字母、数字或者符号（除空格）,且字母、数字和标点符号至少包含两种',
    err12: '请输入正确的邮箱',
    Mailboxverification: '邮箱验证',
    Resend: '重新发送验证码',
    Registeredsuccessfully: '注册成功',
    Verificationcodeerror: '验证码错误',
  },
  homeSearch: {
    ...zhSearch,
  },
  homeAboutUs: {
    text1: 'YLCIK生命化学智识库门户网，是甬恒瑶瑶科技有限公司开发的生命化学信息库门户网站，秉承全开源全免费的公益路线，立项至今已完成正式A版的建设，共收录化合物',
    numbers: '条',
    protein: '蛋白质',
    gene: '基因',
    bioassay: '及生物测定',
    text2: '我们希望建立一个国际化的、创新的和跨学科的生命化学知识共享交流平台，我们是生命科学领域开放数据的拥护者。',
    text3:
      '甬恒瑶瑶科技有限公司是一家由多名来自浙江大学、爱尔兰三一学院、佛罗里达大学等资深科学家、教授及专业人才组建的公司，致力于将科技、创新、生物、人工智能和大数据等领域的前沿技术相结合，为客户提供优质的产品和服务。重点聚焦在生命化学数据、创新、装备等业务，目前本项目已加入“光合组织”，拟在中国生物工程学会备案立项，并和中科曙光乌镇国家算力中心达成合作。',
    title1: '研究方案',
    title1p: '我们通过计算研究为生命科学和化学的进步做出贡献。在基因组学时代，我们的计算研究越来越具有转化性，与人类健康、疾病和环境息息相关',
    teach: '培训',
    teachText1: '我们为各级科学家和行业工作者提供先进的生物及化学信息学培训。',
    teachText2: '我们的生物信息学实践培训可帮助实验和计算生物学家充分利用我们广泛的数据和算法资源',
    data: '数据资源',
    cooperation: '行业合作',
    Info: '咨讯及交易',
    InfoP: '我们提供资讯服务、技术交流、技术交易、产品交易平台。',
    time1: '2023年6月',
    time1Title: 'YLCIK生命智识库A1版上线',
    time1Text1: '包含化合物、天然产物、蛋白质、基因、生物分类学、生物测定等多个一级子库;',
    time1Text2: '包含植物、食物、表型与变异、化学合成等数十个二级子库，平均每个子库内容超过80项;',
    time1Text3: '数据量开源收集量达到国外同类60%;',
    time1Text4: '算法功能超过15个;',
    time2: '2023年1月',
    time2Title: 'YLCIK生命智识库demo版上线',
    time2Text1: '包含化合物、天然产物、蛋白质、基因四大子库;',
    time2Text2: '前后端框架完成，具有内部调试页面;',
    time2Text3: '算法功能相似化合物开发完成;',
    time3: '2023年12月',
    time3Title: 'YLCIK生命智识库A版正式上线',
    time3Text1: '在A1版基础上增加GPT生成式生命化学助手，可以服务撰写论文、设计实验、了解行业动态',
    time3Text2: '增加生命化学领域多语言互译功能，准确度超过谷歌医学翻译，新增NLP子库2个;',
    time3Text3: '服务平台正式上线，包含论坛和交易功能;',
    teams:
      '项目团队由“浙江省重点科技创新团队(浙江省委省政府2011年批准成立)”骨干成员组成。以自主创业企业形式支撑团队运行至今，团队由高学历年轻科技人员组成，拥有一批自主知识产权、一批自主技术算法、一批自主处理数据集、一批国际前沿技术研究成果，具有雄厚的技术基础和创新能力。团队追求解决行业卡脖子问题实现国外替代国产化创新，力争成为国内生物信息化行业的先锋企业、国标行标制定者，引领国家级生物信息化平台和新药发现的创新研发基地平台。',
  },
  // 历史记录
  History: { ...lang.zhHistory },
  // 个人中心
  Profile: { ...profile.zhPersonal },
  // 批量查找
  BS: { ...other.zhBatchSearch },
}
