import router from '../router/router'

class GlobalRouter {
  static routeUtil = new GlobalRouter()

  get router() {
    return router
  }

  get route() {
    return router.currentRoute.value
  }
}

export default GlobalRouter.routeUtil
export const { routeUtil } = GlobalRouter
