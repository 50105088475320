export default {
  nature: {
    overview: "overview",
    HeadTitle: "Natural Product",
    HeadText: "素华春漠漠，丹实夏煌煌",
    similarCompound: "Similar Compound",
    compound: "Compound",
    food: "Food",
    plant: "Plant",
    bioassay: "Bioassay",
    bioassaySuggest: "Bioassay",
    cell: "Cell Line",
    cellSuggest: "Cell Line",
    taxonomy: "Taxonomy",
    compoundSuggest: "Compound",
    plantSuggest: "Plant",
    foodSuggest: "Food",
    compound_chemical_reaction: 'Chemical Reaction',
    chemicalReaction: 'Chemical Reaction',
    compound_taxonomy: 'Taxonomy',
    OrganismsTaxonomy: 'Taxonomy of organisms',
    Browser: 'Overview of the Data',
    LearnMore: 'Learn more updates',
    AboutText: "The Natural Products Database is the world 's largest free-accessible collection of chemical information. Search for chemicals by name, molecular formula, structure, and other identifiers. Find chemical and physical properties, biological activity, safety and toxicity information, patents, literature references, etc. It is the world 's largest free-accessible collection of chemical information. Search for chemicals by name, molecular formula, structure, and other identifiers. Find chemical and physical properties, biological activity, safety and toxicity information, patents, literature references, etc.",
    LearnMoreBtn: 'Learn More',
    SearchBox: {
      Label1: 'Compound',
      Label2: 'Food Plant',
      Label3: 'Medicinal Plant',
      Label4: 'Bioassay',
      Label5: 'Cell Line',
    },
    search: {
      compound: {
        MolecularFormula: "Molecular Formula",
        MolecularWeight: "Molecular Weight",
        IUPACName: "IUPAC Name",
        CreateDate: "Create Date"
      },
      food: {
        LatinName: "Latin Name",
        EnglishName: "English Name",
        Description: "Description",

        cytologicalClassification: "Cytological Classification",
        soutce: "Soutce",
        ChineseName: 'Chinese Name'
      },
      plant: {
        LatinName: "Latin Name",
        EnglishName: "English Name",
        Description: "Description",
        ChineseName: 'Chinese Name'
      },
      bioassay: {
        ProjectCategory: "Project Category",
        TestedCompounds: "Tested Compounds",
        TestedSubstances: "Tested Substances",
        Source: "Source",
        DepositDate: "Deposit Date",
        Description: "Description",
      },
      taxonomy: {
        name: 'Name',
        k: 'Kingdom',
        p: 'Phylum',
        c: 'Class',
        o: 'Order',
        f: 'Family',
        s: 'Seed',
        g: 'Genus',
        Classificationlevel: 'Classification level',
        besubordinateto: 'Be subordinate to',
        QuickLinks: 'Quick Links',
        DisplayDetails: 'Display Details',
        SpeciesClassificationTree: 'Species Classification Tree',
        EukaryoticOrganisms: 'Eukaryotic Organisms',
        Bacteria: 'Bacteria',
        Archaea: 'Archaea',
        Virus: 'Virus',
        Other: 'Other',
        unclassified: 'Unclassified',
        title1: 'The species keyword you searched for:',
        title2: 'Name:',
        Nucleicacid: 'Nucleic Acid',
        protein: 'Protein',
        compound: 'Compound',
        bioassay: 'Bioassay',
        ClassificationTree: 'Classification Tree',
        Details: 'Details',
        all: 'All',
        UseClassification: 'Classification of uses',
        TaxonomyOfOrganisms: 'Taxonomy Of Organisms',
        homeText1: 'Far and wide eagles cleave the blue, Up and down fish in shallows glide, All creatures strive for freedom under frosty skies',
        Included: ' included ',
        piecesof: ' pieces of ',
        CategorizedData: ' categorized data.',
        relateProtein: 'Related Protein',
        relateGene: 'Related Gene',
        relateBioassay: 'Related Bioassay',
        relateProteinId: 'ProteinId',
        relateGeneId: 'GeneId',
        relateBioassayId: 'BioassayId',
        relateProteinName: 'Protein Name',
        relateGeneName: 'Gene Name',
        relateBioassayName: 'Bioassay Name',
        otherInfo: 'Other relevant information',
        relatePlant: 'Related Plants',
        relatePlantId: 'PlantId',
        relatePlantName: 'Plant Name'
      },
      reaction: {
        ChemicalReactionId: 'Chemical Reaction YYCReaction ID',
        Reactants: 'Reactants',
        Product: 'Product',
        ConditionOfReaction: 'Condition Of Reaction',
        temperature: 'temperature',
        stirring: 'stirring',
      }
    },
    details: {
      cellLines: {
        Summary: "Summary of CELL lines",
        NameCode: "Name and Identifier",
        OtherName: "Other Names",
        OtherCode: "Other Identifiers",
        CellSource: "Cell Source",
        CompoundBioassay: "Compound-related bioassays",
        AssociatedDiseases: "Related diseases",
        Reference: "Reference",
        Classify: "Classification",
        InfoSource: "Information source",
        IdentifierType: "Identifier type",
        DataSource: "Data sources",
        Identifier: "Identifier",
        CellSourceType: "Cell source type",
        BioName: "Bioassay name",
        BioValue: "Bioassay results",
        BioId: "Bioassay Id",
        LiteratureId: "Literature Id",
        date: "Date of publication",
        name: "Name",
        JournalTitle: "Journal Title",
        CellSorting: "Classification of cells",
        SourceOrganization: "Organization of origin",
        CellGender: "Sex of Cell",
        UpdateTime: "Update Time",
        Basic: 'Basic Information',
        ChemicalsAndBioactivities: 'Related determination of compounds',
        Diseases: 'Related diseases',
        Literature: 'Relevant literature',
        Classification: 'Classification',
        References: 'Sources of information',
        Knowledge: 'Knowledge'
      },
      compound: {
        Summary: "Compound Abstract",
        YYNPsId: "Compound ID",
        Name: "Name",
        Structure: "Structure",
        ChemicalSafety: "Chemical safety symbol",
        MolecularFormula: "Molecular formula",
        Synonyms: "Alias",
        MolecularWeight: "Molecular weight",
        Structure2D: "2D Structure",
        Structure3D: "3D Structure",
        NameAndIdentifiers: "Name and code",
        ComputedDescription: "Computer characterization",
        OtherIdentifiers: "Other Coding",
        PhysicalAndChemicalCharacteristics: "Physical and chemical characteristics",
        ChemicalPropertyName: "Computational features",
        PysicalDescription: "Experimental characteristics",
        PharmacologyAndIochemistry: "Pharmacology and biochemistry",
        BiochemicalReaction: "Biochemical reaction",
        AbsorptionDistributionAndExcretion: "Absorption distribution and excretion",
        BiologicalHalfLife: "Biological half-life",
        Pharmacology: "Pharmacology",
        HumanMetabolite: "Human metabolism",
        Metabolism: "Metabolism",
        BiologicalActivity: "Biological activity",
        EcologicalInformation: "Ecological Information",
        ArtificialPollutionSources: "Artificial pollution sources",
        EnvironmentalBiodegradation: "Environmental biodegradation",
        Mobility: "liquidity",
        Soil: "Soil",
        EnvironmentalWaterConcentrations: "Environmental water concentration",
        EnvironmentalAbioticDegradation: "Environmental non-biodegradation",
        EnvironmentalFate: "Environmental fate",
        EnvironmentalBioconcentration: "Environmental bioconcentration",
        ExposureSummary: "Overview",
        CompoundSimilarity: "Similar compounds",
        DataSources: "Database of sources",
        DataId: "Data Id",
        DataType: "Data Type",
        ViewType: "View Type",
        BiologicalSource: "Biological Source",
        Standard: "Standard",
        Reaction: "Reaction",
        CellularLocations: "Cellular Locations",
        TissueLocations: "Tissue Locations",
        Similarity: "Similarity",
        StructuralSimplification: "Simple structural formula",
        Reference: "Reference",
        Basic: 'Basic Information',
        Knowledge: 'Knowledge'
      },
      bioassay: {
        Intro: "Bioassay Abstract",
        DataSource: "Data Source",
        ExperimentResult: "Experiment Result",
        TargetInformation: "Target Information",
        OriginalCitation: "Original Citation",
        Detail: "Detail Information",
        BioassayAnnotation: "Bioassay Notes(To be perfected)",
        InfoSource: "Information Sources",
        YYAID: "YYBAssay ID",
        TargetType: "Types of targets",
        Source: "Source",
        ExperimentalSpecies: "Experimental species",
        TestCompoundCount: "Test compound count",
        DataUpdateDate: "Data Update Date",
        All: "All",
        Active: "Active",
        InActive: "InActive",
        JournalTitle: "Journal Title",
        YearDate: "Year",
        DOI: "DOI",
        ChEMBLTargetName: "ChEMBL Target Name",
        RelationshipType: "Relationship Type",
        Summary: "Summary",
        Structure: "Structure",
        YYHomeNPSID: "YYHomeNPSID",
        YYHomeCID: "YYHomeCID",
        Activity: "Activity",
        TargetName: "Target Name",
        DetectionForm: "Detection Forms",
        DetectionType: "Detection Type",
        DetectionInfo: "Detection Information",
        DetectionOfCellType: "Detection of Cell types",
        DetectionOrganism: "Detection of organisms",
        DeterminationOfTissue: "Determination of tissue",
        Licence: "Licence",
        BasicInfo: "Basic Information",
        OrganismTestName: "Bioassay Name",
        ProjectClassification: "Project Classification",
        OtherRelevantBiologicalAssays: "Other Relevant Bioassays",
        aid: 'Biometric id',
        aidname: 'Related name of biometrics',
        testedsidcnt: 'Number of species tested',
        activesidcnt: 'Number of active species',
        aidtype: 'Type of relevant bioassay',
        Basic: 'Basic Information',
        References: 'Sources of information',
        Knowledge: 'Knowledge'
      },
      food: {
        Summary: "Edible plant abstract",
        FoodbId: "FoodbId",
        EnglishName: "English name",
        ChineseName: "Chinese name",
        LatinName: "Latin name",
        OtherName: "Other name",
        Picture: "Picture",
        Description: "Description",
        Classification: "Classification",
        PrimaryClassification: "Primary Classification",
        SecondaryClassification: "Secondary Classification",
        Taxonomy: "Taxonomy",
        Kingdom: "Kingdom",
        Phylum: "Phylum",
        Class: "Class",
        Order: "Order",
        Family: "Family",
        Seed: "Seed",
        Genus: "Genus",
        Application: "Application",
        PreparationMethod: "Method of preparation",
        Dose: "Dose",
        Effect: "Effect",
        Indications: "Indications",
        Contraindication: "Contraindications",
        OtherUses: "Other Uses",
        Refaddress: "Reference address",
        Compounds: "Compound composition",
        DataSources: "Data Sources",
        DataType: "Data Type",
        DataId: "Data Id",
        ViewType: "View Type",
        Macronutrients: "Nutritional components",
        References: "References",
        Reference: "Reference address",
        struct: "Structure",
        maxConcentration: "Maximum content",
        minConcentration: "Minimum content",
        avgConcentration: "Average content",
        reference: "Reference",
        macronutrient: "Nutritional components",
        food: 'Food'
      },
      plant: {
        Summary: "Abstract of medicinal plants",
        MorphologicalCharacteristics: 'Morphological characteristics',
        LifeHabits: 'Distribution area',
        MapDistribution: 'Map distribution',
        FoodbId: "Plant ID",
        EnglishName: "English Name",
        ChineseName: "Chinese Name",
        OtherName: "Other name",
        LatinName: "Latin Name",
        Picture: "Picture",
        Description: "Description",
        Classification: "Classification",
        PrimaryClassification: "Primary Classification",
        SecondaryClassification: "Secondary Classification",
        Taxonomy: "Taxonomy",
        Kingdom: "Kingdom",
        Phylum: "Phylum",
        Class: "Class",
        Order: "Order",
        Family: "Family",
        Seed: "Seed",
        Genus: "Genus",
        Application: "TCM Application",
        PreparationMethod: "Method of preparation",
        Dose: "Dose",
        Effect: "Effect",
        Indications: "Indications",
        Contraindication: "Contraindication",
        OtherUses: "Other Uses",
        Refaddress: "Reference address",
        Compounds: "Compound composition",
        DataSources: "Data Sources",
        DataType: "Data Type",
        DataId: "Data Id",
        ViewType: "View Type",
        Macronutrients: "Nutritional components",
        References: "References",
        Reference: "Reference address",
        IntroductionOfMedicinalMaterials: "Introduction of medicinal materials",
        Basigen: 'Basigen',
        FlavorAndMeridianTropism: 'Flavor and Meridian tropism',
        PrimaryFunction: 'Primary function',
        DosageAndUsage: 'Dosage and usage',
        IndicationAndContraindication: 'Indication and Contraindication',
        SideSelection: 'Side selection',
        Pharmacology: 'Pharmacology',
        picture: 'Picture',
        plant: 'Plant'
      },
    },
    StructureDraw: {
      Title: 'Structure drawing',
      inputTitle: 'Please input Smiles...',
    },
    DataStatistic: {
      Title1: 'Diagram of species classification',
      Title2: 'Diagram of compound classification',
      Text1: 'Below is a representation of the taxonomic hierarchy of organisms. Click a section to expand it, and then click the Browse button below to begin exploring taxonomy levels in more detail.',
      Text2: 'The following figure shows a representation of the compound classification hierarchy in the YYHOME Compound database, click a section to expand it, and then click the center button to return to the previous level.',
      BtnText: 'Browser All species'
    },
    NatureBrowser: {
      Compound: 'Compound',
      Plant: 'Medicinal Plant',
      Food: 'Food Plant',
      Bioassay: 'Bioassay',
      Cell: 'Cell Line',
      MoleFormula: 'Molecular formula:',
      MoleWeight: 'Molecular weight:',
      LatinName: 'Latin name:',
      EnglishName: 'English name:',
      TestedCompounds: 'Number of compounds tested',
      TestedSubstances: 'Number of substances tested',
      CellCategory: 'Classification of cells',
      SourceOrganism: 'Source',
    }
  },
};
