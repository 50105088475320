import { objectToKeyValue } from '@/utils'

const zhBaseInfo = {
  Task: '任务',
  Status: '状态',
  Running: '运行中',
  BatchDownload: '批量下载',
  Search: '搜索',
  TaskId: '任务ID',
  TaskName: '任务名称',
  SubmitTime: '提交时间',
  ExpireTime: '过期时间',
  Action: '操作',
  Login: '登录',
  ForecastTime: '预测时间',
}
const zhParagraphInfo = {
  tip01: '你可以在这里等到它完成。',
  tip02: '此状态将一秒检查一次。',
  tip03: '注意：如果文件太大，你可以复制作业ID并稍后在我的BLAST任务页面中检索结果。',
  tip05: '返回提交任务',
  tip06: '选择任务进行批量下载',
  tip07: '请输入任务Id',
  tip08: '是否要删除当前选中的任务？',
  tip09: '说明：此列表仅保留浏览器一周内的历史任务，若您想永久保存任务记录，',
  tip10: '后收藏任务。',
  tip11: '说明：此列表仅保留浏览器一周内的历史任务，您可在',
  tip12: '个人中心—我的任务',
  tip13: '中查看您收藏的全部任务',
  tip14: '请先登录',
}

const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(zhParagraphInfo, true)
enParagraphInfo.tip01 = "You can wait here until it's finished."
enParagraphInfo.tip02 = 'This status will be checked once a second.'
enParagraphInfo.tip03 = 'Note: If the file is too large, you can copy the job ID and retrieve the results later in the My BLAST task page.'
enParagraphInfo.tip05 = 'Return to Submit task'
enParagraphInfo.tip06 = 'Select task for batch download'
enParagraphInfo.tip07 = 'Please enter the task ID'
enParagraphInfo.tip08 = 'Are you sure you want to delete the selected task?'
enParagraphInfo.tip09 = 'Note: This list only keeps the historical tasks of the browser within a week. If you want to permanently save the task record, '
enParagraphInfo.tip10 = ' and collect the task.'
enParagraphInfo.tip11 = 'Note: This list only keeps the browsers historical tasks for a week, Personal center - My Tasks'
enParagraphInfo.tip12 = 'Personal center - My Tasks'
enParagraphInfo.tip13 = 'you can view all your favorites in the '
enParagraphInfo.tip14 = 'please login in the web site'

// 特别的
// TODO 可以作为参数传递
const differentPageParamZh = {
  TP: {
    tip01: '注意：如果文件太大，你可以复制作业ID并稍后在我的靶点预测页面中检索结果。',
    tip02: '你的靶点任务已提交。',
  },
  IS: {
    tip01: '注意：如果文件太大，你可以复制作业ID并稍后在我的逆合成页面中检索结果。',
    tip02: '你的逆合成任务已提交。',
  },
}

const differentPageParamEn = {
  TP: {
    tip01: 'Note: If the file is too large, you can copy the job ID and retrieve the results later in the My target prediction page.',
    tip02: 'Your target prediction has been submitted.',
  },
  IS: {
    tip01: 'Note: If the file is too large, you can copy the job ID and retrieve the results later in the My inverse synthesis page.',
    tip02: 'Your inverse synthesis has been submitted.',
  },
}

export const zhHistory = {
  ...zhBaseInfo,
  ...zhParagraphInfo,
  ...differentPageParamZh,
}
export const enHistory = {
  ...enBaseInfo,
  ...enParagraphInfo,
  ...differentPageParamEn,
}
