import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  NLP: 'NLP小鼠表达式',
  ProjectNumber: '项目编号',
  GeneName: '基因名称',
  BelongingSpecies: '所属物种',
  GeneDescription: '基因描述',
  ExperimentalObject: '实验对象',
  GeneEnvironment: '变量环境',
  TissueSite: '分析的组织部位',
  Conclusion: '文章结论',
  WorksCited: '引文',
}
const zhParagraphInfo = {}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(
  zhParagraphInfo,
  true
)

export const zhNLPDetail = { ...zhBaseInfo, ...zhParagraphInfo }
export const enNLPDetail = { ...enBaseInfo, ...enParagraphInfo }
