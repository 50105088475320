import RouteNames from '@/consts/RouteNames'
import { RouteRecordRaw } from 'vue-router'

export const News: RouteRecordRaw[] = [
  {
    path: '/News',
    name: RouteNames.Home.News,
    component: () => import('@/views/News/NewsHome.vue'),
    redirect: { name: RouteNames.Home.NewsLsit },
    children: [
      {
        path: '',
        name: RouteNames.Home.NewsLsit,
        component: () => import('@/views/News/List/homeLise.vue'),
      },
      {
        path: 'search/:id',
        name: RouteNames.Home.NewsDetail,
        component: () => import('@/views/News/search/NewsDetail.vue'),
      },
    ],
  },
]
