import { objectToKeyValue } from '@/utils'
// 中文
const zhBaseInfo = {
  SelectSpecies: '选择物种',
  HistoryTask: '历史任务',
  Human: '人类',
  Pig: '猪',
  Mouse: '老鼠',
  Rat: '大鼠',
  Cow: '牛',
  UploadCompound: '上传化合物',
  InputSmiles: '输入SMILES',
  Example: '示例',
  Clear: '清除',
  OtherSettings: '其他设置',
  Submit: '提交',
  Reset: '重置',
  Target: '靶点',
  Affinity: '亲合度',
  DownloadResult: '下载结果',
  DrawSMiles: '绘制化合物结构',
  // NoDataTip: '请上传化合物',
  // NoDataTip2,
}
const zhParagraphInfo = {
  tip01:
    '您可以在此预测一个化合物可能存在的靶点 (假设是生物活性的),这一预测是基于二维和三维相似性的结合,以及来自YYHome的56万多种蛋白质。',
  tip02: '请输入Smiles',
  tip03: '绘制化合物结构',
  tip04: '请输入靶点预测任务名称',
  tip05: '请输入Smiles',
  tip06: '请绘制图形',
  tip07: '化合物结构',
  tip08: 'Smiles式错误，请重新输入',
}
// 英文
const enBaseInfo = objectToKeyValue<keyof typeof zhBaseInfo>(zhBaseInfo, false)
const enParagraphInfo = objectToKeyValue<keyof typeof zhParagraphInfo>(
  zhParagraphInfo,
  true
)
enParagraphInfo.tip01 =
  'You can predict a potential target (assuming it is bioactive) based on the combination of two-dimensional and three-dimensional similarity, and the 56 million protein targets from YYHome.'
enParagraphInfo.tip02 = 'Please enter Smiles'
enParagraphInfo.tip03 = 'Draw the structure of compound'
enParagraphInfo.tip04 = 'Please enter the target prediction task name'
enParagraphInfo.tip05 = 'Please enter Smiles'
enParagraphInfo.tip06 = 'Please draw the figure'
enParagraphInfo.tip07 = 'Structure of compound'
enParagraphInfo.tip08 = 'Smiles is wrong, please re-enter'

export const zhTargetPrediction = { ...zhBaseInfo, ...zhParagraphInfo }
export const enTargetPrediction = { ...enBaseInfo, ...enParagraphInfo }
