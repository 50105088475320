// 用户相关api
import request from '@/utils/request'

//修改头像
export function POSTChangeHead<T = any>(formData: any) {
  return request<T>(`/user/changeHead`, 'POST', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
//修改名称
export function POSTChangeName<T = any>(formData: any) {
  return request<T>(`/user/changeName`, 'POST', formData)
}
//获取验证码
export function POSTCode<T = any>(formData: any) {
  return request<T>(`user/integration/send`, 'POST', formData)
}
//绑定
export function POSTcheck<T = any>(formData: any) {
  return request<T>(`user/integration/check`, 'POST', formData)
}
//获取uuid
export function getUuid<T = any>() {
  return request<T>(`/user/uuid`, 'get')
}
//通过uuid获取用户信息
export interface getUserInfo {
  name?: string
  password?: boolean
  email?: string
  phone?: string
  headImage?: string
}
export function getUserInfo<T = any>(uuid: any) {
  return request<T>(`/user/userInfoLite/${uuid}`, 'get')
}
//修改密码
export function changePassword<T = any>(formData: any) {
  return request<T>(`user/changePwd`, 'POST', formData)
}
//判断一个页面是否被收藏
export function ISCollect<T = any>(formData: any) {
  return request<T>(`/user/collection/isCollected`, 'POST', formData)
}

//获取我的收藏列表
export function myCollect<T = any>(
  firstLevelKey: string,
  secondLevelKeys: string,
  page: number,
  size: number
) {
  return request<T>(
    `/user/collection/click?firstLevelKey=${firstLevelKey}&secondLevelKeys=${secondLevelKeys}&page=${page}&size=${size}`,
    'get'
  )
}
//收藏一个页面
export function handleCollect<T = any>(formData: any) {
  return request<T>(`/user/collection/collect`, 'POST', formData)
}
//取消收藏一个页面
export function cnacelCollect<T = any>(formData: any) {
  return request<T>(`/user/collection/uncollect`, 'POST', formData)
}
//获取收藏目录
export function getCatalog<T = any>() {
  return request<T>(`/user/collection/list`, 'get')
}
//个人中心-修改地址/机构
export function POSTChangeAddress<T = any>(formData: any) {
  return request<T>(`/user/changePersonal`, 'POST', formData, {
    headers: { 'Content-Type': 'multipart/x-www-form-urlencoded' },
  })
}
//个人中心-获取地址/机构/绑定信息
export function getPersonal<T = any>(type: any) {
  return request<T>(`/user/getPersonal?type=${type}`, 'get')
}
//个人中心-微信绑定
export function POSTwechatBinding<T = any>(formData: any) {
  return request<T>(`/user/wechatBinding`, 'POST', formData)
}
