export default {
  home: {
    nucleobase: {
      restrictionEnzymesProtectBaseTable:
        'Restriction enzymes protect the base table',
      protectiveBaseDefinition:
        ' Protective base definition: Restriction enzymes recognize specific DNA sequences. In addition, the enzyme protein also occupies several bases on both sides of the recognition site. These bases have a great influence on the stable binding knife DNA double strand of the enzyme and play the role of cutting DNA, which is called the protective base',
      homePage: 'Home page',
      gene: 'Gene',
      protectBaseTable: 'Protect the base table',
      enzyme: 'enzyme',
      oligonucleotideSequence: 'Oligonucleotide sequence',
      chainLength: 'chain length',
      cuttingRate: 'Cutting rate',
    },
    knowledgeAtlas: {
      noData: 'no data',
      accuratelyLocateTheTargetObject:
        'Note: In order to accurately locate the target object, you need to enter the full name of the target object to display the knowledge graph of the object (you can select the target object by entering the prompt list). ',
      selectAssociatedObjectType: 'Please select the associated object type',
      inputFieldCannotEmpty: 'The input field cannot be empty',
      advancedFilter: 'advanced filter',
      associatedObjectTypeFiltering: 'Associated object type filtering',
      all: 'All',
      calculatingStepSize: 'Calculating step size',
      theGraphWillNotUpdated:
        ' Note: The graph will not be updated until you click the "Start Calculation" button after filtering',
      reset: 'reset',
      confirm: 'Confirm',
      beginToCalculate: 'begin to calculate',
      disease: 'disease',
      goAnnotation: 'go annotation',
      protein: 'protein',
      medicine: 'medicine',
      compound: 'compound',
      gene: 'gene',
      genome: 'genome',
      biologicalProject: 'Biological project',
      cellLine: 'cell line',
      bioassay: 'bioassay',
      foodPlant: 'food plant',
      medicinalPlant: 'medicinal plant',
      nutritionalIngredient: 'nutritional ingredient',
      speciestTaxonomy: 'Taxonomy of species',
      selectMapType: 'Select map type',
      targetObjectMapDisplay: 'Target object map display',
      viewTheObjectsPropertiesAssociated:
        'View the objects and properties associated with the target object',
      objectAssociationPathSearch: 'Object association path search',
      viewTheRelationship: 'View the relationship between two target objects',
      theTargetObjectMap: 'The target object map shows the operation flow',
      selectTheObjectType:
        'Select the object type, enter the object name, and select an object based on the search results',
      clickAdvancedFilter:
        'Click Advanced Filter, filter the associated object type, and select the spectrum to be queried',
      clickStartCalculation:
        'Click Start Calculation, and the queried graph relationship will be displayed in the center of the canvas',
      clickTheObject:
        'Click the object in the center of the canvas to display the object information panel',
      objectAssociationPathOperationFlow:
        'Object association path operation flow',
      selectTargetPairType:
        'Select the target pair type, enter the names of the two objects to be compared, and select the specific object according to the search association results',
      clickAdvancedFilterAssociatedObjectType:
        'Click Advanced Filter to filter the associated object type, select the spectrum range to be queried and the calculation step (the calculation step represents the interval between object A and object B).',
    },
    personal: {
      uploading: 'uploading',
      cancel: 'cancel',
    },
    physicochemicalPropertyAnalyze: {
      selectionRegionSequence: 'Selection region sequence',
      youCanSelectSequenceFragmentCorresponding:
        'Note: You can select the sequence fragment corresponding to the township by selecting the following pair of endpoints',
      inputAreaFragment: 'Input area fragment',
      youCanSelectCorrespondingSequenceFragmentEntering:
        'Alternatively, you can select the corresponding sequence fragment by entering the desired endpoint, the sequence fragment you select is at least 5 amino acids long, and the full sequence is used by default',
      NTerminal: 'N-terminal',
      CTerminal: 'C-terminal',
      singleLineInput: 'Single line input',
      reset: 'Reset',
      submit: 'submit',
      currentOperationWillDeleteTheData:
        'Please note that the current operation will delete the data that has been obtained behind the current item. Please confirm before operation!',
    },
    primerDesignUploadDetail: {
      enterPCRTemplateName: 'Please enter a PCR template name',
      mustFillIn: 'must fill in',
    },
    registration: {
      enterYourMobileNumber: 'Please enter your mobile number',
      accountConsistsDigitsStarting:
        'The account consists of 11 digits starting with 1',
      messageAuthenticationCode: 'message authentication code',
      enterYourVerificationCode: 'Please enter your verification code',
      getVerificationCodeFree: 'Get the verification code for free',
      password: 'password',
      enterYourPassword: ' Please enter your password',
      confirmPassword: 'confirm password',
      confirmPasswordTwice: 'Please confirm the password twice',
      personalInformation: 'personal information',
      familyName: 'family name',
      firstName: 'first name',
      enterLastName: 'Please enter your last name',
      enterYourName: 'Please enter your name',
      address: 'address',
      enterYourAddress: 'Please enter your address',
      detailedAddress: 'detailed address',
      enterYourFullAddress: 'Please enter your full address',
      postalCode: 'postal code',
      enterYourZipCode: 'Please enter your zip code',
      organizationInformation: 'Organization information',
      institutionOrOrganization: 'Institution/organization',
      department: 'department',
      laboratory: 'laboratory',
      duty: 'duty',
      researchField: 'research field',
      submit: 'submit',
      back: 'back',
      enterYourPasswordAgain: 'Please enter your password again',
      twoPasswordInconsistencies: 'Two password inconsistencies!',
      enter11DigitPhoneNumber: 'Please enter the 11-digit phone number',
      emailFormatError: 'Email format error!',
      theMailboxCannotEmpty: 'The mailbox cannot be empty',
    },
    login: {
      unregisteredMobileLoginAutomaticallyCreates:
        'Unregistered mobile login automatically creates a new account',
      readAndAgreeServiceAgreementAndPrivacyPolicy:
        'I have read and agree to the Service Agreement and Privacy Policy',
      loginSuccessfully: 'login successfully',
    },
    news: {
      thereNoNewsYourSearch: 'There is no news for your search',
      detailedInformation: 'Detailed information',
    },
    alphaFold: {
      considerHumanProteinEncoded:
        'Consider the human protein encoded by the gene GNE (Q9Y223)',
      itObviouslyHasTwoDomains:
        'It obviously has two domains; In fact, they have a known structure. Will AlphaFold predict their relative positions? To answer this question, we use the predicted Alignment Error (PAE) graph',
      thePAEDiagramIsNotResidualToResidue:
        'Note that the PAE diagram is not a residual-to-residue distance diagram or a contact diagram. In contrast, the green shade indicates the expected distance error (in Angstroms). The color at (x, y) corresponds to the expected distance error at the location of residue x, and when the predicted and true structure are aligned on residue y,',
      darkGreenMeansGood:
        'Dark green means good (low error) and light green means poor (high error). For example, align residue 300',
      confidentRelativePositionOfResidue200:
        'We are confident of the relative position of residue 200',
      twoLowErrorSquaresCorrespond:
        'Of course, two low-error squares correspond to two fields. If you click and drag to highlight them, they are structurally highlighted. Click the cross to remove the highlight.',
      highlightingDiscontinuousAreasIsNot:
        ' Highlighting discontinuous areas is not currently supported. Selecting a non-diagonal region highlights the entire range from the smallest selected residue to the largest selected residue.',
      theHighPredictiveAlignment:
        'The high predictive alignment error for the entire interdomain region tells us that AlphaFold does not predict the relative domain position in this case.',
      morePromisingInterdomain:
        'A more promising interdomain example is the human protein encoded by DIP2B (Q9P265)',
      alignedResiduesAround850:
        'In this case, aligned on residues around 850, we are confident about the relative position of residues around 1450, which indicates that there is stacking between small central domains',
    },
    balst: {
      querySequence: 'Query sequence',
      enterFasta: 'Enter FASTA',
      target: 'target',
      DS: 'DS',
      nucleotideSet: 'Nucleotide set',
      genome: 'genome',
      transcriptome: 'transcriptome',
      submitTask: 'Submit task',
      setTaskName: 'Set task name',
      notificationViaEmail: 'Notification via email',
      setUpParameters: 'set up parameters',
      outputFormat: 'output format',
      generalParameter: 'General parameter',
      maximumNumberAlignmentsRetain:
        'The maximum number of alignments to retain',
      expectationSetting: 'Expectation setting ',
      wordLengthMatchingFragmentLength:
        'Word length (matching fragment length)',
      scoringParameter: 'Scoring parameter',
      filteringMaskingProcedures: 'Filtering and masking procedures',
      submit: 'submit',
      reset: 'reset',
      advertising:
        'The Basic Local Comparison Search Tool (BLAST) can find similar regions between biological sequences, and the program compares nucleotide or protein sequences with sequence databases to obtain information such as sequence similarity, so as to determine the origin or evolutionary relationship of sequences, and helps identify gene family members',
      inputFieldCannotEmpty: 'The input field cannot be empty',
      returnSubmitTask: 'Return to submit task',
      batchDownload: 'batch download',
      selectTasksBatchDownload: 'Select tasks for batch download',
      taskIDNotificationEmail: 'Task ID or notification email',
      retrieval: 'retrieval',
      taskType: 'task type',
      taskName: 'task name',
      submitIn: 'Submit in',
      status: 'status',
      expirationTime: 'expiration time',
      handle: 'handle',
      thisListOnlyKeeps:
        "Note: This list only keeps the browser's historical tasks for a week, which you can do in ",
      personalCenterinMyMission: 'Personal Center - My mission',
      viewAllTasksYourCollection: 'View all tasks in your collection',
      thisListOnlyKeeps1:
        "Note: This list only keeps the browser's historical tasks within a week,",
      logIn: 'log in',
      afterThatYouCanView: 'After that, you can view all your recent tasks',
      uploadSerialNumberError: 'Upload serial number error',
      areYouSureDeleteThisResult: 'Are you sure to delete this result?',
      areYouSureBookmarkThisResult: 'Are you sure to bookmark this result?',
      areYouSureAboutTheResultUnfavorites:
        'Are you sure about the result of unfavorites?',
      taskID: 'TaskID',
      clickDownload: 'click to download',
      uploadTime: 'upload time',
      workingCondition: 'working condition',
      finish: 'finish',
      program: 'Program',
      quote: 'quote',
      molecularType: 'Molecular type',
      database: 'database',
      queryLength: 'Query length',
      enterTheCorrectSerialNumber: 'No result yet',
    },
  },
}
